import { useCallback } from 'react';
import { addToast } from '../../../utils/toastNotifications';
import { useAuth } from 'react-oidc-context';
import { Link } from 'react-router-dom';

const RegistrationLanding = () => {
  const auth = useAuth();

  const loginAuth = useCallback(async () => {
    await auth
      .signinRedirect()
      .then((response: any) => {})
      .catch((error: any) => {
        addToast('Network error occured', 'error');
        console.log(error);
      });
  }, [auth]);

  return (
    <div className='secondary-bg-color'>
      {/* <h4 className='text-center mt-5'>WellaHealth Agent Network</h4> */}
      <div className='container secondary-bg-color' style={{ marginTop: '60px' }}>
        <div className='text-center mb-5'>
          <a href='/dashboard'>
            <img
              src={require('../../../assets/images/wellahealth-plain.svg').default}
              alt='wellahealth'
              width='150'
            />
          </a>
        </div>
        <div className='row justify-content-center'>
          <div className='col-sm-6 col-md-6 col-lg-4'>
            <div className='card' style={{ backgroundColor: '#f2f6ff', padding: '20px 10px' }}>
              <h4 className='fw-book text-center'>Select Account Type</h4>
              <div className='fs-7 fw-light text-center mb-3'>Make a selection to proceed</div>
              <div className='row mt-5 mb-3'>
                <div className='col-md-6 mt-2'>
                  <div className='register-card'>
                    <Link to='/auth/register/' className='plain-link color-black'>
                      <div>
                        <img
                          src={require('../../../assets/icons/person.svg').default}
                          alt='wellahealth'
                        />
                      </div>
                      <div className='mt-3 fs-6'>Register as a Sub Agent</div>
                    </Link>
                  </div>
                </div>
                <div className='col-md-6 mt-2'>
                  <div className='register-card'>
                    <Link to='/auth/register/superagent' className='plain-link color-black'>
                      <div>
                        <img
                          src={require('../../../assets/icons/person-group.svg').default}
                          alt='wellahealth'
                        />
                      </div>
                      <div className='mt-3 fs-6'>Register as a Super Agent</div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='color-white fs-7 mt-5 text-center pointer' onClick={loginAuth}>
          Already have an account? Login
        </div>
      </div>
    </div>
  );
};

export default RegistrationLanding;
