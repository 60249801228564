import { useEffect } from 'react';
import { GENDER_MAPPING, SubscriptionDetailsTab } from '../../../utils/mappings';
import { STATES_LGA } from '../../../utils/states_lga';
import { ISubscriptionDetailsProp } from '../../../interfaces/SubscriptionInterface';

import { formatInputDate } from '../../../utils/dateTimeUtils';
import { isFormValidated } from '../../../utils/formUtils';

const PersonalDetailScreen = ({
  subscription,
  tabKey,
  handleChange,
  changeTab,
}: ISubscriptionDetailsProp) => {
  const statesType = STATES_LGA;
  const statesMap = statesType.map((states) => {
    return {
      key: states.id,
      text: states.state,
      value: states.state,
    };
  });

  const moveToNextScreen = (event: any) => {
    if (!isFormValidated('personal-details')) {
      return;
    }

    event.preventDefault();

    if (changeTab) {
      changeTab(SubscriptionDetailsTab.plan);
    }
  };

  useEffect(() => {
    if (tabKey !== SubscriptionDetailsTab.info || !subscription) {
      return;
    }
  }, [subscription, tabKey]);

  return (
    <>
      <div className='container mt-5'>
        <h4 style={{ textAlign: 'center', color: '#000000' }}>Personal Details</h4>

        <form id='personal-details' className='row justify-content-center'>
          <div className='col-md-4 col-sm-8 mt-3'>
            <div className='form-group form-floating'>
              <input
                value={subscription!.firstName}
                required
                className='form-control'
                type='text'
                onChange={handleChange('firstName')}
                placeholder='First Name *'
              />
              <label>First Name *</label>
            </div>
            <div className='form-floating form-group mt-4'>
              <input
                value={subscription!.lastName}
                required
                className='form-control '
                type='text'
                placeholder=' Last Name *'
                onChange={handleChange('lastName')}
              />
              <label>Last Name *</label>
            </div>
            <div className='form-floating form-group mt-4'>
              <input
                value={subscription!.phone}
                maxLength={13}
                pattern='[0]{1}[7-9]{1}[0-2]{1}[0-9]{8}|[2]{1}[3]{1}[4]{1}[0-9]{10}'
                required
                className='form-control '
                type='tel'
                onChange={handleChange('phone')}
                placeholder='Phone Number *'
              />
              <label>Phone Number *</label>
            </div>
            <div className='form-floating form-group mt-4'>
              <input
                value={subscription!.email}
                className='form-control '
                type='email'
                placeholder='Email address'
                onChange={handleChange('email')}
              />
              <label>Email Address *</label>
            </div>
            <div className='form-floating form-group mt-4'>
              <textarea
                value={subscription!.location}
                required
                className='form-control'
                onChange={handleChange('location')}
                placeholder='Residential Address'
              />
              <label>Residential Address *</label>
            </div>

            <div className='form-floating form-group mt-4'>
              <select
                required
                onChange={handleChange('stateOfResidence')}
                value={subscription!.stateOfResidence}
                className='form-control custom-select'
                placeholder='State Of Residence'
              >
                {statesMap.map((option) => (
                  <option key={option.key} value={option.value.toString()}>
                    {option.text}
                  </option>
                ))}
              </select>
              <label className='form-label'>
                State Of Residence <span className='text-danger'>*</span>
              </label>
            </div>
            <div className='form-floating form-group mt-4'>
              <select
                value={subscription!.gender}
                onChange={handleChange('gender')}
                className='form-control custom-select'
              >
                {GENDER_MAPPING.map((option) => (
                  <option key={option.key} value={option.value}>
                    {option.text}
                  </option>
                ))}
              </select>
              <label className='form-label'>
                Gender <span className='text-danger'>*</span>
              </label>
            </div>
            <div className='form-floating form-group mt-4'>
              <input
                value={formatInputDate(subscription!.dateOfBirth)}
                required
                className='form-control '
                type='date'
                onChange={handleChange('dateOfBirth')}
              />
              <label className='form-label'>
                Date of Birth <span className='text-danger'>*</span>
              </label>
            </div>
            <div className='d-grid mt-5'>
              <button
                className='btn btn-primary btn-height'
                type='submit'
                onClick={moveToNextScreen}
              >
                Next
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default PersonalDetailScreen;
