import { useState, useEffect, useCallback, useContext } from 'react';
import { IEsusuCustomer } from '../../../interfaces/EsusuInterface';
import { Link } from 'react-router-dom';
import { addToast } from '../../../utils/toastNotifications';
import { endpoints } from '../../../utils/URL';
import axios from 'axios';
import { AppContext } from '../../../context/AppContext';
import TableLoader from '../../common/TableLoader';
import NoDataView from '../../common/NoDataView';
import { LoadingStatus } from '../../../utils/mappings';
import { formatDate } from '../../../utils/dateTimeUtils';
import EsusuPaymentModal from './PaymentModal';

const EsusuCustomers = () => {
  const { appState } = useContext(AppContext);

  const [subscriptionData, setSubscriptionData] = useState<IEsusuCustomer[]>([]);
  const [allData, setAllData] = useState<IEsusuCustomer[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [loadingState, setLoadingState] = useState(LoadingStatus.LOADING);
  const [viewType, setViewType] = useState('All');
  const [modalData, setModalData] = useState<IEsusuCustomer>();

  const getSubscriptions = useCallback(async () => {
    setLoadingState(LoadingStatus.LOADING);

    const agentCode = appState.agentCode;
    try {
      const response = await axios.get(`${endpoints.Esusu.Customers.mainUrl}/agents/${agentCode}`);
      const subscriptions = response.data;
      setSubscriptionData(subscriptions);
      setAllData(subscriptions);
      setLoadingState(LoadingStatus.LOADED);
    } catch (error) {
      setLoadingState(LoadingStatus.NODATA);
      addToast('Error getting esusu subscriptions', 'error');
    }
  }, [appState.agentCode]);

  const filterSubscribers = (event: any) => {
    const filterValue = event.target.value;

    const filteredSubscribers = allData.filter(
      (x) =>
        x.phoneNumber.includes(filterValue) ||
        x.firstName.includes(filterValue) ||
        x.lastName.includes(filterValue)
    );

    // const filteredSubscribers = allData.filter((subscriber) => {
    //   return subscriber.phoneNumber.includes(event.target.value);
    // });
    setSubscriptionData(filteredSubscribers);
  };

  const openPaymentModal = (data: IEsusuCustomer) => {
    setModalData(data);
    setShowModal(true);
  };

  useEffect(() => {
    getSubscriptions();
  }, [getSubscriptions]);

  let subscriberCards: JSX.Element = (
    <div className=''>
      {subscriptionData ? (
        <div className='row'>
          {subscriptionData.map((subscriber: IEsusuCustomer) => {
            return (
              <div className='col-md-4 mt-4'>
                <div className='card borderless rounded'>
                  <div className='card-body'>
                    <div>
                      <div className='row card-text subscription-card-text mb-3'>
                        <div className='col-6 fs-5'>
                          {subscriber.firstName} {subscriber.lastName}
                        </div>
                        <div className='col-6 text-end fs-6 fw-light'>
                          {subscriber.numberOfSubscribers} beneficiaries
                        </div>
                      </div>
                      <div className='row card-text subscription-card-text mb-3'>
                        <div className='col-6 fs-6 fw-light'> {subscriber.phoneNumber} </div>
                        <div className='col-6 text-end fw-light'>{subscriber.customerCode}</div>
                      </div>
                      <div className='row card-text subscription-card-text mb-2'>
                        <div className='col-6 fs-6'>
                          <div className=' fw-light'>Last payment</div>
                          {formatDate(subscriber.lastPaymentDate)}
                        </div>
                        <div className='col-6 text-end'>
                          <div className=' fw-light'>Next Payment</div>
                          {formatDate(subscriber.nextPaymentDate)}
                        </div>
                      </div>
                      <div className='row mt-3'>
                        <div className='col-6'>
                          <div className='mt-3'>
                            <button
                              className='btn btn-sm btn btn-success'
                              onClick={() => openPaymentModal(subscriber)}
                            >
                              Add Payment
                            </button>
                          </div>
                        </div>
                        <div className='col-6 mt-3 text-end'>
                          <Link
                            to={`/esusu/${subscriber.customerId}/payments`}
                            className='btn btn-sm btn btn-info'
                          >
                            Payments
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <NoDataView />
      )}
    </div>
  );

  let paymentModal = (
    <EsusuPaymentModal open={showModal} close={() => setShowModal(false)} modalData={modalData!} />
  );

  return (
    <>
      {paymentModal}
      <div className='container'>
        <h3 className='mr-auto'>My Esusu Customers </h3>
        <div className='row'>
          <div className='col-sm-6 mt-4'>
            <div className='row'>
              <div className='col-sm-8'>
                <div className='form-floating'>
                  <input
                    type='search'
                    id='sub-search-param'
                    className='form-control'
                    placeholder='search by subscriber name or phone numbers'
                    onChange={filterSubscribers}
                  />
                  <label>search by subscriber name or phone numbers</label>
                </div>
              </div>
              <div className='col-sm-4 col-md-4'>
                <div id='select-view' className='form-floating'>
                  <select
                    onChange={(e) => setViewType(e.target.value)}
                    value={viewType}
                    className='form-control custom-select'
                  >
                    <option value='All'>All</option>
                    <option value='Today'>Payments Due Today</option>
                    <option value='Others'>Others</option>
                  </select>
                  <label>Change View</label>
                </div>
              </div>
              {/* {advSearch ? (
                <div className=' col-4'>
                  <button className='btn btn-success' onClick={searchRemoteData}>
                    {isSearch ? <ButtonLoader /> : 'Search'}
                  </button>
                </div>
              ) : (
                ''
              )} */}
            </div>
          </div>
          <div className='col-sm-6 text-sm-end mt-4'>
            <button className='btn btn-primary'>
              <Link
                to='/esusu/customers/register'
                style={{ textDecoration: 'none', color: 'white' }}
              >
                Add Customer
              </Link>
            </button>
          </div>
        </div>

        {/* <br />
        <div id='search-input' className=''>
          <input
            onChange={filterSubscribers}
            className='form-control'
            placeholder='Search by phone number'
          />
        </div> */}
        <div id='payment-cards' className='mt-5'>
          {loadingState === LoadingStatus.NODATA ||
            (loadingState === LoadingStatus.LOADED && subscriptionData.length === 0 && (
              <NoDataView />
            ))}
          {loadingState === LoadingStatus.LOADING && <TableLoader />}
          {loadingState === LoadingStatus.LOADED && subscriberCards}
        </div>
      </div>
    </>
  );
};

export default EsusuCustomers;
