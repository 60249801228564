import { IAppState, IDispatch } from '../interfaces/AppInterface';

const appReducer = (state: IAppState, action: IDispatch) => {
  switch (action.type) {
    case 'LOG_OUT':
      localStorage.clear();
      sessionStorage.clear();
      localStorage.setItem('rememberMe', 'false');
      return {
        token: '',
        isAuthenticated: false,
        userRole: 'SubAgent',
        firstName: '',
        lastName: '',
        agentCode: '',
        customerId: 0,
      };
    case 'LOG_IN':
      return {
        ...state,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        agentCode: action.payload.agentCode,
        //userRole: action.payload.superAgentName === null ? 'SuperAgent' : 'SubAgent',
      };
    case 'SET_AUTH':
      localStorage.setItem('rememberMe', 'true'); //
      return {
        ...state,
        isAuthenticated: action.payload,
      };
    case 'SET_CUSTOMER_ID':
      return {
        ...state,
        customerId: action.payload,
      };
    case 'SET_WALLET':
      return {
        ...state,
        wallet: action.payload,
      };
    case 'UPDATE_PROP': //updates a property of the SubscriptionData object e.g. firstName. to use, pass the property name and new value e.g 'firstName', 'Adam
      return { ...state, [action.propName]: action.propData };
    default:
      return state;
  }
};

export default appReducer;
