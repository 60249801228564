import { useEffect, useState } from 'react';
import Header from './Header';
import SideMenu from './SideMenu';
import { Redirect, Route, useHistory } from 'react-router-dom';
import { IPrivateRouteProps } from '../../interfaces/AppInterface';
import axios from 'axios';
import ConfirmationModal from '../common/ConfirmationModal';
import { useAuth } from 'react-oidc-context';
import routes from '../../utils/routes';
import Breadcrumbs from '../common/Breadcrumbs';

const Layout = ({ component: Component, ...rest }: IPrivateRouteProps) => {
  const [isLoading, setLoading] = useState(true);

  const history = useHistory();

  useEffect(() => {
    return history.listen((location: any, action: any) => {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    });
  }, [setLoading, history]);

  const [showModal, setShowModal] = useState(false);

  /* Auth methods */

  const auth = useAuth();

  const loginAuth = () => {
    //save the current route so we can navigate to it after login
    sessionStorage.setItem('return-url', window.location.href);
    auth
      .signinRedirect()
      .then((response: any) => {})
      .catch((error: any) => {
        console.log(error);
        window.location.href = '/';
      });
  };

  //if an error occurs during renewal,
  if (auth.error) {
    if (showModal) {
      console.log('error me');
    } else {
      setShowModal(true);
    }
  }

  if (!auth.isLoading) {
    //if authenticated, set axios header
    if (auth.isAuthenticated) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${auth.user?.access_token}`;
    } else {
      loginAuth();
    }
  }

  /* End auth */

  return (
    <div>
      {showModal ? (
        <ConfirmationModal
          show={showModal}
          hide={() => setShowModal(false)}
          title='Session expired'
          okCallback={loginAuth}
          content='Click Ok to Login'
        />
      ) : (
        <div></div>
      )}

      <div style={{ background: '#f6f6fa' }}>
        {auth.isLoading ? (
          <div>Loading</div>
        ) : auth.isAuthenticated ? (
          <div>
            <Header />
            <div className='container-fluid'>
              <span
                id='request-spinner'
                className='spinner-border spinner-border-sm text-success request-spinner d-none'
                role='status'
                aria-hidden='true'
              ></span>
              {isLoading ? (
                <div className='progress' style={{ height: '3px', backgroundColor: '#5ba532' }}>
                  <div
                    className='progress-bar'
                    role='progressbar'
                    aria-valuenow={0}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  ></div>
                </div>
              ) : (
                ''
              )}

              <div className='row'>
                <SideMenu />
                <Route
                  {...rest}
                  render={(props) => {
                    const crumbs = routes
                      // Get all routes that contain the current one.
                      .filter(({ path }) => props.match.path.includes(path))
                      // Swap out any dynamic routes with their param values.
                      // E.g. "/pizza/:pizzaId" will become "/pizza/1"
                      .map(({ path, ...rest }) => ({
                        path: Object.keys(props.match.params).length
                          ? Object.keys(props.match.params).reduce(
                              (path, param) =>
                                path.replace(`:${param}`, props.match.params[param]!),
                              path
                            )
                          : path,
                        ...rest,
                      }));
                    return (
                      <main
                        role='main'
                        id='main-view'
                        className='col-md-12 col-lg-12 ms-sm-auto px-md-4'
                        style={{ marginTop: '30px', marginBottom: '100px' }}
                      >
                        <Breadcrumbs crumbs={crumbs} />
                        <Component {...props} />
                      </main>
                    );
                  }}
                />
              </div>
            </div>
          </div>
        ) : (
          <Redirect to='/' />
        )}
      </div>
    </div>
  );
};

export default Layout;
