import { Link } from 'react-router-dom';

const EsusuLandingPage = () => {
  //get agent details

  return (
    <div>
      <div className='container'>
        <div className='row justify-content-center'>
          <div className='col-sm-6'>
            <div className='fs-3 text-center'>Esusu</div>
            <br />

            <div className='text-center mt-4' style={{ fontSize: '0.9rem' }}>
              What would you like to do today?
            </div>
            <div className='row mt-3'>
              <div className='home-card col-sm-6 mt-3'>
                <Link
                  to='/esusu/customers'
                  className='card home-card '
                  style={{ backgroundColor: '#1489C8', color: '#fff' }}
                >
                  <div className='card-body form-group'>
                    <div className='mt-2 home-card-title fw-medium'>View customers</div>
                    <div className='mt-2 home-card-subtitle'>
                      See all your Esusu customers and their information
                    </div>
                    <div className='align-items-middle float-end' style={{ marginBottom: '5px' }}>
                      <img src={require('../../../assets/icons/users.svg').default} alt='' />
                    </div>
                  </div>
                </Link>
              </div>
              <Link to='/esusu/customers/register' className='home-card col-sm-6 mt-3'>
                <div
                  className='card home-card '
                  style={{ backgroundColor: '#5BA532', color: '#fff' }}
                >
                  <div className='card-body form-group'>
                    <div className='mt-2 home-card-title fw-medium'>Add new customer</div>
                    <div className='mt-2 home-card-subtitle'>
                      Register a new Esusu customer with very simple steps
                    </div>
                    <div className='align-items-middle float-end'>
                      <img src={require('../../../assets/icons/user-plus.svg').default} alt='' />
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EsusuLandingPage;
