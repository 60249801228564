export const PAYMENT_PLAN_MAPPING = [
  // {
  //   key: 0,
  //   value: 'Monthly',
  //   text: 'Monthly',
  // },
  {
    key: 1,
    value: 'Quarterly',
    text: 'Quarterly',
  },
  {
    key: 2,
    value: 'Biannually',
    text: 'Biannually',
  },
  {
    key: 3,
    value: 'Yearly',
    text: 'Yearly',
  },
];

export const PHARM_PAYMENT_PLAN_MAPPING = [
  {
    key: 2,
    value: 'Biannually',
    text: 'Biannually',
  },
  {
    key: 3,
    value: 'Yearly',
    text: 'Yearly',
  },
];
export const DECISION_MAPPING = [
  {
    key: 0,
    value: 'Yes',
    text: 'Yes',
  },
  {
    key: 1,
    value: 'No',
    text: 'No',
  },
];

export const STATUS_DISPLAY = [
  {
    key: 0,
    value: 'Unpaid',
    text: 'Unpaid',
  },
  {
    key: 1,
    value: 'Active',
    text: 'Active',
  },
  {
    key: 2,
    value: 'MissedPayment',
    text: 'MissedPayment',
  },
  {
    key: 3,
    value: 'Cancelled',
    text: 'Cancelled',
  },
];

export let getKeyByValue = (object: any, value: any) => {
  return Object.keys(object).find((key) => object[key] === value);
};

export let getValueByKey = (object: any, key: any) => {
  return object[key];
};

export const PRODUCT_TYPE = [
  {
    key: 0,
    value: 'Cashplus',
    text: 'Basic',
  },
  {
    key: 1,
    value: 'Pharma',
    text: 'Pharma',
  },
];
export const BOOL_MAPPING = [
  {
    key: 0,
    value: true,
    text: 'Yes',
  },
  {
    key: 1,
    value: false,
    text: 'No',
  },
];

export const GENDER_MAPPING = [
  {
    key: 0,
    value: 'Female',
    text: 'Female',
  },
  {
    key: 1,
    value: 'Male',
    text: 'Male',
  },
  {
    key: 2,
    value: 'Others',
    text: 'Others',
  },
];

export const PAYMENT_CHANNEL_MAPPING = [
  {
    key: 0,
    value: 'Paystack',
    text: 'Paystack',
  },
  {
    key: 1,
    value: 'BankTransfer',
    text: 'Bank Transfer',
  },
  {
    key: 2,
    value: 'Cash',
    text: 'Cash',
  },
  {
    key: 3,
    value: 'Agent',
    text: 'Agent',
  },
  {
    key: 4,
    value: 'Monnify',
    text: 'Monnify',
  },
];

// export const HomeTiles = [
//   {
//     title: 'Subscriptions',
//     icons: 'bi bi-people-fill',
//     link: '/subscriptions',
//   },
//   {
//     title: 'Agents',
//     icons: 'bi bi-file-medical',
//     link: '/agents',
//   },

//   {
//     title: 'Commission',
//     icons: 'bi bi-cash',
//     link: '/wallet',
//   },
// ];

export const HomeTiles = [
  {
    title: 'Subscriptions',
    subtitle: '',
    icons: '../../assets/icons/subscription-icon.svg',
    link: '/subscriptions/view',
  },
  {
    title: 'Agents',
    subtitle: '',
    icons: '../../assets/icons/subscription-icon.svg',
    link: '/agents',
  },

  {
    title: 'Float Wallet',
    subtitle: '',
    icons: '../../assets/icons/subscription-icon.svg',
    link: '/wallet',
  },
];

export const LoadingStatus = {
  LOADING: 'Loading', //api loading and no data
  NODATA: 'NoData', //api loaded but no data
  LOADED: 'Loaded', //api loaded with data
};

export const BankList = [
  {
    label: 'Access Bank Plc',
    value: 'Access Bank Plc',
  },
  {
    label: 'Citibank Nigeria Limited',
    value: 'Citibank Nigeria Limited',
  },
  {
    label: 'Ecobank Nigeria',
    value: 'Ecobank Nigeria',
  },
  {
    label: 'Fidelity Bank Plc',
    value: 'Fidelity Bank Plc',
  },
  {
    label: 'First Bank of Nigeria Limited',
    value: 'First Bank of Nigeria Limited',
  },
  {
    label: 'First City Monument Bank Limited',
    value: 'First City Monument Bank Limited',
  },
  {
    label: 'Guaranty Trust Bank Plc',
    value: 'Guaranty Trust Bank Plc',
  },
  {
    label: 'Heritage Bank Plc',
    value: 'Heritage Bank Plc',
  },
  {
    label: 'Jaiz Bank Plc',
    value: 'Jaiz Bank Plc',
  },
  {
    label: 'Keystone Bank Limited',
    value: 'Keystone Bank Limited',
  },
  {
    label: 'Kuda Bank',
    value: 'Kuda Bank',
  },
  {
    label: 'Mint Finex MFB',
    value: 'Mint Finex MFB',
  },
  {
    label: 'Mkobo MFB',
    value: 'Mkobo MFB',
  },
  {
    label: 'Polaris Bank Limited',
    value: 'Polaris Bank Limited',
  },
  {
    label: 'Providus Bank Limited',
    value: 'Providus Bank Limited',
  },
  {
    label: 'Rubies Bank',
    value: 'Rubies Bank',
  },
  {
    label: 'Stanbic IBTC Bank Plc',
    value: 'Stanbic IBTC Bank Plc',
  },
  {
    label: 'Standard Chartered',
    value: 'Standard Chartered',
  },
  {
    label: 'Sterling Bank Plc',
    value: 'Sterling Bank Plc',
  },
  {
    label: 'SunTrust Bank Nigeria Limited',
    value: 'SunTrust Bank Nigeria Limited',
  },
  {
    label: 'TAJBank Limited',
    value: 'TAJBank Limited',
  },
  {
    label: 'Titan Trust Bank Limited',
    value: 'Titan Trust Bank Limited',
  },
  {
    label: 'Union Bank of Nigeria Plc',
    value: 'Union Bank of Nigeria Plc',
  },
  {
    label: 'United Bank for Africa Plc',
    value: 'United Bank for Africa Plc',
  },
  {
    label: 'Unity Bank Plc',
    value: 'Unity Bank Plc',
  },
  {
    label: 'VFD MFB',
    value: 'VFD MFB',
  },
  {
    label: 'Wema Bank Plc',
    value: 'Wema Bank Plc',
  },
  {
    label: 'Zenith Bank Plc',
    value: 'Zenith Bank Plc',
  },
];

export const SubscriptionDetailsTab = {
  info: 'info',
  plan: 'plan',
  nextOfKin: 'nextOfKin',
};
