export interface IEsusuCustomer {
  customerId?: number;
  firstName: string;
  customerCode: string;
  lastName: string;
  phoneNumber: string;
  address: string;
  numberOfSubscribers: number;
  agentCode: string;
  lastPaymentDate: string;
  nextPaymentDate: string;
  dateCreated: string;
}

export interface INewEsusuCustomer {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  address: string;
  numberOfSubscribers: number;
  agentCode: string;
  durationPaidFor: number;
  durationType: string;
}

export interface IEsusuPayment {
  paymentDate: string;
  paymentActualDate: string;
  numberOfPersons: number;
  amountPaid: number;
  baseAmount: number;
  dateCreated: string;
}

export interface IEsusuPaymentModal {
  modalData: IEsusuCustomer;
  open: boolean;
  close: () => void;
}

//initializations
export const intialNewCustomer: INewEsusuCustomer = {
  firstName: '',
  lastName: '',
  phoneNumber: '',
  durationPaidFor: 0,
  durationType: 'Weekly',
  address: '',
  numberOfSubscribers: 1,
  agentCode: '',
};
