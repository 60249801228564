//For routes with sub routes, add the sub-routes AFTER the main subroute
//e.g /profile has a sub route /profile/edit therefore, /profile/edit must be below /profile when declared below

import { IRoute } from '../interfaces/CommonInterface';

const routes: IRoute[] = [
  {
    path: '/',
    name: '',
    tags: '',
    icon: '',
  },
  {
    path: '/dashboard',
    name: 'Home',
    tags: '',
    icon: '',
  },
  {
    path: '/subscriptions',
    name: 'Subscription Home',
    tags: '',
    icon: '',
  },
  {
    path: '/subscriptions/view',
    name: 'Subscription',
    tags: '',
    icon: '',
  },
  {
    path: '/subscriptions/add',
    name: 'Add Subscription',
    tags: '',
    icon: '',
  },
  {
    path: '/subscriptions/vouchers',
    name: 'Vouchers',
    tags: '',
    icon: '',
  },
  {
    path: '/subscriptions/details',
    name: 'Subscription Details',
    tags: '',
    icon: '',
  },
  {
    path: '/subscriptions/view/:agentCode',
    name: 'Agent Subscription',
    tags: '',
    icon: '',
  },
  {
    path: '/auth/register',
    name: 'Registration',
    tags: '',
    icon: '',
  },
  {
    path: '/auth/register/landing',
    name: 'Registration Landing',
    tags: '',
    icon: '',
  },
  {
    path: '/auth/register/superagent',
    name: 'Super Agent Registration',
    tags: '',
    icon: '',
  },
  {
    path: '/auth/register/success',
    name: 'Registration Success',
    tags: '',
    icon: '',
  },
  {
    path: '/wallet',
    name: 'Wallet',
    tags: '',
    icon: '',
  },
  {
    path: '/wallet/withdrawal',
    name: 'Wallet Withdrawal',
    tags: '',
    icon: '',
  },
  {
    path: '/profile',
    name: 'Profile',
    tags: '',
    icon: '',
  },
  {
    path: '/wellapay/users',
    name: 'WellaPay Users',
    tags: '',
    icon: '',
  },
  {
    path: '/wellapay/users/add',
    name: 'Add WellaPay Users',
    tags: '',
    icon: '',
  },
  {
    path: '/esusu',
    name: 'Esusu Home',
    tags: '',
    icon: '',
  },
  {
    path: '/esusu/customers',
    name: 'Esusu Customers',
    tags: '',
    icon: '',
  },
  {
    path: '/esusu/customers/register',
    name: 'Register Esusu Customers',
    tags: '',
    icon: '',
  },
  {
    path: '/esusu/:customerId/payments',
    name: 'Esusu Payments',
    tags: '',
    icon: '',
  },

  {
    path: '/agents',
    name: 'Agents',
    tags: '',
    icon: '',
  },
];

export default routes;
