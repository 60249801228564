import { useContext, useCallback, useEffect, useState } from 'react';
import { AppContext } from '../../../context/AppContext';
import axios from 'axios';
import { endpoints } from '../../../utils/URL';
import { addToast } from '../../../utils/toastNotifications';
import { IProfile } from '../../../interfaces/ProfileInterface';
import { formatDate } from '../../../utils/dateTimeUtils';
import TableLoader from '../../common/TableLoader';

const Profile = () => {
  const { appState } = useContext(AppContext);
  const [profileData, setProfileData] = useState<IProfile | null>();

  const passwordResetUrl = process.env.REACT_APP_RESET_URL;

  const getProfile = useCallback(async () => {
    try {
      const response = await axios.get(endpoints.Agents.mainUrl + `/${appState.agentCode}`);
      setProfileData(response.data);
    } catch (error) {
      addToast('Error getting Profile.');
    }
  }, [appState.agentCode]);

  useEffect(() => {
    const runOnComponentMount = async () => {
      await getProfile();
    };
    runOnComponentMount();
  }, [getProfile]);

  return (
    <>
      <div className='row justify-content-center mt-3'>
        <h4 className='text-center'>My Account</h4>
        {profileData == null ? (
          <TableLoader />
        ) : (
          <div className='mt-3 pb-4 pt-4 pr-4 pl-4 col-sm-4'>
            <div className='card home-card subscription-card mt-2'>
              <div className='card-body'>
                <div className='row card-text subscription-card-text mb-3'>
                  <div className='col-2 align-items-middle'>
                    <span className='name-abbr'>{profileData.firstName[0].toUpperCase()}</span>
                  </div>
                  <div className='col-10 align-grid-items-middle'>
                    <div className='fs-5'>
                      <span className='me-2 '>{profileData.firstName}</span>
                      <span>{profileData.lastName}</span>
                    </div>
                    <div className='fs-9 fw-light'>{profileData.agentCode}</div>
                  </div>
                </div>
                <div className='row card-text subscription-card-text mb-3'>
                  <div className='col-2 bi bi-envelope text-center'></div>
                  <div className='col-10 align-items-middle'>
                    <span className='fw-light fs-6'>{profileData.email}</span>
                  </div>
                </div>
                <div className='row card-text subscription-card-text mb-3'>
                  <div className='col-2  bi bi-telephone text-center'></div>
                  <div className='col-10 align-items-middle'>
                    <span className='fw-light fs-6'>{profileData.phoneNumber}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className='card home-card subscription-card mt-4'>
              <div className='card-body'>
                <div className='row card-text subscription-card-text mb-3'>
                  <div className='col-5 align-items-middle fw-light fs-7'>Status:</div>
                  <div className='col-7 align-items-middle fw-medium fs-7'>
                    <span>{profileData.status}</span>
                  </div>
                </div>
                <div className='row card-text subscription-card-text mb-3'>
                  <div className='col-5 align-items-middle fw-light fs-7'>Subscribers:</div>
                  <div className='col-7 align-items-middle fw-medium fs-7'>
                    <span>{profileData.numberOfSubscribers}</span>
                  </div>
                </div>
                <div className='row card-text subscription-card-text mb-3'>
                  <div className='col-5 align-items-middle fw-light fs-7'>Agents:</div>
                  <div className='col-7 align-items-middle fw-medium fs-7'>
                    <span>{0}</span>
                  </div>
                </div>
                <div className='row card-text subscription-card-text mb-3'>
                  <div className='col-5 align-items-middle fw-light fs-7'>Date Joined:</div>
                  <div className='col-7 align-items-middle fw-medium fs-7'>
                    <span>{formatDate(profileData.dateCreated)}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className='mt-5 text-center d-grid'>
              <a href={passwordResetUrl} className='btn btn-primary btn-height'>
                RESET PASSWORD
              </a>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Profile;
