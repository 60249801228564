import { useEffect } from 'react';
import { ISubscriptionDetailsProp } from '../../../interfaces/SubscriptionInterface';
import { isFormValidated } from '../../../utils/formUtils';
import { SubscriptionDetailsTab } from '../../../utils/mappings';

const NextOfKinScreen = ({
  subscription,
  tabKey,
  handleChange,
  submitForm,
  changeTab,
  nextOfKinData,
}: ISubscriptionDetailsProp) => {
  const save = (event: any) => {
    if (!isFormValidated('nok-form')) {
      return;
    }

    if (submitForm) {
      submitForm(event);
    }
  };

  const goBack = () => {
    if (changeTab) {
      changeTab(SubscriptionDetailsTab.plan);
    }
  };

  useEffect(() => {
    if (tabKey !== SubscriptionDetailsTab.nextOfKin || !nextOfKinData || !subscription) {
      return;
    }
  }, [nextOfKinData, tabKey, subscription]);

  return (
    <>
      <div className='container mt-5'>
        <div className='bi bi-chevron-left pointer' onClick={goBack}>
          Back
        </div>
        <form id='nok-form' className='row justify-content-center mt-4'>
          <h4 style={{ textAlign: 'center', color: '#000000' }}>Next of kin information</h4>
          <div className='col-md-4 col-sm-8 mt-2'>
            <div className='form-floating form-group mt-4'>
              <input
                value={nextOfKinData!.firstName}
                required
                className='form-control '
                type='text'
                onChange={handleChange('firstName')}
                placeholder='First Name *'
              />
              <label>First Name *</label>
            </div>
            <div className='form-floating form-group mt-4'>
              <input
                value={nextOfKinData!.lastName}
                required
                className='form-control '
                type='text'
                onChange={handleChange('lastName')}
                placeholder='Last Name *'
              />
              <label>Last Name *</label>
            </div>

            <div className='form-floating form-group mt-4'>
              <input
                value={nextOfKinData!.phoneNumber}
                maxLength={13}
                pattern='[0]{1}[7-9]{1}[0-2]{1}[0-9]{8}|[2]{1}[3]{1}[4]{1}[0-9]{10}'
                className='form-control '
                type='tel'
                required
                onChange={handleChange('phoneNumber')}
                placeholder='Phone Number *'
              />
              <label>Phone Number *</label>
            </div>

            <div className='form-floating form-group mt-4'>
              <input
                value={nextOfKinData!.email}
                className='form-control '
                type='email'
                onChange={handleChange('email')}
                placeholder='Email Address'
              />
              <label>Email Address</label>
            </div>

            <div className='form-floating form-group mt-4'>
              <input
                value={nextOfKinData!.relationship}
                required
                className='form-control '
                type='text'
                onChange={handleChange('relationship')}
                placeholder='Relationship *'
              />
              <label>Relationship *</label>
            </div>
            <div className='form-group d-grid' style={{ marginTop: '60px' }}>
              <button className='btn btn-primary btn-height' type='submit' onClick={save}>
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default NextOfKinScreen;
