import axios, { AxiosRequestConfig } from 'axios';
import { addToast } from './toastNotifications';

export const toggleSpinner = (state: string) => {
  const spinner = document.getElementById('request-spinner');

  if (spinner) {
    //if (spinner.classList.toString().includes("show")) {
    if (state.includes('show')) {
      spinner.classList.remove('d-none');
      spinner.classList.add('d-block');
    } else {
      spinner.classList.remove('d-block');
      spinner.classList.add('d-none');
    }
  }
};
// //add token to all request
axios.interceptors.request.use(
  function (config: AxiosRequestConfig) {
    if (config.method === 'get') {
      toggleSpinner('show');
    }

    return config;
  },
  function (error) {
    toggleSpinner('hide');
    Promise.reject(error);
  }
);

//Token in this instance will be a base64 encoded string of username and password
const setAuthToken = (token?: string): void => {
  if (token) {
    // console.log(token);
    // apply to every request
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } else {
    // delete auth header
    delete axios.defaults.headers.common.Authorization;
  }
};

// Add a 401 response0
axios.interceptors.response.use(
  (response) => {
    toggleSpinner('hide');
    return response;
  },
  (error) => {
    toggleSpinner('hide');
    // if error woth request, e.g network error
    // if (error.request) {
    //   return; // Promise.reject();
    // }

    if ((error.response && error.response.status) === 500) {
      addToast('There is a server error, Please contact admin. Code 500.', 'error');
    }

    if ((error.response && error.response.status) === 400) {
      addToast(
        'There is a problem with your request, check your payload and contact admin. Code 400',
        'error'
      );
    }

    if ((error.response && error.response.status) === 409) {
      addToast('There is a problem with your request, Data already exists. Code 409', 'error');
    }

    if ((error.response && error.response.status) === 403) {
      addToast('You do not have access to the resources', 'error');
    }

    // if ((error.response && error.response.status) === 401) {
    //   // // if we are on the login page, return promise so the calling page can handle
    //   // if (error.response.config.url.includes("/auth/token")) {
    //   //   return Promise.reject(error);
    //   // }
    //   addToast('Your session has expired. Please login again', 'error');
    //   window.location.href = '/';
    //   return false;
    // }
    return Promise.reject(error);
  }
);

export default setAuthToken;
