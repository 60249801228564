import { formatDisplayDateTime, timeOfDayGreeting } from '../../utils/dateTimeUtils';
import { Link } from 'react-router-dom';
import { endpoints } from '../../utils/URL';
import axios from 'axios';
import { useCallback, useContext, useEffect, useState } from 'react';
import { AppContext } from '../../context/AppContext';
import { addToast } from '../../utils/toastNotifications';

const Home = () => {
  //get agent details

  const { dispatch } = useContext(AppContext);
  const [userName, setUsername] = useState('');

  const getAgentDetails = useCallback(async () => {
    const url = `${endpoints.Agents.mainUrl}/WHAN`;
    try {
      const response = await axios.get(url);
      setUsername(`${response.data.firstName} ${response.data.lastName}`);
      dispatch({
        type: 'LOG_IN',
        payload: response.data,
      });
    } catch (error: any) {
      if (error.response && (error.response.status === 404 || error.response.status === 403)) {
        addToast('You do not have access to this app', 'error');

        dispatch({
          type: 'LOG_OUT',
        });
        window.location.href = '/';
      }
    }
  }, [dispatch]);

  useEffect(() => {
    getAgentDetails();
  }, [getAgentDetails]);

  return (
    <div>
      <div className='container'>
        <div className='row justify-content-center'>
          <div className='col-sm-10'>
            <div className='fs-5 fw-light'>{timeOfDayGreeting(userName)}</div>
            <div style={{ fontSize: '0.9rem' }} className='pl-5 fw-light'>
              {formatDisplayDateTime()}
            </div>

            <br />
            <div className='row mb-4'>
              <div
                className='col-sm-6 col-md-4 home-card card mt-3'
                style={{ background: 'linear-gradient(109.04deg, #1489C8 3.23%, #064D73 132.43%)' }}
              >
                <div className='row'>
                  <div className='card-body form-group'>
                    <div className='row'>
                      <div className='col-6' style={{ margin: 'auto', color: '#fff' }}>
                        <div>
                          <div className='mt-2 home-card-subtitle'>Total Earnings</div>
                          <div className='home-card-title fs-3'>₦0</div>
                        </div>
                        <div>
                          <div className='mt-2 home-card-subtitle'>Total Subscriptions</div>
                          <div className='home-card-title fs-3'>0</div>
                        </div>
                      </div>
                      <div className='col-6 align-items-middle'>
                        <img
                          className='img-fluid'
                          style={{ width: '100%' }}
                          src={require('../../assets/icons/work-rate.svg').default}
                          alt=''
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div style={{ fontSize: '0.9rem' }}>What would you like to do today?</div>
            <div className='row '>
              <Link to='/subscriptions' className='home-card col-sm-6 col-md-4 mt-3'>
                <div className='card home-card '>
                  <div className='card-body form-group'>
                    <div className='row'>
                      <div className='col-2 align-items-middle'>
                        <img
                          src={require('../../assets/icons/subscription-icon.svg').default}
                          alt=''
                        />
                      </div>
                      <div className='col-8' style={{ margin: 'auto' }}>
                        <div className='mt-2 home-card-title fw-medium'>Subscriptions</div>
                        <div className='mt-2 home-card-subtitle'>
                          Manage your subscribers using their details
                        </div>
                      </div>
                      <div className='col-2 align-items-middle'>
                        <img src={require('../../assets/icons/chevron-right.svg').default} alt='' />
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
              <Link to='/agents' className='home-card col-sm-6 col-md-4 mt-3'>
                <div className='card home-card '>
                  <div className='card-body form-group'>
                    <div className='row'>
                      <div className='col-2 align-items-middle'>
                        <img src={require('../../assets/icons/agent-icon.svg').default} alt='' />
                      </div>
                      <div className='col-8' style={{ margin: 'auto' }}>
                        <div className='mt-2 home-card-title  fw-medium'>Agents</div>
                        <div className='mt-2 home-card-subtitle'>
                          Monitor the activities of all your agents
                        </div>
                      </div>
                      <div className='col-2 align-items-middle'>
                        <img src={require('../../assets/icons/chevron-right.svg').default} alt='' />
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
              <Link to='/wallet' className='home-card col-sm-6 col-md-4 mt-3'>
                <div className='card home-card '>
                  <div className='card-body form-group'>
                    <div className='row'>
                      <div className='col-2 align-items-middle'>
                        <img src={require('../../assets/icons/wallet-icon.svg').default} alt='' />
                      </div>
                      <div className='col-8' style={{ margin: 'auto' }}>
                        <div className='mt-2 home-card-title  fw-medium'>Float Wallet</div>
                        <div className='mt-2 home-card-subtitle'>
                          Top-up your wallet or request a withdrawal
                        </div>
                      </div>
                      <div className='col-2 align-items-middle'>
                        <img src={require('../../assets/icons/chevron-right.svg').default} alt='' />
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
              {/* <Link to='/wellapay/users' className='home-card col-sm-6 col-md-4 mt-3'>
                <div className='card home-card '>
                  <div className='card-body form-group'>
                    <div className='row'>
                      <div className='col-2 align-items-middle'>
                        <img src={require('../../assets/icons/wellapay-icon.svg').default} alt='' />
                      </div>
                      <div className='col-8' style={{ margin: 'auto' }}>
                        <div className='mt-2 home-card-title  fw-medium'>WellaPay</div>
                        <div className='mt-2 home-card-subtitle'>
                          Manage your WellaPay customers
                        </div>
                      </div>
                      <div className='col-2 align-items-middle'>
                        <img src={require('../../assets/icons/chevron-right.svg').default} alt='' />
                      </div>
                    </div>
                  </div>
                </div>
              </Link> */}
              <Link to='/esusu' className='home-card col-sm-6 col-md-4 mt-3'>
                <div className='card home-card'>
                  <div className='card-body form-group'>
                    <div className='row'>
                      <div className='col-2 align-items-middle'>
                        <img src={require('../../assets/icons/person.svg').default} alt='' />
                      </div>
                      <div className='col-8' style={{ margin: 'auto' }}>
                        <div className='mt-2 home-card-title  fw-medium'>Esusu</div>
                        <div className='mt-2 home-card-subtitle'>
                          Manage your Esusu daily payments
                        </div>
                      </div>
                      <div className='col-2 align-items-middle'>
                        <img src={require('../../assets/icons/chevron-right.svg').default} alt='' />
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
