//import { ReactComponentElement, ReactNode } from 'react';

export interface IRoute {
  name: string;
  path: string;
  tags: string;
  icon: string;
}

export interface IPageInfo {
  pageIndex: number;
  pageSize: number;
  pageCount: number;
}

export interface PaginationInfo {
  currentPageNumber: number;
  pageSize: number;
  numberOfPages: number;
  //callback?: () => void;
  callback?: (pageInfo: IPageInfo) => any;
}

//default initializations

export const defaultPageInfo: IPageInfo = {
  pageIndex: 1,
  pageSize: 100,
  pageCount: 1,
};

export interface IConfirmationModal {
  show: boolean;
  hide: Function;
  title: string;
  content: string;
  okText?: string;
  cancelText?: string;
  okCallback?: Function;
  cancelCallback?: Function;
}
