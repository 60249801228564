export function isFormValidated(formName) {
  let form = document.getElementById(formName);
  let isValid = form !== null ? form.checkValidity() : false;
  return isValid;
}

export const calculateMessageCount = (messageLength) => {
  let messageMetaData = {
    pageCount: 1,
    totalCount: 160,
    characterCount: messageLength,
  };

  if (messageLength > 765 && messageLength <= 918) {
    messageMetaData.pageCount = 6;
    messageMetaData.totalCount = 918;
  } else if (messageLength > 612 && messageLength <= 765) {
    messageMetaData.pageCount = 5;
    messageMetaData.totalCount = 765;
  } else if (messageLength > 459 && messageLength <= 612) {
    messageMetaData.pageCount = 4;
    messageMetaData.totalCount = 612;
  } else if (messageLength > 306 && messageLength <= 459) {
    messageMetaData.pageCount = 3;
    messageMetaData.totalCount = 459;
  } else if (messageLength > 160 && messageLength <= 306) {
    messageMetaData.pageCount = 2;
    messageMetaData.totalCount = 306;
  } else if (messageLength >= 0 && messageLength <= 160) {
    messageMetaData.pageCount = 1;
    messageMetaData.totalCount = 160;
  }

  return messageMetaData;
};

export const validatePhoneNumber = (phone) => {
  let pattern = '(^(?:[+0]9)?[0-9]{11,13}$)';
  let message = '';
  let regExp = new RegExp(pattern);
  if (phone.length < 11 || phone.length > 13) {
    message = 'Phone number must be between 11 and 13';
    return message;
  } else if (!regExp.test(phone)) {
    message = 'Please enter a phone number between 11 and 13 digits';
    return message;
  }
};

export const scrollToTop = () => {
  document.getElementById('root').scrollIntoView();
};
