import React from 'react';

const PageHeader = () => {
  return (
    <nav className='secondary-color navbar navbar-light flex-md-nowrap p-0 navbar-custom'>
      <div className='' style={{ margin: '10px 0px 15px 70px' }}>
        <a href='/dashboard'>
          <img
            src={require('../../assets/images/wellahealth-plain.svg').default}
            alt='wellahealth'
            width='150'
          />
        </a>
      </div>
      {/* <button
                // className='navbar-toggler position-absolute d-md-none collapsed'
                className='navbar-toggler position-absolute collapsed'
                type='button'
                data-toggle='collapse'
                data-target='#sidebarMenu'
                aria-controls='sidebarMenu'
                aria-expanded='false'
                aria-label='Toggle navigation'
            >
                <span
                    className='navbar-toggler-icon'
                    data-toggle='collapse'
                    data-target='#sidebarMenu'
                ></span>
            </button> */}
    </nav>
  );
};

export default PageHeader;
