let BASE_URL = process.env.REACT_APP_BASE_URL;
let BASE_API_URL = process.env.REACT_APP_BASE_API_URL;
let ACCOUNT_BASE_URL = process.env.REACT_APP_ACCOUNT_BASE_URL;
let REWARDS_BASE_URL = process.env.REACT_APP_REWARDS_BASE_URL;

let endpoints = {
  Auth: {},
  Subscriptions: {
    mainUrl: BASE_URL + '/agents/subscriptions',
  },
  Claims: {
    mainUrl: BASE_URL + '/agents/claims',
  },
  Agents: {
    subAgents: BASE_URL + '/agents/users/subagents',
    mainUrl: BASE_URL + '/agents/users',
  },
  UserWallets: {
    mainUrl: BASE_URL + '/agents/users/wallets',
    withdrawal: BASE_URL + '/agents/users/wallets/withdrawals',
    transactions: BASE_URL + '/agents/users/wallets/transactions',
  },
  Rewards: {
    mainUrl: REWARDS_BASE_URL,
  },
  Accounts: {
    mainUrl: ACCOUNT_BASE_URL,
  },
  Tasks: {
    mainUrl: BASE_API_URL + '/task',
  },
  Wallets: {
    mainUrl: BASE_URL + '/wallets',
  },
  Esusu: {
    Customers: {
      mainUrl: BASE_URL + '/esusu/customers',
    },
    Payments: {
      mainUrl: BASE_URL + '/esusu/payments',
    },
  },
};

export { endpoints };
