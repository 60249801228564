import axios from 'axios';
import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../context/AppContext';
import { isFormValidated } from '../../../utils/formUtils';
import { IWalletAccountSummary, walletData } from '../../../interfaces/WalletInterface';
import { addToast } from '../../../utils/toastNotifications';
import { endpoints } from '../../../utils/URL';
import { IAppState } from '../../../interfaces/AppInterface';
import ButtonLoader from '../../common/ButtonLoader';

const WalletWithdrawal = ({ history }: any) => {
  const { appState } = useContext(AppContext);

  const [walletSummary, setWalletSummary] = useState<IWalletAccountSummary>(walletData);
  const [withdrawalAmount, setWithdrawalAmount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const changeAmount = (event: any) => {
    const amount = parseFloat(event.target.value);

    if (amount > walletSummary.walletBalance) {
      addToast('Withdrawal amount exceeds wallet balance');
    }

    setWithdrawalAmount(amount);
  };

  const requestWithdrawal = async (event: any) => {
    if (!isFormValidated('withdrawal-request')) {
      return;
    }

    event.preventDefault();

    if (withdrawalAmount > walletSummary.walletBalance) {
      addToast('Withdrawal amount exceeds wallet balance');
      return;
    }

    const payload = {
      amount: withdrawalAmount,
    };
    const url = `${endpoints.UserWallets.withdrawal}`;
    setIsLoading(true);
    try {
      await axios.post(url, payload);
      addToast('Withdrawal request successful', 'success');
      history.push('/wallet');
    } catch (error: any) {
      console.error(error);
      if (error.response) {
        if (error.response.status === 404 || error.response.status === 422) {
          addToast(error.response.data.message, 'error');
        } else {
          addToast('server error occured', 'error');
        }
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const applicationState = appState as IAppState;

    let wallet = applicationState.wallet;

    if (wallet === null || typeof wallet === 'undefined' || wallet?.bankAccountNumber === '') {
      //push state
      history.push('/wallet');
      return;
    }
    wallet = wallet as IWalletAccountSummary;
    setWalletSummary(wallet);
  }, [history, appState]);

  return (
    <div>
      <h5 className='text-center' style={{ color: '#191489' }}>
        Request for Withdrawal
      </h5>
      <br />
      <h4 className='text-center'>
        Available Balance:
        <span className={walletSummary.walletBalance >= 0 ? 'text-success' : 'text-danger'}>
          ₦ {walletSummary.walletBalance.toLocaleString()}
        </span>
      </h4>
      <div className='row justify-content-center'>
        <div
          className='col-sm-6 col-md-4 col-lg-4'
          style={{
            marginTop: '30px',
            padding: '20px',
          }}
        >
          <form id='withdrawal-request'>
            <fieldset disabled={isLoading}>
              <div className='form-group mb-4'>
                <input
                  type='number'
                  className='form-control'
                  required
                  min={1000}
                  max={walletSummary.walletBalance}
                  onChange={changeAmount}
                  placeholder='Enter withdrawal amount'
                />
                <small className='fs-8'>Minimum of ₦1000</small>
              </div>

              <div className='card home-card subscription-card primary-bg-color-light '>
                <div className='card-body'>
                  <div className='row card-text subscription-card-text mb-1'>
                    <div className='primary-color'>Receiving account</div>
                    <div className='fs-6 mt-3 fw-light'>
                      Bank Name: <span className='ms-2 fw-medium'>{walletSummary.bankName}</span>
                    </div>
                    <div className='fs-6 mt-3 fw-light'>
                      Account Name:
                      <span className='ms-2 fw-medium'>{walletSummary.bankAccountName}</span>
                    </div>
                    <div className='fs-6 mt-3 fw-light'>
                      Account Number:
                      <span className='ms-2 fw-medium'>{walletSummary.bankAccountNumber}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className='d-grid mt-4'>
                <button type='submit' className='btn btn-primary' onClick={requestWithdrawal}>
                  Request for Withdrawal
                  {isLoading ? <ButtonLoader /> : ''}
                </button>
              </div>
            </fieldset>
          </form>
        </div>
      </div>
    </div>
  );
};

export default WalletWithdrawal;
