const NoDataView = () => {
  return (
    <div className='text-center mt-5'>
      <img src={require('../../assets/icons/empty-box.svg').default} alt='' />
      <div className='mt-3'>No data found</div>
    </div>
  );
};

export default NoDataView;
