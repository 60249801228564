export interface IAgentDataDTO {
  insuranceAgentId: number;
  agentCode: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  status: string;
  numberOfSubscribers: number;
  superAgentName: string;
  dateCreated: string;
}

export interface INewAgentDataDTO {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  password: string;
  location: string;
  superAgentCode: string;
}

export interface INewSuperAgentDataDTO {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  stateOfResidence: string;
  lga: string;
  area: string;
  streetName: string;
  location: string;
  bankName: string;
  bankAccountName: string;
  bankAccountNumber: string;
  bvn: string;
  guarantor1: string;
  guarantor2: string;
}

export const defaultSuperAgent: INewSuperAgentDataDTO = {
  firstName: '',
  lastName: '',
  phoneNumber: '',
  email: '',
  stateOfResidence: 'Abia',
  lga: '',
  area: '',
  streetName: '',
  location: '',
  bankName: 'Access Bank',
  bankAccountName: '',
  bankAccountNumber: '',
  bvn: '',
  guarantor1: '',
  guarantor2: '',
};

export const defaultNewAgent: INewAgentDataDTO = {
  firstName: '',
  lastName: '',
  phoneNumber: '',
  password: '',
  email: '',
  location: '',
  superAgentCode: '',
};
