import React from 'react';
import { ISubscriptionResponse } from './SubscriptionInterface';
import { IWalletAccountSummary, walletData } from './WalletInterface';

export interface IAppState {
  isAuthenticated: boolean;
  firstName: string;
  lastName: string;
  agentCode: string;
  userRole: string;
  wallet?: IWalletAccountSummary;
  selectedSubscription?: ISubscriptionResponse;
  customerId: number;
}

export interface IDispatch {
  type: string;
  payload: any;
  propName: string;
  propData?: any;
}

export interface IContextProps {
  children: React.ReactNode;
}

export interface IPrivateRouteProps {
  exact?: boolean;
  path: string;
  component: any; //React.ComponentType<any>;
}

export const initialAppState: IAppState = {
  isAuthenticated: false,
  firstName: '',
  lastName: '',
  agentCode: '',
  userRole: 'SubAgent',
  wallet: walletData,
  customerId: 0
};
