import { useState, useCallback, useEffect, useContext } from 'react';
import { endpoints } from '../../../utils/URL';
import axios from 'axios';
import {
  // initialSubscriptionResponse,
  ISubscriptionResponse,
} from '../../../interfaces/SubscriptionInterface';
import { addToast } from '../../../utils/toastNotifications';
import { Link, useParams } from 'react-router-dom';
import { formatDate } from '../../../utils/dateTimeUtils';
import { defaultPageInfo } from '../../../interfaces/CommonInterface';
import PaginationBar from '../../common/Pagination';
import ButtonLoader from '../../common/ButtonLoader';
import { AppContext } from '../../../context/AppContext';
import { LoadingStatus } from '../../../utils/mappings';
import TableLoader from '../../common/TableLoader';
import NoDataView from '../../common/NoDataView';

const Subscriptions = ({ history }: any) => {
  const [allData, setAllData] = useState<ISubscriptionResponse[]>([]); //to hold all table result in unfiltered format
  const [subscriptionData, setSubscriptionData] = useState<ISubscriptionResponse[]>([]);
  const [pageInfo, setPageInfo] = useState(defaultPageInfo);
  const [showPagination, setShowPagination] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [loadingState, setLoadingState] = useState(LoadingStatus.LOADING);
  const [advSearch, setAdvSearch] = useState(false);
  const [isSearch, setIsSearch] = useState(false);

  const searchParam = new URLSearchParams(window.location.search);
  const subStatus = searchParam.get('status');
  //const searchType = searchParam.get('')

  const { agentCode } = useParams() as any;
  const { appState, dispatch } = useContext(AppContext);

  const loadSubscriptions = useCallback(
    async (pageIndex: number) => {
      let localAgentCode = appState.agentCode;

      if (typeof agentCode !== 'undefined' && agentCode !== '') {
        localAgentCode = agentCode;
      }

      let url = `${endpoints.Subscriptions.mainUrl}/${localAgentCode}?pageSize=12&pageIndex=${pageIndex}`;

      if (subStatus != null) {
        url = `${endpoints.Subscriptions.mainUrl}/subagent?status=${subStatus}&pageSize=12&pageIndex=${pageIndex}`;
      }

      setLoadingState(LoadingStatus.LOADING);

      try {
        const response = await axios.get(url);

        setLoadingState(LoadingStatus.LOADED);

        setSubscriptionData(response.data.data);
        setAllData(response.data.data);
        response.data.data.length > 0 ? setShowPagination(true) : setShowPagination(false);
        const responsePageInfo = {
          pageIndex: response.data.pageIndex,
          pageSize: response.data.pageSize,
          pageCount: response.data.pageCount,
        };
        //set page info
        setPageInfo(responsePageInfo);
      } catch (error) {
        addToast('Error getting Subscriptions', 'error');
        setLoadingState(LoadingStatus.NODATA);
      }
    },
    [subStatus, agentCode, appState.agentCode]
  );

  const approveSubscription = async (subscriptionCode: string) => {
    const url = `${endpoints.Subscriptions.mainUrl}`;

    const payload = {
      subscriptionCode: subscriptionCode,
      subscriptionStatus: 'Active',
    };
    setLoading(true);
    try {
      await axios.put(url, payload);

      addToast('Subscription approved', 'success');
      loadSubscriptions(1);
    } catch (error) {
      addToast('An error occured', 'error');
    } finally {
      setLoading(false);
    }
  };

  const searchTable = (event: any) => {
    //search loaded data
    let searchParam = event.target.value;
    if (searchParam === '') {
      setSubscriptionData(allData);
      setShowPagination(true);
      setAdvSearch(false);
      return;
    }
    setShowPagination(false);
    searchParam = searchParam.toLowerCase();
    const searchResult = allData.filter(
      (x) =>
        x.firstName?.toLowerCase().includes(searchParam) ||
        x.lastName?.toLowerCase().includes(searchParam) ||
        x.phone?.toLowerCase().includes(searchParam) ||
        x.email?.toLowerCase().includes(searchParam)
    );
    //set table data to search result
    setSubscriptionData(searchResult);
    if (searchResult.length === 0 && searchParam.length >= 4) {
      //addToast('Searching data remote');
      //add delay if user is stil typing
      setAdvSearch(true);
    } else {
      setLoading(false);
    }
  };

  const searchRemoteData = async () => {
    let searchParamInput = document.getElementById('sub-search-param') as HTMLInputElement;
    const advSearchParam = searchParamInput.value;

    if (advSearchParam === '') {
      return;
    }

    const url = `${endpoints.Subscriptions.mainUrl}/search?searchparam=${advSearchParam}`;
    setIsSearch(true);

    try {
      const response = await axios.get(url);
      setSubscriptionData(response.data);
    } catch (error) {
      addToast('An error occured');
    } finally {
      setIsSearch(false);
    }
  };

  useEffect(() => {
    const runOnComponentMount = async () => {
      await loadSubscriptions(1);
    };
    runOnComponentMount();
  }, [loadSubscriptions]);

  const getStatusColor = (subscriptionStatus: string) => {
    switch (subscriptionStatus) {
      case 'Active':
        return 'text-success';
      case 'MissedPayment':
        return 'text-danger';
      case 'Cancelled':
        return 'text-danger';
      default:
        return 'text-info';
    }
  };

  let subscriberCards: JSX.Element = (
    <div className=''>
      {subscriptionData ? (
        <div className='row'>
          {subscriptionData.map((subscription: ISubscriptionResponse) => {
            return (
              <div
                className='col-sm-6 col-md-4 home-card mt-4'
                key={subscription.subscriptionCode}
                onClick={() => selectSubscription(subscription)}
              >
                <div className='card home-card subscription-card '>
                  <div className='card-body'>
                    <div className='row card-text subscription-card-text mb-1'>
                      <div className='col-6 fs-5'>
                        {subscription.firstName} {subscription.lastName}
                      </div>
                      <div className='col-6 text-end fs-6 fw-light'>{subscription.paymentPlan}</div>
                    </div>
                    <div className='row card-text subscription-card-text mb-3'>
                      <div className='col-6 fs-6 fw-light'> {subscription.phone} </div>
                      <div className='col-6 text-end'>{subscription.product}</div>
                    </div>

                    <div className='row card-text subscription-card-text'>
                      <div className='col-6'>
                        <div className={getStatusColor(subscription.status)}>
                          {subscription.status}
                        </div>
                        <div style={{ fontSize: '0.85rem' }}>
                          <span className='fw-light'>Due date:</span>
                          {formatDate(subscription.nextPayment)}
                        </div>
                      </div>
                      <div className='col-6 align-items-bottom-right'>
                        {subscription.numberOfSubscribers} beneficiaries
                      </div>
                    </div>
                    {subStatus != null &&
                    subStatus === 'Unpaid' &&
                    subscription.status === 'Unpaid' ? (
                      <div className='row card-text subscription-card-text'>
                        <div className='col-6'>
                          <button
                            className='btn btn-sm btn-success'
                            onClick={() => approveSubscription(subscription.subscriptionCode)}
                          >
                            Approve {isLoading ? <ButtonLoader /> : ''}
                          </button>
                        </div>
                        {/* <div className='col-6'>
                          <button className='btn btn-sm btn-warning float-end'>Decline</button>
                        </div> */}
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <NoDataView />
      )}
    </div>
  );

  const selectSubscription = async (data: ISubscriptionResponse) => {
    dispatch({
      type: 'UPDATE_PROP',
      propName: 'selectedSubscription',
      propData: data,
    });
    history.push('/subscriptions/details');
  };

  return (
    <>
      <div className='container'>
        <h3 className='mr-auto'>My Subscriptions </h3>
        <div className='row'>
          <div className='col-sm-6 mt-4'>
            <div className='row'>
              <div className='col-8'>
                <input
                  type='search'
                  id='sub-search-param'
                  className='form-control'
                  placeholder='search by subscriber name or phone numbers'
                  onChange={searchTable}
                />
              </div>
              {advSearch ? (
                <div className=' col-4'>
                  <button className='btn btn-success' onClick={searchRemoteData}>
                    {isSearch ? <ButtonLoader /> : 'Search'}
                  </button>
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
          <div className='col-sm-6 text-sm-end mt-4'>
            <button className='btn btn-primary'>
              <Link to='/subscriptions/add' style={{ textDecoration: 'none', color: 'white' }}>
                Add Subscription
              </Link>
            </button>
          </div>
        </div>
        <br />
      </div>
      <div className='container'>
        {/* <div className=''>{subscriberCards}</div> */}
        {loadingState === LoadingStatus.NODATA ||
          (loadingState === LoadingStatus.LOADED && subscriptionData.length === 0 && (
            <NoDataView />
          ))}
        {loadingState === LoadingStatus.LOADING && <TableLoader />}
        {loadingState === LoadingStatus.LOADED && subscriberCards}

        <br />
        {showPagination ? (
          <PaginationBar
            currentPageNumber={pageInfo.pageIndex}
            numberOfPages={pageInfo.pageCount}
            callback={loadSubscriptions}
          />
        ) : (
          ''
        )}
      </div>
    </>
  );
};

export default Subscriptions;
