import { useState, useCallback, useEffect, useContext } from 'react';
import { endpoints } from '../../../utils/URL';
import axios from 'axios';
import { IAgentDataDTO } from '../../../interfaces/AgentInterface';
import { addToast } from '../../../utils/toastNotifications';
import { useHistory } from 'react-router-dom';
import ButtonLoader from '../../common/ButtonLoader';
import { AppContext } from '../../../context/AppContext';
import { LoadingStatus } from '../../../utils/mappings';
import TableLoader from '../../common/TableLoader';

const SubAgentsPage = () => {
  const [agentData, setAgentData] = useState<IAgentDataDTO[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { appState } = useContext(AppContext);
  const [loadingState, setLoadingState] = useState(LoadingStatus.LOADING);

  const history = useHistory();

  const loadAgents = useCallback(async () => {
    const agentCode = appState.agentCode;

    try {
      const response = await axios.get(`${endpoints.Agents.subAgents}/${agentCode}`);
      setAgentData(response.data);
      setLoadingState(LoadingStatus.LOADED);
    } catch (error) {
      addToast('Error getting agents', 'error');
      setLoadingState(LoadingStatus.NODATA);
    }
  }, [appState.agentCode]);

  const toggleAgentStatus = async (agentCode: string) => {
    const url = `${endpoints.Agents.mainUrl}/status`;

    const agent = agentData.filter((agent) => agent.agentCode === agentCode)[0];
    const agentStatus = agent.status === 'Active' ? 'Inactive' : 'Active';

    const payload = {
      agentCode: agentCode,
      status: agentStatus,
    };

    setIsLoading(true);

    try {
      await axios.put(url, payload);
      addToast('Agent status changed successfully', 'success');
      loadAgents();
    } catch (error) {
      addToast('An error occured', 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const showSubscriptions = (agentCode: string) => {
    history.push(`/subscriptions/view/${agentCode}`);
  };

  useEffect(() => {
    loadAgents();
  }, [loadAgents]);

  let noDataView = (
    <div className='text-center mt-5'>
      <img src={require('../../../assets/icons/empty-box.svg').default} alt='' />
      <div className='mt-3'>No Agents found</div>
    </div>
  );

  let agentCards: JSX.Element = (
    <div className=''>
      {agentData ? (
        <div className='row'>
          {agentData.map((agent: IAgentDataDTO) => {
            return (
              <div className='col-md-4 mt-4' key={agent.insuranceAgentId}>
                <div className='card borderless'>
                  <div className='card-body'>
                    <div className='row card-title'>
                      <div className='col-6 fw-medium'>
                        {agent.firstName} {agent.lastName}
                      </div>
                      <div className='col-6 text-end fw-light'> {agent.agentCode} </div>
                    </div>
                    <div className='card-text subscription-card-text fw-light'>
                      {agent.phoneNumber}
                    </div>

                    <div className='row card-text subscription-card-text mt-5'>
                      <div className='col-6' style={{ fontWeight: 600 }}>
                        <button
                          className={
                            agent.numberOfSubscribers > 0
                              ? 'btn btn-sm btn-primary'
                              : 'btn btn-sm btn-secondary'
                          }
                          onClick={() => showSubscriptions(agent.agentCode)}
                        >
                          Subscriptions - {agent.numberOfSubscribers}
                        </button>
                        {/* {formatDate(agent.dateCreated)} */}
                      </div>
                      <div className='col-6 text-success'>
                        <button
                          className={
                            agent.status === 'Inactive'
                              ? 'btn btn-sm float-end primary-color'
                              : 'btn btn-sm float-end text-danger'
                          }
                          onClick={() => toggleAgentStatus(agent.agentCode)}
                        >
                          {agent.status === 'Active' ? 'Deactivate' : 'Activate'}
                          {isLoading ? <ButtonLoader /> : ''}
                        </button>
                      </div>
                      {/* // ) : (
                      //   ''
                      // )} */}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        noDataView
      )}
    </div>
  );

  return (
    <>
      <div className='container'>
        <h3 className='mr-auto text-center'>My Agents </h3>

        {/* <div className='text-right'>
          <button className='btn btn-primary'>
            <Link to='/subscriptions/add' style={{ textDecoration: 'none', color: 'white' }}>
              Add Agent
            </Link>
          </button>
        </div> */}
        <br />
      </div>
      <div className='container'>
        {loadingState === LoadingStatus.NODATA ||
          (loadingState === LoadingStatus.LOADED && agentData.length === 0 && noDataView)}
        {loadingState === LoadingStatus.LOADING && <TableLoader />}
        {loadingState === LoadingStatus.LOADED && agentCards}
        <br />
      </div>
    </>
  );
};

export default SubAgentsPage;
