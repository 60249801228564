import axios from 'axios';
import { useState } from 'react';
import {
  defaultWellaPayUser,
  IWellaPayBvnDetails,
  IWellaPayUser,
} from '../../../interfaces/WellaPayUser';
import { formatInputDate } from '../../../utils/dateTimeUtils';
import { isFormValidated } from '../../../utils/formUtils';
import { GENDER_MAPPING } from '../../../utils/mappings';
import { STATES_LGA } from '../../../utils/states_lga';
import { addToast } from '../../../utils/toastNotifications';
import { endpoints } from '../../../utils/URL';
import ButtonLoader from '../../common/ButtonLoader';
import ConfirmationModal from '../../common/ConfirmationModal';

const AddWellaPayUserScreen = () => {
  //
  const [localState, setLocalState] = useState<IWellaPayUser>(defaultWellaPayUser);
  const [isLoading, setLoading] = useState(false);
  const [isBvnVerified, setBvnVerified] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const handleChange = (inputName: string) => (event: any) => {
    setLocalState({
      ...localState,
      [inputName]: event.target.value,
    });
  };

  const statesType = STATES_LGA;
  const statesMap = statesType.map((states) => {
    return {
      key: states.id,
      text: states.state,
      value: states.state,
    };
  });

  const state_filter = STATES_LGA.filter((selected_state) => {
    return selected_state.state === localState.state.trim();
  });

  let lgas = state_filter[0].locals.map((lga) => {
    return {
      key: lga.id,
      text: lga.name,
      value: lga.name,
    };
  });

  const saveAccount = async (event: any) => {
    if (!isFormValidated('account-form')) {
      return;
    }

    //if bvn was skipped, use the phone number
    if (
      localState.identificationNumber === '' ||
      localState.identificationNumber === '00000000000'
    ) {
      localState.identificationNumber = localState.phoneNumber;
    }

    event.preventDefault();

    const url = `${endpoints.Accounts.mainUrl}`;

    setLoading(true);
    try {
      await axios.post(url, localState);
      addToast('Account created successfully', 'success');
      setLocalState(defaultWellaPayUser);
      setBvnVerified(false);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const verifyBvn = async (event: any) => {
    if (!isFormValidated('bvn-form')) {
      return;
    }

    event.preventDefault();
    const bvnElement = document.getElementById('bvn') as any;
    const bvn = bvnElement.value;

    const url = `${endpoints.Wallets.mainUrl}/bvn/verify/${bvn}`;
    try {
      const response = await axios.get(url);

      const bvnDetails: IWellaPayBvnDetails = response.data;

      const dateOfBirthStr = bvnDetails.dateOfBirth.replaceAll('-', '/').trim();

      const dateOfBirth = new Date(dateOfBirthStr).toISOString().split('T')[0];

      console.log(bvnDetails.stateOfResidence.replace('State', ''));
      const newUser: IWellaPayUser = {
        firstName: bvnDetails.firstName,
        lastName: bvnDetails.lastName,
        dateOfBirth: dateOfBirth, //bvnDetails.dateOfBirth,
        phoneNumber: bvnDetails.phoneNumber1,
        email: bvnDetails.email,
        //state: '',
        state: bvnDetails.stateOfResidence.replace('State', '').trim(),
        addressLine: `${bvnDetails.residentialAddress}, ${bvnDetails.lgaOfResidence} ${bvnDetails.stateOfResidence}`,
        gender: bvnDetails.gender,
        city: bvnDetails.lgaOfResidence,
        identificationNumber: bvn,
        identificationType: 'BVN',
        country: bvnDetails.nationality,
        middleName: bvnDetails.middleName,
        customerType: 'Individual',
      };

      setLocalState(newUser);

      // setLocalState({
      //   ...localState,
      //   identificationNumber: bvn,
      // });
      setBvnVerified(true);
    } catch (error) {
      addToast('Bvn could not be verified. Continue?');
      setShowModal(true);
    }
  };

  const skipBvnCheck = () => {
    setBvnVerified(true);
    setLocalState({
      ...localState,
      identificationNumber: '00000000000',
    });
  };

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const confirmationModal = (
    <ConfirmationModal
      show={showModal}
      title='Continue without BVN'
      content='Do you want to continue account creation? For best experience please validate the BVN'
      hide={toggleModal}
      okText='Continue'
    />
  );

  return (
    <>
      <div className='container'>
        {confirmationModal}
        {!isBvnVerified ? (
          <form id='bvn-form' className='row justify-content-center mt-5'>
            <div className='col-md-4 mt-3'>
              <div className='form-group'>
                <label className='form-label'>
                  BVN <span className='text-danger'>*</span>
                </label>
                <input
                  id='bvn'
                  required
                  className='form-control'
                  type='text'
                  minLength={11}
                  maxLength={11}
                />
              </div>
              <br />
              <div className='d-grid'>
                <button className='btn btn-primary' onClick={verifyBvn}>
                  Verify BVN
                  {isLoading ? <ButtonLoader /> : ''}
                </button>
              </div>
              <div className='text-center mt-3 pointer fs-7' onClick={skipBvnCheck}>
                Skip BVN
              </div>
            </div>
          </form>
        ) : (
          <div>
            <h4 style={{ textAlign: 'center', color: '#000000' }}>Add Account</h4>
            <br />
            <form id='account-form'>
              <fieldset disabled={isLoading} className='row justify-content-center '>
                <div className='col-md-4 col-sm-8'>
                  <div className='form-group mt-4'>
                    <input
                      value={localState.firstName}
                      required
                      className='form-control'
                      type='text'
                      onChange={handleChange('firstName')}
                      placeholder='First Name *'
                    />
                  </div>
                  <div className='form-group mt-4'>
                    <input
                      value={localState.lastName}
                      required
                      className='form-control '
                      type='text'
                      onChange={handleChange('lastName')}
                      placeholder='Last Name *'
                    />
                  </div>
                  <div className='form-group mt-4'>
                    <input
                      value={localState.phoneNumber}
                      maxLength={13}
                      pattern='[0]{1}[7-9]{1}[0-2]{1}[0-9]{8}|[2]{1}[3]{1}[4]{1}[0-9]{10}'
                      required
                      className='form-control '
                      type='tel'
                      onChange={handleChange('phoneNumber')}
                      placeholder='Phone Number *'
                    />
                  </div>
                  <div className='form-group mt-4'>
                    <input
                      value={localState.email}
                      className='form-control '
                      type='email'
                      placeholder='Email address'
                      onChange={handleChange('email')}
                    />
                  </div>

                  <div className='form-group mt-4'>
                    <label className='form-label'>
                      Date of Birth <span className='text-danger'>*</span>
                    </label>
                    <input
                      value={formatInputDate(localState.dateOfBirth)}
                      required
                      className='form-control '
                      type='date'
                      onChange={handleChange('dateOfBirth')}
                    />
                  </div>
                  <div className='form-group mt-4'>
                    <label className='form-label'>
                      Gender <span className='text-danger'>*</span>
                    </label>
                    <select
                      value={localState.gender}
                      onChange={handleChange('gender')}
                      className='form-control custom-select'
                    >
                      {GENDER_MAPPING.map((option) => (
                        <option key={option.key} value={option.value}>
                          {option.text}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className='form-group mt-4'>
                    <textarea
                      value={localState.addressLine}
                      required
                      className='form-control'
                      onChange={handleChange('addressLine')}
                      placeholder='Address'
                    />
                  </div>

                  <div className='form-group mt-4'>
                    <label className='form-label'>
                      State Of Residence <span className='text-danger'>*</span>
                    </label>
                    <select
                      required
                      onChange={handleChange('state')}
                      value={localState.state}
                      className='form-control custom-select'
                    >
                      {statesMap.map((option) => (
                        <option key={option.key} value={option.value.toString()}>
                          {option.text}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className='form-group mt-4'>
                    <label className='form-label'>LGA</label>
                    <select
                      onChange={handleChange('city')}
                      value={localState.city}
                      className='form-control custom-select'
                    >
                      <option value=''></option>
                      {lgas.map((option) => (
                        <option key={option.key} value={option.value.toString()}>
                          {option.text}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className='d-grid mt-5'>
                    <button className='btn  btn-primary' type='submit' onClick={saveAccount}>
                      Create Account
                      {isLoading ? <ButtonLoader /> : ''}
                    </button>
                  </div>
                </div>
              </fieldset>
            </form>
          </div>
        )}
      </div>
    </>
  );
};

export default AddWellaPayUserScreen;
