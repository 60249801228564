import { FormEvent, useState } from 'react';
import { INewSuperAgentDataDTO, defaultSuperAgent } from '../../../interfaces/AgentInterface';
import { isFormValidated } from '../../../utils/formUtils';
import { endpoints } from '../../../utils/URL';
import axios from 'axios';
import { addToast } from '../../../utils/toastNotifications';
import ButtonLoader from '../../common/ButtonLoader';
import { STATES_LGA } from '../../../utils/states_lga';
import { BankList } from '../../../utils/mappings';

const SuperAgentRegistration = () => {
  const [agentData, setAgentData] = useState<INewSuperAgentDataDTO>(defaultSuperAgent);
  const [isLoading, setLoading] = useState(false);

  const handleChange = (input: string) => (event: any) => {
    setAgentData({
      ...agentData,
      [input]: event.target.value,
    });
  };

  const saveAgent = async (event: FormEvent) => {
    if (!isFormValidated('super-agent-registration')) {
      return;
    }

    event.preventDefault();
    if (
      agentData.email === agentData.guarantor1 ||
      agentData.email === agentData.guarantor2 ||
      agentData.guarantor1 === agentData.guarantor2
    ) {
      addToast('Please use unique emails', 'warning');
      return;
    }
    event.preventDefault();

    setLoading(true);
    const url = endpoints.Agents.mainUrl;

    try {
      await axios.post(url, agentData);
      addToast('Registration successful. Login to continue', 'success');
      setAgentData(defaultSuperAgent);
      //redirect to success page
      window.location.href = '/auth/register/success';
    } catch (error) {
      addToast('An error occurred');
    } finally {
      setLoading(false);
    }
  };

  const statesType = STATES_LGA;
  const statesMap = statesType.map((states) => {
    return {
      key: states.id,
      text: states.state,
      value: states.state,
    };
  });

  const state_filter = STATES_LGA.filter((selected_state) => {
    return selected_state.state === agentData.stateOfResidence;
  });

  let lgas = state_filter[0].locals.map((lga) => {
    return {
      key: lga.id,
      text: lga.name,
      value: lga.name,
    };
  });

  return (
    <div>
      <div className='container' style={{ marginTop: '50px', marginBottom: '50px' }}>
        <div className='text-center mb-5'>
          <a href='/dashboard'>
            <img
              src={require('../../../assets/images/wellahealth-plain.svg').default}
              alt='wellahealth'
              width='150'
            />
          </a>
        </div>
        <div className='row d-flex justify-content-center align-items-center'>
          <div className='col-lg- 4 col-md-6 col-sm-8 card p-3'>
            <h4 className='text-center mb-4'>WellaHealth Super Agent Registration</h4>

            <form id='super-agent-registration'>
              <fieldset disabled={isLoading}>
                <div className='form-group mb-3'>
                  <label className='form-label'>First Name</label>
                  <input
                    type='text'
                    className='form-control'
                    value={agentData.firstName}
                    onChange={handleChange('firstName')}
                    required
                  />
                </div>
                <div className='form-group mb-3'>
                  <label className='form-label'>Last Name</label>
                  <input
                    type='text'
                    className='form-control'
                    value={agentData.lastName}
                    onChange={handleChange('lastName')}
                    required
                  />
                </div>

                <div className='form-group mb-3'>
                  <label className='form-label'>Phone Number</label>
                  <input
                    type='tel'
                    className='form-control'
                    value={agentData.phoneNumber}
                    onChange={handleChange('phoneNumber')}
                    maxLength={13}
                    pattern='[0]{1}[7-9]{1}[0-2]{1}[0-9]{8}|[2]{1}[3]{1}[4]{1}[0-9]{10}'
                    required
                  />
                </div>
                <div className='form-group mb-3'>
                  <label className='form-label'>Email address</label>
                  <input
                    type='email'
                    className='form-control'
                    value={agentData.email}
                    onChange={handleChange('email')}
                    required
                  />
                </div>

                <div className='form-group mb-3'>
                  <label className='form-label'>State Of Residence</label>
                  <select
                    required
                    onChange={handleChange('stateOfResidence')}
                    value={agentData.stateOfResidence}
                    className='custom-select form-control'
                  >
                    {statesMap.map((option) => (
                      <option key={option.key} value={option.value.toString()}>
                        {option.text}
                      </option>
                    ))}
                  </select>
                </div>

                <div className='form-group mb-3'>
                  <label className='form-label'>LGA</label>
                  <select
                    onChange={handleChange('lga')}
                    value={agentData.lga}
                    className='custom-select form-control'
                    required
                  >
                    <option value=''></option>
                    {lgas.map((option) => (
                      <option key={option.key} value={option.value.toString()}>
                        {option.text}
                      </option>
                    ))}
                  </select>
                </div>

                <div className='form-group mb-3'>
                  <label className='form-label'>Street Name and Address</label>
                  <input
                    type='text'
                    className='form-control'
                    value={agentData.streetName}
                    onChange={handleChange('streetName')}
                    required
                  />
                </div>
                <div className='form-group mb-3'>
                  <label className='form-label'>Bank Account Number</label>
                  <input
                    type='text'
                    className='form-control'
                    value={agentData.bankAccountNumber}
                    onChange={handleChange('bankAccountNumber')}
                    required
                    maxLength={10}
                    minLength={10}
                    pattern='[0-9]{10}'
                  />
                </div>
                <div className='form-group mb-3'>
                  <label className='form-label'>Bank Name</label>
                  <select
                    onChange={handleChange('bankName')}
                    value={agentData.bankName}
                    className='custom-select form-control'
                    required
                  >
                    {BankList.map((option) => (
                      <option key={option.label} value={option.value.toString()}>
                        {option.value}
                      </option>
                    ))}
                  </select>
                </div>

                <div className='form-group mb-3'>
                  <label className='form-label'>Bank Account Name</label>
                  <input
                    type='text'
                    className='form-control'
                    value={agentData.bankAccountName}
                    onChange={handleChange('bankAccountName')}
                    required
                  />
                </div>

                <div className='form-group mb-3'>
                  <label className='form-label'>NIN</label>
                  <input
                    type='text'
                    className='form-control'
                    value={agentData.bvn}
                    onChange={handleChange('bvn')}
                    required
                    maxLength={11}
                    minLength={11}
                    pattern='[0-9]{11}'
                  />
                </div>

                <div className='form-group mb-3'>
                  <label className='form-label'>Guarantor Email 1</label>
                  <input
                    type='email'
                    className='form-control'
                    value={agentData.guarantor1}
                    onChange={handleChange('guarantor1')}
                    required
                  />
                </div>

                <div className='form-group mb-3'>
                  <label className='form-label'>Guarantor Email 2</label>
                  <input
                    type='email'
                    className='form-control'
                    value={agentData.guarantor2}
                    onChange={handleChange('guarantor2')}
                    required
                  />
                </div>

                <button
                  type='submit'
                  onClick={saveAgent}
                  className='mt-3 btn btn-primary form-control'
                >
                  Submit {isLoading ? <ButtonLoader /> : ''}
                </button>
              </fieldset>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuperAgentRegistration;
