export const insurancePlans = [
  {
    insurancePlanId: 1,
    planName: 'Monthly',
    planType: 'Monthly',
    price: 600,
    numberOfPersons: 1,
    numberOfMonths: 1,
    product: 'Malaria',
  },
  {
    insurancePlanId: 2,
    planName: 'Monthly for 2 persons',
    planType: 'Monthly',
    price: 1200,
    numberOfPersons: 2,
    numberOfMonths: 1,
    product: 'Malaria',
  },
  {
    insurancePlanId: 3,
    planName: 'Monthly for 3 persons',
    planType: 'Monthly',
    price: 1800,
    numberOfPersons: 3,
    numberOfMonths: 1,
    product: 'Malaria',
  },
  {
    insurancePlanId: 4,
    planName: 'Monthly for 4 persons',
    planType: 'Monthly',
    price: 2400,
    numberOfPersons: 4,
    numberOfMonths: 1,
    product: 'Malaria',
  },
  {
    insurancePlanId: 5,
    planName: 'Monthly for 5 persons',
    planType: 'Monthly',
    price: 3000,
    numberOfPersons: 5,
    numberOfMonths: 1,
    product: 'Malaria',
  },
  {
    insurancePlanId: 6,
    planName: 'Quarterly',
    planType: 'Quarterly',
    price: 1700,
    numberOfPersons: 1,
    numberOfMonths: 3,
    product: 'Malaria',
  },
  {
    insurancePlanId: 7,
    planName: 'Quarterly for 2 persons',
    planType: 'Quarterly',
    price: 3300,
    numberOfPersons: 2,
    numberOfMonths: 3,
    product: 'Malaria',
  },
  {
    insurancePlanId: 8,
    planName: 'Quarterly for 3 persons',
    planType: 'Quarterly',
    price: 4900,
    numberOfPersons: 3,
    numberOfMonths: 3,
    product: 'Malaria',
  },
  {
    insurancePlanId: 9,
    planName: 'Quarterly for 4 persons',
    planType: 'Quarterly',
    price: 6600,
    numberOfPersons: 4,
    numberOfMonths: 3,
    product: 'Malaria',
  },
  {
    insurancePlanId: 10,
    planName: 'Quarterly for 5 persons',
    planType: 'Quarterly',
    price: 8100,
    numberOfPersons: 5,
    numberOfMonths: 3,
    product: 'Malaria',
  },
  {
    insurancePlanId: 11,
    planName: 'Biannually',
    planType: 'Biannually',
    price: 3400,
    numberOfPersons: 1,
    numberOfMonths: 6,
    product: 'Malaria',
  },
  {
    insurancePlanId: 12,
    planName: 'Biannually for 2 persons',
    planType: 'Biannually',
    price: 6800,
    numberOfPersons: 2,
    numberOfMonths: 6,
    product: 'Malaria',
  },
  {
    insurancePlanId: 13,
    planName: 'Biannually for 3 persons',
    planType: 'Biannually',
    price: 10000,
    numberOfPersons: 3,
    numberOfMonths: 6,
    product: 'Malaria',
  },
  {
    insurancePlanId: 14,
    planName: 'Biannually for 4 persons',
    planType: 'Biannually',
    price: 13200,
    numberOfPersons: 4,
    numberOfMonths: 6,
    product: 'Malaria',
  },
  {
    insurancePlanId: 15,
    planName: 'Biannually for 5 persons',
    planType: 'Biannually',
    price: 16800,
    numberOfPersons: 5,
    numberOfMonths: 6,
    product: 'Malaria',
  },
  {
    insurancePlanId: 16,
    planName: 'Yearly',
    planType: 'Yearly',
    price: 6800,
    numberOfPersons: 1,
    numberOfMonths: 12,
    product: 'Malaria',
  },
  {
    insurancePlanId: 17,
    planName: 'Yearly for 2 persons',
    planType: 'Yearly',
    price: 13600,
    numberOfPersons: 2,
    numberOfMonths: 12,
    product: 'Malaria',
  },
  {
    insurancePlanId: 18,
    planName: 'Yearly for 3 persons',
    planType: 'Yearly',
    price: 20000,
    numberOfPersons: 3,
    numberOfMonths: 12,
    product: 'Malaria',
  },
  {
    insurancePlanId: 19,
    planName: 'Yearly for 4 persons',
    planType: 'Yearly',
    price: 26400,
    numberOfPersons: 4,
    numberOfMonths: 12,
    product: 'Malaria',
  },
  {
    insurancePlanId: 20,
    planName: 'Yearly for 5 persons',
    planType: 'Yearly',
    price: 33600,
    numberOfPersons: 5,
    numberOfMonths: 12,
    product: 'Malaria',
  },
  {
    insurancePlanId: 21,
    planName: 'Monthly',
    planType: 'Monthly',
    price: 800,
    numberOfPersons: 1,
    numberOfMonths: 1,
    product: 'Cashplus',
  },
  {
    insurancePlanId: 22,
    planName: 'Monthly for 2 persons',
    planType: 'Monthly',
    price: 1600,
    numberOfPersons: 2,
    numberOfMonths: 1,
    product: 'Cashplus',
  },
  {
    insurancePlanId: 23,
    planName: 'Monthly for 3 persons',
    planType: 'Monthly',
    price: 2400,
    numberOfPersons: 3,
    numberOfMonths: 1,
    product: 'Cashplus',
  },
  {
    insurancePlanId: 24,
    planName: 'Monthly for 4 persons',
    planType: 'Monthly',
    price: 3200,
    numberOfPersons: 4,
    numberOfMonths: 1,
    product: 'Cashplus',
  },
  {
    insurancePlanId: 25,
    planName: 'Monthly for 5 persons',
    planType: 'Monthly',
    price: 4000,
    numberOfPersons: 5,
    numberOfMonths: 1,
    product: 'Cashplus',
  },
  {
    insurancePlanId: 26,
    planName: 'Quarterly',
    planType: 'Quarterly',
    price: 2300,
    numberOfPersons: 1,
    numberOfMonths: 3,
    product: 'Cashplus',
  },
  {
    insurancePlanId: 27,
    planName: 'Quarterly for 2 persons',
    planType: 'Quarterly',
    price: 4300,
    numberOfPersons: 2,
    numberOfMonths: 3,
    product: 'Cashplus',
  },
  {
    insurancePlanId: 28,
    planName: 'Quarterly for 3 persons',
    planType: 'Quarterly',
    price: 6200,
    numberOfPersons: 3,
    numberOfMonths: 3,
    product: 'Cashplus',
  },
  {
    insurancePlanId: 29,
    planName: 'Quarterly for 4 persons',
    planType: 'Quarterly',
    price: 8100,
    numberOfPersons: 4,
    numberOfMonths: 3,
    product: 'Cashplus',
  },
  {
    insurancePlanId: 30,
    planName: 'Quarterly for 5 persons',
    planType: 'Quarterly',
    price: 10100,
    numberOfPersons: 5,
    numberOfMonths: 3,
    product: 'Cashplus',
  },
  {
    insurancePlanId: 31,
    planName: 'Biannually',
    planType: 'Biannually',
    price: 4600,
    numberOfPersons: 1,
    numberOfMonths: 6,
    product: 'Cashplus',
  },
  {
    insurancePlanId: 32,
    planName: 'Biannually for 2 persons',
    planType: 'Biannually',
    price: 8600,
    numberOfPersons: 2,
    numberOfMonths: 6,
    product: 'Cashplus',
  },
  {
    insurancePlanId: 33,
    planName: 'Biannually for 3 persons',
    planType: 'Biannually',
    price: 12400,
    numberOfPersons: 3,
    numberOfMonths: 6,
    product: 'Cashplus',
  },
  {
    insurancePlanId: 34,
    planName: 'Biannually for 4 persons',
    planType: 'Biannually',
    price: 16200,
    numberOfPersons: 4,
    numberOfMonths: 6,
    product: 'Cashplus',
  },
  {
    insurancePlanId: 35,
    planName: 'Biannually for 5 persons',
    planType: 'Biannually',
    price: 20200,
    numberOfPersons: 5,
    numberOfMonths: 6,
    product: 'Cashplus',
  },
  {
    insurancePlanId: 36,
    planName: 'Yearly',
    planType: 'Yearly',
    price: 9200,
    numberOfPersons: 1,
    numberOfMonths: 12,
    product: 'Cashplus',
  },
  {
    insurancePlanId: 37,
    planName: 'Yearly for 2 persons',
    planType: 'Yearly',
    price: 17200,
    numberOfPersons: 2,
    numberOfMonths: 12,
    product: 'Cashplus',
  },
  {
    insurancePlanId: 38,
    planName: 'Yearly for 3 persons',
    planType: 'Yearly',
    price: 22800,
    numberOfPersons: 3,
    numberOfMonths: 12,
    product: 'Cashplus',
  },
  {
    insurancePlanId: 39,
    planName: 'Yearly for 4 persons',
    planType: 'Yearly',
    price: 32400,
    numberOfPersons: 4,
    numberOfMonths: 12,
    product: 'Cashplus',
  },
  {
    insurancePlanId: 40,
    planName: 'Yearly for 5 persons',
    planType: 'Yearly',
    price: 40400,
    numberOfPersons: 5,
    numberOfMonths: 12,
    product: 'Cashplus',
  },
  {
    insurancePlanId: 41,
    planName: 'Monthly for 1 person',
    planType: 'Monthly',
    price: 500,
    numberOfPersons: 1,
    numberOfMonths: 1,
    product: 'Diabetes',
  },
  {
    insurancePlanId: 42,
    planName: 'Monthly for 1 person',
    planType: 'Monthly',
    price: 700,
    numberOfPersons: 1,
    numberOfMonths: 1,
    product: 'DiabetesPlus',
  },
  {
    insurancePlanId: 48,
    planName: 'Compact Monthly',
    planType: 'Monthly',
    product: 'Compact',
    numberOfPersons: 1,
    numberOfMonths: 1,
    price: 2000.0,
  },
  {
    insurancePlanId: 49,
    planName: 'Compact Monthly for 2 persons',
    planType: 'Monthly',
    product: 'Compact',
    numberOfPersons: 2,
    numberOfMonths: 1,
    price: 3700.0,
  },
  {
    insurancePlanId: 50,
    planName: 'Compact Monthly for 3 persons',
    planType: 'Monthly',
    product: 'Compact',
    numberOfPersons: 3,
    numberOfMonths: 1,
    price: 5000.0,
  },
  {
    insurancePlanId: 51,
    planName: 'Compact Monthly for 4 persons',
    planType: 'Monthly',
    product: 'Compact',
    numberOfPersons: 4,
    numberOfMonths: 1,
    price: 7400.0,
  },
  {
    insurancePlanId: 52,
    planName: 'Compact Monthly for 5 persons',
    planType: 'Monthly',
    product: 'Compact',
    numberOfPersons: 5,
    numberOfMonths: 1,
    price: 9000.0,
  },
  {
    insurancePlanId: 53,
    planName: 'Compact Quarterly',
    planType: 'Quarterly',
    product: 'Compact',
    numberOfPersons: 1,
    numberOfMonths: 3,
    price: 5500.0,
  },
  {
    insurancePlanId: 54,
    planName: 'Compact Quarterly for 2 persons',
    planType: 'Quarterly',
    product: 'Compact',
    numberOfPersons: 2,
    numberOfMonths: 3,
    price: 11000.0,
  },
  {
    insurancePlanId: 55,
    planName: 'Compact Quarterly for 3 persons',
    planType: 'Quarterly',
    product: 'Compact',
    numberOfPersons: 3,
    numberOfMonths: 3,
    price: 16500.0,
  },
  {
    insurancePlanId: 56,
    planName: 'Compact Quarterly for 4 persons',
    planType: 'Quarterly',
    product: 'Compact',
    numberOfPersons: 4,
    numberOfMonths: 3,
    price: 22000.0,
  },
  {
    insurancePlanId: 57,
    planName: 'Compact Quarterly for 5 persons',
    planType: 'Quarterly',
    product: 'Compact',
    numberOfPersons: 5,
    numberOfMonths: 3,
    price: 27500.0,
  },
  {
    insurancePlanId: 58,
    planName: 'Compact Biannually',
    planType: 'Biannually',
    product: 'Compact',
    numberOfPersons: 1,
    numberOfMonths: 6,
    price: 11000.0,
  },
  {
    insurancePlanId: 59,
    planName: 'Compact Biannually for 2 persons',
    planType: 'Biannually',
    product: 'Compact',
    numberOfPersons: 2,
    numberOfMonths: 6,
    price: 22000.0,
  },
  {
    insurancePlanId: 60,
    planName: 'Compact Biannually for 3 persons',
    planType: 'Biannually',
    product: 'Compact',
    numberOfPersons: 3,
    numberOfMonths: 6,
    price: 33000.0,
  },
  {
    insurancePlanId: 61,
    planName: 'Compact Biannually for 4 persons',
    planType: 'Biannually',
    product: 'Compact',
    numberOfPersons: 4,
    numberOfMonths: 6,
    price: 44000.0,
  },
  {
    insurancePlanId: 62,
    planName: 'Compact Biannually for 5 persons',
    planType: 'Biannually',
    product: 'Compact',
    numberOfPersons: 5,
    numberOfMonths: 6,
    price: 55000.0,
  },
  {
    insurancePlanId: 63,
    planName: 'Compact Yearly',
    planType: 'Yearly',
    product: 'Compact',
    numberOfPersons: 1,
    numberOfMonths: 12,
    price: 21000.0,
  },
  {
    insurancePlanId: 64,
    planName: 'Compact Yearly for 2 persons',
    planType: 'Yearly',
    product: 'Compact',
    numberOfPersons: 2,
    numberOfMonths: 12,
    price: 42000.0,
  },
  {
    insurancePlanId: 65,
    planName: 'Compact Yearly for 3 persons',
    planType: 'Yearly',
    product: 'Compact',
    numberOfPersons: 3,
    numberOfMonths: 12,
    price: 63000.0,
  },
  {
    insurancePlanId: 66,
    planName: 'Compact Yearly for 4 persons',
    planType: 'Yearly',
    product: 'Compact',
    numberOfPersons: 4,
    numberOfMonths: 12,
    price: 84000.0,
  },
  {
    insurancePlanId: 67,
    planName: 'Compact Yearly for 5 persons',
    planType: 'Yearly',
    product: 'Compact',
    numberOfPersons: 5,
    numberOfMonths: 12,
    price: 105000.0,
  },
  {
    insurancePlanId: 68,
    planName: 'Pharma Biannually',
    planType: 'Biannually',
    product: 'Pharma',
    numberOfPersons: 1,
    numberOfMonths: 6,
    price: 2500.0,
  },
  {
    insurancePlanId: 69,
    planName: 'Pharma Biannually for 2 persons',
    planType: 'Biannually',
    product: 'Pharma',
    numberOfPersons: 2,
    numberOfMonths: 6,
    price: 4700.0,
  },
  {
    insurancePlanId: 70,
    planName: 'Pharma Biannually for 3 persons',
    planType: 'Biannually',
    product: 'Pharma',
    numberOfPersons: 3,
    numberOfMonths: 6,
    price: 7400.0,
  },
  {
    insurancePlanId: 71,
    planName: 'Pharma Biannually for 4 persons',
    planType: 'Biannually',
    product: 'Pharma',
    numberOfPersons: 4,
    numberOfMonths: 6,
    price: 9500.0,
  },
  {
    insurancePlanId: 72,
    planName: 'Pharma Biannually for 5 persons',
    planType: 'Biannually',
    product: 'Pharma',
    numberOfPersons: 5,
    numberOfMonths: 6,
    price: 12300.0,
  },
  {
    insurancePlanId: 73,
    planName: 'Pharma Yearly',
    planType: 'Yearly',
    product: 'Pharma',
    numberOfPersons: 1,
    numberOfMonths: 12,
    price: 5000.0,
  },
  {
    insurancePlanId: 74,
    planName: 'Pharma Yearly for 2 persons',
    planType: 'Yearly',
    product: 'Pharma',
    numberOfPersons: 2,
    numberOfMonths: 12,
    price: 9400.0,
  },
  {
    insurancePlanId: 75,
    planName: 'Pharma Yearly for 3 persons',
    planType: 'Yearly',
    product: 'Pharma',
    numberOfPersons: 3,
    numberOfMonths: 12,
    price: 14800.0,
  },
  {
    insurancePlanId: 76,
    planName: 'Pharma Yearly for 4 persons',
    planType: 'Yearly',
    product: 'Pharma',
    numberOfPersons: 4,
    numberOfMonths: 12,
    price: 19000.0,
  },
  {
    insurancePlanId: 77,
    planName: 'Pharma Yearly for 5 persons',
    planType: 'Yearly',
    product: 'Pharma',
    numberOfPersons: 5,
    numberOfMonths: 12,
    price: 24600.0,
  },
];
