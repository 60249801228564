export interface IWellaPayUser {
  firstName: string;
  lastName: string;
  middleName: string;
  phoneNumber: string;
  email: string;
  customerType: string;
  addressLine: string;
  city: string;
  state: string;
  dateOfBirth: string;
  gender: string;
  country: string;
  identificationType: string;
  identificationNumber: string;
}

export interface IWellaPayUserDTO {
  firstName: string;
  lastName: string;
  middleName: string;
  phoneNumber: string;
  email: string;
  accountNumber: string;
}

export interface IWellaPayReward {
  rewardId: number;
  accountNumber: string;
  totalCashback: number;
  totalLoyaltyPoints: number;
  phoneNumber: string;
  email: string;
  dateCreated: string;
}

export interface IRewardModalProps {
  open: boolean;
  close: () => void;
  modalData: IWellaPayUserDTO;
  callback?: Function;
}

export interface IRedeemRewardModalProps {
  open: boolean;
  close: () => void;
  modalData: IWellaPayReward;
  callback?: Function;
}

export interface IWellaPayRewardDTO {
  rewardPoints: number;
  rewardType: string;
  accountNumber: string;
  phoneNumber: string;
  email: string;
  dateCreated: string;
  fileUrl: string;
  agentName: string;
  agentCode: string;
}

export interface IWellaPayBvnDetails {
  bvn: '';
  firstName: '';
  lastName: '';
  middleName: '';
  gender: '';
  dateOfBirth: '';
  phoneNumber1: '';
  phoneNumber2: '';
  email: '';
  enrollementBank?: null;
  enrollementBranch?: null;
  levelOfAccount: '';
  lgaOfOrigin: '';
  lgaOfResidence: '';
  maritalStatus: '';
  nameOnCard: '';
  nationality: '';
  nin: '';
  registrationDate: '';
  residentialAddress: '';
  stateOfOrigin: '';
  stateOfResidence: '';
  title: '';
  watchlisted?: null;
}

export const defaultWellaPayUser: IWellaPayUser = {
  firstName: '',
  lastName: '',
  middleName: '',
  phoneNumber: '',
  email: '',
  customerType: 'Individual',
  addressLine: '',
  city: '',
  state: '',
  country: 'Nigeria',
  dateOfBirth: '',
  gender: 'Others',
  identificationNumber: '',
  identificationType: 'BVN',
};

export const defaultWellaPayUserDTO: IWellaPayUserDTO = {
  firstName: '',
  lastName: '',
  middleName: '',
  phoneNumber: '',
  email: '',
  accountNumber: '',
};

export const defaultWellaPayReward: IWellaPayReward = {
  rewardId: 0,
  accountNumber: '',
  totalCashback: 0,
  totalLoyaltyPoints: 0,
  phoneNumber: '',
  email: '',
  dateCreated: '',
};
