import React from 'react';
import ErrorBoundary from './utils/ErrorBoundary';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { ToastProvider, DefaultToastContainer } from 'react-toast-notifications';
import { AppProvider } from './context/AppContext';

// stylesheet imports
import 'bootstrap/dist/css/bootstrap.min.css';
import './components/style.css';

// component imports
import Layout from './components/layout/Layout';
import Home from './components/pages/Home';
import SubAgentsPage from './components/pages/agents/SubAgents';
import AddSubscriptionForm from './components/pages/add_subscription/AddSubscriptionForm';
import Subscriptions from './components/pages/subscriptions/Subscriptions';
import LandingPage from './components/pages/LandingPage';
import AgentRegistration from './components/pages/auth/AgentRegistration';
import Callback from './components/pages/auth/Callback';
import RefreshToast from './components/common/RefreshToast';
import WalletDashboard from './components/pages/wallets/WalletDashboard';
import WalletWithdrawal from './components/pages/wallets/WalletWithdrawal';
import SuperAgentRegistration from './components/pages/auth/SuperAgentRegistration';
import SignupSuccess from './components/pages/auth/SignupSuccess';
import Profile from './components/pages/profile/Profile';
import AddWellaPayUSerScreen from './components/pages/wellapay/AddWellaPayUserScreen';
import ViewWellaPayUsers from './components/pages/wellapay/ViewWellaPayUsers';
import SubscriptionLandingPage from './components/pages/subscriptions/SubscriptionLandingPage';
import SubscriptionDetail from './components/pages/subscriptions/SubscriptionDetail';
import { AuthProvider } from 'react-oidc-context';
import RegistrationLanding from './components/pages/auth/RegistrationLanding';
import VoucherCode from './components/pages/subscriptions/VoucherCodes';
import EsusuCustomers from './components/pages/esusu/EsusuCustomers';
import EsusuPaymentHistory from './components/pages/esusu/EsusuPaymentHistory';
import EsusuRegistration from './components/pages/esusu/EsusuRegistration';
import EsusuLandingPage from './components/pages/esusu/EsusuLandingPage';
import ScrollToTop from './components/common/ScrollToTop';

const oidcConfig = {
  authority: process.env.REACT_APP_OIDC_AUTHORITY as string,
  client_id: process.env.REACT_APP_OIDC_CLIENTID as string,
  redirect_uri: process.env.REACT_APP_OIDC_REDIRECT_URL as string,
  response_type: 'code',
  scope: 'openid profile email roles',
  post_logout_redirect_uri: process.env.REACT_APP_OIDC_POSTLOGOUT_URL as string,
  filterProtocolClaims: true,
  //userStore: new WebStorageStateStore({ store: window.localStorage }),
  automaticSilentRenew: true,
  silent_redirect_uri: process.env.REACT_APP_OIDC_RENEWAL_URL as string,
  revokeAccessTokenOnSignout: true,
};

declare global {
  interface Window {
    __react_toast_provider: any;
  }
}

window.__react_toast_provider = React.createRef();
// create a default container so we can override the styles
const ToastContainer = (props: any) => (
  <DefaultToastContainer style={{ zIndex: '1900' }} {...props} />
);

const App = () => {
  return (
    <AuthProvider {...oidcConfig}>
      <ToastProvider
        components={{ ToastContainer }}
        placement='top-right'
        ref={window.__react_toast_provider} //ref is a custom prop added in toast-notifications.d.ts
      >
        <AppProvider>
          <ErrorBoundary>
            <BrowserRouter>
              <Switch>
                <Layout exact path='/dashboard' component={Home} />
                <Route exact path='/' component={LandingPage} />
                <Route exact path='/signin-callback' component={Callback} />
                <Route exact path='/auth/register/landing' component={RegistrationLanding} />
                <Route exact path='/auth/register' component={AgentRegistration} />
                <Route exact path='/auth/register/superagent' component={SuperAgentRegistration} />
                <Route exact path='/auth/register/success' component={SignupSuccess} />
                <Layout exact path='/agents' component={SubAgentsPage} />
                <Layout exact path='/subscriptions' component={SubscriptionLandingPage} />
                <Layout exact path='/subscriptions/add' component={AddSubscriptionForm} />
                <Layout exact path='/subscriptions/view' component={Subscriptions} />{' '}
                <Layout exact path='/subscriptions/details' component={SubscriptionDetail} />
                <Layout exact path='/subscriptions/view/:agentCode' component={Subscriptions} />
                <Layout exact path='/wallet/' component={WalletDashboard} />
                <Layout exact path='/wallet/withdrawal' component={WalletWithdrawal} />
                <Layout exact path='/profile' component={Profile} />
                <Layout exact path='/wellapay/users' component={ViewWellaPayUsers} />
                <Layout exact path='/wellapay/users/add' component={AddWellaPayUSerScreen} />
                <Layout exact path='/subscriptions/vouchers' component={VoucherCode} />
                <Layout exact path='/esusu' component={EsusuLandingPage} />
                <Layout exact path='/esusu/customers' component={EsusuCustomers} />
                <Layout exact path='/esusu/:customerId/payments' component={EsusuPaymentHistory} />
                <Layout exact path='/esusu/customers/register' component={EsusuRegistration} />
              </Switch>
              <RefreshToast />
              <ScrollToTop />
            </BrowserRouter>
          </ErrorBoundary>
        </AppProvider>
      </ToastProvider>
    </AuthProvider>
  );
};

export default App;
