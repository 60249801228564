import { useState, useEffect, useCallback } from 'react';
import { IEsusuPayment } from '../../../interfaces/EsusuInterface';
import TableLoader from '../../common/TableLoader';
import { endpoints } from '../../../utils/URL';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { addToast } from '../../../utils/toastNotifications';
import { formatDisplayDate } from '../../../utils/dateTimeUtils';

const EsusuPaymentHistory = () => {
  const { customerId } = useParams() as any;

  const [paymentDetails, setPaymentDetails] = useState<IEsusuPayment[]>([]);
  const [loading, setLoading] = useState(false);

  const getPaymentDetails = useCallback(async () => {
    setLoading(true);

    try {
      const response = await axios.get(
        `${endpoints.Esusu.Payments.mainUrl}/customer/${customerId}`
      );
      if (response.status === 200) {
        setPaymentDetails(response.data);
      }
    } catch (error) {
      addToast('Error getting payment details', 'error');
    } finally {
      setLoading(false);
    }
  }, [customerId]);

  useEffect(() => {
    getPaymentDetails();
  }, [getPaymentDetails]);

  let noDataView = (
    <div className='text-center mt-5'>
      <img src={require('../../../assets/icons/empty-box.svg').default} alt='' />
      <div className='mt-3'>No Payments found</div>
    </div>
  );

  let paymentCards: JSX.Element = (
    <div className=''>
      {loading ? (
        <TableLoader />
      ) : paymentDetails.length === 0 ? (
        <div> {noDataView} </div>
      ) : (
        <div className='row'>
          {paymentDetails.map((paymentData: IEsusuPayment) => {
            return (
              <div className='col-md-4 mt-4'>
                <div className='card borderless rounded'>
                  <div className='card-body'>
                    <div className='row card-text subscription-card-text mt-4'>
                      <div className='col-6 fw-medium'>Payment Date</div>
                      <div className='col-6 text-end fw-light fs-7'>
                        {formatDisplayDate(paymentData.paymentDate)}
                      </div>
                    </div>
                    <div className='row card-text subscription-card-text mt-4'>
                      <div className='col-6 fw-medium'>Actual Payment Date</div>
                      <div className='col-6 text-end fw-light  fs-7'>
                        {formatDisplayDate(paymentData.paymentActualDate)}
                      </div>
                    </div>
                    <div className='row card-text subscription-card-text mt-4'>
                      <div className='col-6 fw-medium'>Amount Paid</div>
                      <div className='col-6 text-end fw-light'>₦ {paymentData.amountPaid} </div>
                    </div>
                    <div className='row card-text subscription-card-text mt-4'>
                      <div className='col-6 fw-medium'>Number of People</div>
                      <div className='col-6 text-end fw-light'> {paymentData.numberOfPersons} </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );

  return (
    <div>
      <div className='container'>
        <h3 className='mr-auto text-center'>Payment History</h3>
        <br />
      </div>
      <div className='container'>
        {paymentCards}
        <br />
      </div>
    </div>
  );
};

export default EsusuPaymentHistory;
