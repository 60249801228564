import React, { useContext, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { isFormValidated } from '../../../utils/formUtils';
import { addToast } from '../../../utils/toastNotifications';
import { IRewardModalProps, IWellaPayRewardDTO } from '../../../interfaces/WellaPayUser';
import axios from 'axios';
import { endpoints } from '../../../utils/URL';
import ButtonLoader from '../../common/ButtonLoader';
import { validImageFileType } from '../../../utils/fileHelper';
import { AppContext } from '../../../context/AppContext';

const NewRewardModal = (props: IRewardModalProps) => {
  const [rewardDTO, setRewardDTO] = useState({
    amount: 0,
    percent: 1,
    fileUrl: '',
  });
  const [btnLoading, setBtnLoading] = useState(false);
  const { appState } = useContext(AppContext);

  const handleChange = (input: string) => (event: any) => {
    setRewardDTO({
      ...rewardDTO,
      [input]: event.target.value,
    });
  };
  const [isUploading, setIsUploading] = useState(true);

  const resetProgress = () => {
    let progressBar = document.getElementById('progress-bar');
    progressBar!.style.width = '0%';
    progressBar!.setAttribute('aria-now', '0');
  };

  const uploadProofOfPayment = async (event: any) => {
    const confirm = window.confirm('Do you want to upload this file?');

    if (!confirm) {
      return;
    }

    let fileUpload: any = document.getElementById('upload-file');
    let fileId: string = `${props.modalData.phoneNumber}`;

    let uploadedFiles = fileUpload.files;

    if (uploadedFiles.length === 0) {
      addToast('No files selected. Please select a jpg, png or jpeg file', 'warning');
      return;
    }
    if (!validImageFileType(uploadedFiles[0])) {
      addToast('Wrong file. Please select a jpg, png or jpeg file', 'warning');
      return;
    }

    if (uploadedFiles[0].size > 5242880) {
      //if exceeding 2mb
      addToast('Exceed file limit. Max limit is 5MB', 'warning');
      return;
    }

    event.preventDefault();

    const formData = new FormData();
    formData.append('formFile', uploadedFiles[0]);
    formData.append('fileId', fileId);

    let progressBar = document.getElementById('progress-bar');
    resetProgress();
    let config = {
      onUploadProgress: (progressEvent: any) => {
        let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        progressBar!.style.width = `${percentCompleted}%`;
        progressBar?.setAttribute('aria-now', percentCompleted.toString());
        console.log(percentCompleted);
      },
    };

    setIsUploading(true);

    let url = `${endpoints.Tasks.mainUrl}/upload`;

    try {
      // setIsUploading(true);
      const response = await axios.post(url, formData, config);
      setRewardDTO({
        ...rewardDTO,
        fileUrl: response.data,
      });
      addToast('File uploaded successfully', 'success');
    } catch (error) {
      addToast('There was an error uploading the file. Please check the uploaded file', 'error');
      console.error(error);
    } finally {
      //set loading
      resetProgress();
      setIsUploading(false);
    }
  };

  const sendReward = async (event: any) => {
    if (!isFormValidated('add-reward-modal')) {
      return;
    }

    if (rewardDTO.fileUrl === '') {
      addToast('Please upload an image or wait for upload to complete', 'error');
      return;
    }

    event.preventDefault();

    const reward: IWellaPayRewardDTO = {
      accountNumber: props.modalData.accountNumber,
      phoneNumber: props.modalData.phoneNumber,
      email: props.modalData.email,
      rewardType: 'Cashback',
      rewardPoints: (rewardDTO.amount * rewardDTO.percent) / 100,
      dateCreated: new Date().toISOString(),
      fileUrl: rewardDTO.fileUrl,
      agentCode: appState.agentCode,
      agentName: `${appState.firstName} ${appState.lastName}`,
    };

    const url = `${endpoints.Rewards.mainUrl}`;
    setBtnLoading(true);
    try {
      await axios.post(url, reward);
      addToast('Reward added for user', 'success');
      if (props.callback) {
        props.callback(props.modalData.phoneNumber);
      }
      props.close();
    } catch (error) {
      addToast('An error occured', 'error');
    } finally {
      setBtnLoading(false);
    }
  };

  return (
    <Modal show={props.open} onHide={props.close}>
      <form id='add-reward-modal'>
        <fieldset disabled={btnLoading}>
          <Modal.Header closeButton>
            <Modal.Title>
              Give Reward of {((rewardDTO.amount * rewardDTO.percent) / 100).toFixed(2)} to{' '}
              {props.modalData?.firstName} {props.modalData?.lastName}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className='modal-body'>
            <div className='col-md-12'>
              <label htmlFor='firstName' className='form-label'>
                Amount Spent<span className='text-danger'>*</span>
              </label>
              <input
                value={rewardDTO.amount}
                required
                className='form-control '
                type='number'
                onChange={handleChange('amount')}
              />
            </div>
            <br />

            <div className='col-md-12'>
              <label htmlFor='lastName' className='form-label'>
                Percent Discount <span className='text-danger'>*</span>
              </label>
              <input
                value={rewardDTO.percent}
                required
                className='form-control '
                type='number'
                min={0.0}
                max={10.0}
                step={0.01}
                onChange={handleChange('percent')}
                disabled
              />
            </div>
            <div className='col-md-12 mt-4'>
              <label htmlFor='firstName' className='form-label'>
                Evidence of payment<span className='text-danger'>*</span>
              </label>
              <input
                id='upload-file'
                required
                className='form-control '
                type='file'
                accept='.jpg, .jpeg, .png'
                onChange={uploadProofOfPayment}
              />
              <div
                className={isUploading ? 'progress' : 'd-none'}
                style={{ height: '3px', marginTop: '10px' }}
              >
                <div
                  id='progress-bar'
                  className='progress-bar bg-success'
                  role='progressbar'
                  aria-valuenow={0}
                  aria-valuemin={0}
                  aria-valuemax={100}
                />
              </div>
              <div className='fs-8'>
                Only .jpg, .png and .jpeg are allowed. Maximum file size is 5MB
              </div>
            </div>

            <br />
          </Modal.Body>
          <Modal.Footer>
            <button type='submit' className='btn btn-primary' onClick={sendReward}>
              Give Reward
              {btnLoading ? <ButtonLoader /> : ''}
            </button>
          </Modal.Footer>
        </fieldset>
      </form>
    </Modal>
  );
};

export default NewRewardModal;
