import { useState, useEffect, useContext } from 'react';
import { SubscriptionDetailsTab } from '../../../utils/mappings';
import axios from 'axios';
import { endpoints } from '../../../utils/URL';
import { insurancePlans } from '../../../utils/insurancePlans';
import {
  INextOfKinData,
  initialNextOfKinData,
  initialSubscriptionPostData,
  ISubscriptionData,
} from '../../../interfaces/SubscriptionInterface';
import { IBeneficiaryList } from '../../../interfaces/BeneficiaryInterface';

import { addToast } from '../../../utils/toastNotifications';
import { AppContext } from '../../../context/AppContext';
import { Tab, Tabs } from 'react-bootstrap';
import PersonalDetailScreen from './PersonalDetailScreen';
import PlanDetailScreen from './PlanDetailScreen';
import NextOfKinScreen from './NextOfKinScreen';

const AddSubscriptionForm = () => {
  const [subscription, setSubscription] = useState<ISubscriptionData>(initialSubscriptionPostData);
  const [amount, setAmount] = useState(initialSubscriptionPostData.amount);
  const [isLoading, setLoading] = useState(false);
  const { appState } = useContext(AppContext);
  const [nextOfKinData, setNextOfKinData] = useState<INextOfKinData>(initialNextOfKinData);
  const [key, setKey] = useState('info');

  const handleChange = (inputName: string) => (event: any) => {
    if (inputName === 'numberOfSubscribers') {
      const subscribers = event.target.value;
      if (subscribers === '') {
        return;
      }
      const parsedSubscribers = parseInt(subscribers);

      if (parsedSubscribers < 1 || parsedSubscribers > 5) {
        addToast('Enter a minimum of 1 and maximum of 5', 'warning');
        return;
      }
      computeBeneficiaries(parsedSubscribers);
      return;
    }

    const value = event.target.value;
    if (inputName === 'product' && value === 'Pharma' && subscription.paymentPlan === 'Quarterly') {
      console.log('we here');
      let paymentPlan = 'Biannually';
      setSubscription({
        ...subscription,
        product: value,
        paymentPlan: paymentPlan,
      });

      return;
    }

    setSubscription({
      ...subscription,
      [inputName]: event.target.value,
    });
    // }
  };

  const handleNextOfKinDataChange =
    (inputName: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setNextOfKinData({
        ...nextOfKinData,
        [inputName]: event.target.value,
      });
    };

  const computeBeneficiaries = (numberOfSubscribers: number) => {
    if (numberOfSubscribers === 1) {
      //reset array
      subscription.beneficiaryList.length = 0;
      setSubscription({
        ...subscription,
        isBeneficiary: true,
        beneficiaryList: [],
        numberOfSubscribers: numberOfSubscribers,
      });
      return;
    }

    if (subscription.firstName === '') {
      addToast('Fill first and last name before selecting persons in plan');
      return;
    }

    //get total subscribers
    const numOfSubscribers = numberOfSubscribers - 1;
    subscription.beneficiaryList.length = 0;
    //for each subscriber, create beneficiary from main details
    const beneficiaries: Array<IBeneficiaryList> = [];
    for (let i = 0; i < numOfSubscribers; i++) {
      const beneficiary: IBeneficiaryList = {
        firstName: `${subscription.firstName}${i + 1}`,
        lastName: subscription.lastName,
        phone: subscription.phone,
        email: subscription.email,
        dateOfBirth: '2001-01-01',
        gender: 'Others',
      };

      beneficiaries.push(beneficiary);
    }
    setSubscription({
      ...subscription,
      beneficiaryList: beneficiaries,
      isBeneficiary: true,
      numberOfSubscribers: numberOfSubscribers,
    });
  };

  const clearForm = () => {
    subscription.beneficiaryList.length = 0;
    setSubscription(initialSubscriptionPostData);
    setNextOfKinData(initialNextOfKinData);
    //clear tab forms
    //'nok-form', personal-details, plan-details
  };

  const submit = async (e: any) => {
    e.preventDefault();

    if (subscription.product === 'Cashplus' && nextOfKinData.firstName === '') {
      addToast('Add Next Of Kin information in relevant section', 'warning');
      return;
    }

    if (subscription.product === 'Malaria' || subscription.product === 'Pharma') {
      subscription.nextOfKinDTO = undefined;
    } else {
      subscription.nextOfKinDTO = nextOfKinData;
    }

    setLoading(true);

    const payload = subscription;

    payload.amount = amount;
    payload.agentCode = appState.agentCode;

    try {
      const response = await axios.post(endpoints.Subscriptions.mainUrl, payload);

      if (response.status === 200) {
        addToast('Subscription saved successfully', 'success');
      }
      // handleClose();
      clearForm();
      setKey(SubscriptionDetailsTab.info);
    } catch (error: any) {
      if (error.response) {
        addToast('There was an error sending your request', 'error');
      }
    } finally {
      setLoading(false);
    }
  };

  const changeTab = (tabKey: string) => {
    setKey(tabKey);
  };

  useEffect(() => {
    const calculatePayment = () => {
      let paymentPlan = subscription.paymentPlan;
      // const initialBeneficiary = subscription.isBeneficiary === true ? 1 : 0;
      const totalBeneficiaries = parseInt(subscription.numberOfSubscribers.toString()); // initialBeneficiary + subscription.beneficiaryList.length;
      const product = subscription.product;

      if (product === 'Pharma' && paymentPlan === 'Quarterly') {
        paymentPlan = 'Biannually';
      }

      if (totalBeneficiaries < 1) {
        addToast("You must add atleast one beneficiary or set IsBeneficiary as 'Yes'");
        return;
      }

      const selectedPayment = insurancePlans.filter(
        (x) =>
          x.numberOfPersons === totalBeneficiaries &&
          x.planType === paymentPlan &&
          x.product === product
      );
      if (selectedPayment === null || selectedPayment.length === 0) {
        addToast('That Plan combination is invalid', 'error');
        return;
      }

      const price = selectedPayment[0].price;
      setAmount(price);
    };
    calculatePayment();
  }, [subscription.paymentPlan, subscription.numberOfSubscribers, subscription.product, amount]);

  return (
    <>
      <div className='container'>
        <h4 style={{ textAlign: 'center', color: '#000000' }}>Add Subscription</h4>
        <br />
        <div>
          <fieldset id='tab-field' disabled={isLoading}>
            <Tabs
              fill
              id='controlled-tab'
              activeKey={key}
              //onSelect={(k) => setKey(k as string)}
            >
              <Tab eventKey={SubscriptionDetailsTab.info} title='Info'>
                <PersonalDetailScreen
                  subscription={subscription}
                  tabKey={SubscriptionDetailsTab.info}
                  handleChange={handleChange}
                  amount={amount}
                  changeTab={changeTab}
                />
              </Tab>
              <Tab eventKey={SubscriptionDetailsTab.plan} title='Plan Details'>
                <PlanDetailScreen
                  subscription={subscription}
                  tabKey={SubscriptionDetailsTab.plan}
                  handleChange={handleChange}
                  amount={amount}
                  changeTab={changeTab}
                  submitForm={submit}
                />
              </Tab>

              {subscription?.product === 'Cashplus' ? (
                <Tab eventKey={SubscriptionDetailsTab.nextOfKin} title='Next Of Kin'>
                  <NextOfKinScreen
                    subscription={subscription}
                    nextOfKinData={nextOfKinData}
                    tabKey={SubscriptionDetailsTab.plan}
                    handleChange={handleNextOfKinDataChange}
                    amount={amount}
                    submitForm={submit}
                    changeTab={changeTab}
                  />
                </Tab>
              ) : (
                ''
              )}
            </Tabs>
          </fieldset>
        </div>
      </div>
    </>
  );
};

export default AddSubscriptionForm;
