import { useCallback, useEffect, useState } from 'react';
import { addToast } from '../../utils/toastNotifications';
import { useAuth } from 'react-oidc-context';
import { Link } from 'react-router-dom';
import ButtonLoader from '../common/ButtonLoader';

const LandingPage = () => {
  const [loading, setLoading] = useState(false);

  const passwordResetUrl = process.env.REACT_APP_RESET_URL as string;

  const auth = useAuth();

  const loginAuth = useCallback(async () => {
    setLoading(true);
    await auth
      .signinRedirect()
      .then((response: any) => {})
      .catch((error: any) => {
        addToast('Network error occured', 'error');
        console.log(error);
      });
  }, [auth]);

  const logoutAuth = useCallback(async () => {
    await auth
      .signoutRedirect()
      .then(() => console.log('Sign out success'))
      .catch((error) => {
        console.error(error);
      });
  }, [auth]);
  useEffect(() => {
    const searchParam = new URLSearchParams(window.location.search);
    const accessState = searchParam.get('access');
    //check localstorage if value exist
    const rememberMe = localStorage.getItem('rememberMe');
    const updateAvailable = localStorage.getItem('update-available');

    //if value does not exist, save new value as false
    if (typeof rememberMe === 'undefined' || rememberMe == null) {
      localStorage.setItem('rememberMe', 'false');
      return;
    }

    //check if access is already denied
    if (accessState === 'false') {
      addToast('You do not have access to this application', 'error');
      logoutAuth();
    }

    //if update is available, don't auto sign in
    if (updateAvailable != null && updateAvailable === 'true') {
      //show update available prompt, and return
      let refreshButton = document.getElementById('refresh-btn') as HTMLElement;
      refreshButton.style.display = 'block';
      return;
    }

    if (rememberMe === 'true') {
      loginAuth();
    }

    // disabled lint because there is no other viable alternative. similar code worked well in wellahealth.admin.portal
    //eslint-disable-next-line
  }, []);

  return (
    <div className='secondary-bg-color'>
      {/* <h4 className='text-center mt-5'>WellaHealth Agent Network</h4> */}
      <div className='container secondary-bg-color' style={{ marginTop: '60px' }}>
        <div className='text-center mb-5'>
          <a href='/dashboard'>
            <img
              src={require('../../assets/images/wellahealth-plain.svg').default}
              alt='wellahealth'
              width='150'
            />
          </a>
        </div>
        <div className='row justify-content-center'>
          <div className='col-sm-6 col-md-4'>
            <div className='card' style={{ backgroundColor: '#f2f6ff', padding: '20px 10px' }}>
              <h4 className='fw-book text-center'>Welcome Back!</h4>
              <div className='fs-7 fw-light text-center mb-3'>Please login to continue</div>
              <div className='mt-5 d-grid'>
                <button
                  className='btn primary-bg-color'
                  style={{ color: '#fff' }}
                  onClick={loginAuth}
                >
                  LOG IN
                  {loading ? <ButtonLoader /> : ''}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className='color-white fs-7 mt-5 text-center'>
          <Link className='ms-1 color-white plain-link' to='/auth/register/landing'>
            Don't have an account? Register
          </Link>
        </div>
        <div className='color-white fs-7 mt-4 text-center'>
          <a className='ms-1 color-white plain-link' href={passwordResetUrl}>
            Forgot Password
          </a>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
