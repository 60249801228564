import PageHeader from '../../layout/PageHeader';

const SignupSuccess = () => {
  return (
    <div>
      <PageHeader />
      <div className='container' style={{ marginTop: '50px', marginBottom: '50px' }}>
        <div className='row justify-content-center'>
          <div className='col-md-6 text-center'>
            <h4 className='text-center mb-2'>CONGRATULATIONS!!! </h4>
            <div className='mb-2'>Your sign up is almost complete.</div>
            <div>
              Please inform your guarantors to check their emails and fill the gurantor's form.
            </div>
            <br />
            <p>You onboarding details will be sent to you after your guarantors' confirmation.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignupSuccess;
