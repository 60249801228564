import { FormEvent, useCallback, useState } from 'react';
import { INewAgentDataDTO, defaultNewAgent } from '../../../interfaces/AgentInterface';
import { isFormValidated } from '../../../utils/formUtils';
import { endpoints } from '../../../utils/URL';
import axios from 'axios';
import { addToast } from '../../../utils/toastNotifications';
import ButtonLoader from '../../common/ButtonLoader';
import { useAuth } from 'react-oidc-context';

const AgentRegistration = () => {
  const [agentData, setAgentData] = useState<INewAgentDataDTO>(defaultNewAgent);
  const [isLoading, setLoading] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const auth = useAuth();

  const handleChange = (input: string) => (event: any) => {
    setAgentData({
      ...agentData,
      [input]: event.target.value,
    });
  };

  const togglePasswordVisiblity = () => {
    setPasswordShown(!passwordShown);
  };

  const loginAuth = useCallback(async () => {
    //setButtonLoading(true);
    await auth
      .signinRedirect()
      .then((response: any) => {})
      .catch((error: any) => {
        addToast('Network error occured', 'error');
        console.log(error);
      });
  }, [auth]);

  const saveAgent = async (event: FormEvent) => {
    if (!isFormValidated('agent-registration')) {
      return;
    }
    event.preventDefault();

    setLoading(true);
    const url = endpoints.Agents.subAgents;

    try {
      await axios.post(url, agentData);
      addToast('Registration successful. Login to continue', 'success');
      setAgentData(defaultNewAgent);
      //redirect to login
      loginAuth();
    } catch (error) {
      addToast('An error occurred');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <div className='container' style={{ marginTop: '50px', marginBottom: '50px' }}>
        <div className='text-center mb-5'>
          <a href='/dashboard'>
            <img
              src={require('../../../assets/images/wellahealth-plain.svg').default}
              alt='wellahealth'
              width='150'
            />
          </a>
        </div>
        <div className='row d-flex justify-content-center align-items-center'>
          <div className='col-md-4 col-sm-8 card p-3'>
            <h4 className='text-center mb-4'>Wellahealth Agent Registration</h4>

            <form id='agent-registration'>
              <fieldset disabled={isLoading}>
                <div className='form-group mb-3'>
                  <label className='form-label'>First Name</label>
                  <input
                    type='text'
                    className='form-control'
                    value={agentData.firstName}
                    onChange={handleChange('firstName')}
                    required
                  />
                </div>
                <div className='form-group mb-3'>
                  <label className='form-label'>Last Name</label>
                  <input
                    type='text'
                    className='form-control'
                    value={agentData.lastName}
                    onChange={handleChange('lastName')}
                    required
                  />
                </div>

                <div className='form-group mb-3'>
                  <label className='form-label'>Phone Number</label>
                  <input
                    type='tel'
                    className='form-control'
                    value={agentData.phoneNumber}
                    onChange={handleChange('phoneNumber')}
                    maxLength={13}
                    pattern='[0]{1}[7-9]{1}[0-2]{1}[0-9]{8}|[2]{1}[3]{1}[4]{1}[0-9]{10}'
                    required
                  />
                </div>
                <div className='form-group mb-3'>
                  <label className='form-label'>Email address</label>
                  <input
                    type='email'
                    className='form-control'
                    value={agentData.email}
                    onChange={handleChange('email')}
                    required
                  />
                </div>

                <div className='form-group mb-3'>
                  <label className='form-label'>Address</label>
                  <input
                    type='text'
                    className='form-control'
                    value={agentData.location}
                    onChange={handleChange('location')}
                    required
                  />
                </div>
                <div className='form-group mb-3'>
                  <label className='form-label' style={{ display: 'block' }}>
                    Password
                    <small style={{ fontSize: '0.7rem', display: 'block' }}>
                      (must be atleast 6 characters, with atleast one number and one letter)
                    </small>
                    <div
                      className={
                        passwordShown ? 'bi bi-eye-slash float-end' : 'bi bi-eye float-end'
                      }
                      onClick={togglePasswordVisiblity}
                    ></div>
                  </label>
                  <input
                    className='form-control'
                    value={agentData.password}
                    onChange={handleChange('password')}
                    required
                    type={passwordShown ? 'text' : 'password'}
                    pattern='^(?=.*[0-9])[a-zA-Z0-9!@#$%^&*]{6,24}$'
                    title='Password must be atleast 6 characters, with atleast one number and one letter'
                  />
                </div>

                <div className='form-group mb-3'>
                  <label className='form-label'>Agent Code</label>
                  <input
                    type='text'
                    className='form-control'
                    value={agentData.superAgentCode}
                    onChange={handleChange('superAgentCode')}
                    required
                  />
                </div>

                <button type='submit' onClick={saveAgent} className='btn btn-primary form-control'>
                  Submit {isLoading ? <ButtonLoader /> : ''}
                </button>
              </fieldset>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AgentRegistration;
