import React, { useContext, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { isFormValidated } from '../../../utils/formUtils';
import { addToast } from '../../../utils/toastNotifications';
import { IRedeemRewardModalProps } from '../../../interfaces/WellaPayUser';
import axios from 'axios';
import { endpoints } from '../../../utils/URL';
import { AppContext } from '../../../context/AppContext';

const RedeemRewardModal = (props: IRedeemRewardModalProps) => {
  const [reward, setReward] = useState({
    accountNumber: props.modalData?.accountNumber,
    rewardType: 'Cashback',
    rewardPoints: 0,
    agentCode: '',
    agentName: '',
  });
  const { appState } = useContext(AppContext);

  const handleChange = (input: string) => (event: any) => {
    setReward({
      ...reward,
      [input]: event.target.value,
    });
  };

  const redeemReward = async (event: any) => {
    if (!isFormValidated('redeem-reward-modal')) {
      return;
    }

    event.preventDefault();
    reward.accountNumber = props.modalData.accountNumber;
    reward.agentCode = appState.agentCode;
    reward.agentName = `${appState.firstName} ${appState.lastName}`;

    const url = `${endpoints.Rewards.mainUrl}/redeem`;

    try {
      await axios.post(url, reward);
      addToast('Reward redeemed successfully', 'success');
      if (props.callback) {
        props.callback(props.modalData.phoneNumber);
      }
      props.close();
    } catch (error) {
      addToast('An error occured', 'error');
    } finally {
    }
  };

  return (
    <Modal show={props.open} onHide={props.close}>
      <form id='redeem-reward-modal'>
        <Modal.Header closeButton>
          <Modal.Title>Balance: {props.modalData?.totalCashback}</Modal.Title>
        </Modal.Header>
        <Modal.Body className='modal-body'>
          <div className='col-md-12'>
            <label htmlFor='firstName' className='form-label'>
              Amount to redeem<span className='text-danger'>*</span>
            </label>
            <input
              value={reward.rewardPoints}
              required
              className='form-control '
              type='number'
              onChange={handleChange('rewardPoints')}
              max={props.modalData?.totalCashback}
              min={100}
            />
          </div>

          <br />
        </Modal.Body>
        <Modal.Footer>
          <button type='submit' className='btn btn-primary' onClick={redeemReward}>
            Give Reward
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default RedeemRewardModal;
