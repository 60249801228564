import axios from 'axios';
import { useState, useEffect, useCallback, useContext } from 'react';
import { AppContext } from '../../../context/AppContext';
import {
  IClaimEligibility,
  ISubscriptionResponse,
} from '../../../interfaces/SubscriptionInterface';
import { formatDate } from '../../../utils/dateTimeUtils';
import { endpoints } from '../../../utils/URL';

const SubscriptionDetail = ({ history }: any) => {
  const [claimEligibity, setClaimEligibility] = useState<IClaimEligibility | null>();

  const { appState } = useContext(AppContext);

  const [modalData, setModalData] = useState<ISubscriptionResponse | undefined>();

  const getStatusColor = (subscriptionStatus: string) => {
    switch (subscriptionStatus) {
      case 'Active':
        return 'detail-data text-success';
      case 'MissedPayment':
        return 'detail-data text-danger';
      case 'Cancelled':
        return 'detail-data text-danger';
      default:
        return 'detail-data text-info';
    }
  };
  const getClaimEligibility = useCallback(async (subscriptionCode: string) => {
    setClaimEligibility(null);

    const url = `${endpoints.Claims.mainUrl}/eligibility/${subscriptionCode}`;

    try {
      const response = await axios.get(url);
      setClaimEligibility(response.data);
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    const data = appState.selectedSubscription;

    if (data === null || typeof data === 'undefined') {
      history.push('/subsciptions');
    }
    setModalData(data);
    getClaimEligibility(data.subscriptionCode);
  }, [history, getClaimEligibility, appState]);

  return (
    <div className='row justify-content-center'>
      <div className='col-md-4 col-sm-6'>
        <div className='text-center detail-data'>Subscription Details</div>
        {modalData ? (
          <div className='mt-5'>
            <div className='fw-light'>Personal Information</div>
            <div className='card home-card subscription-card mt-2'>
              <div className='card-body'>
                <div className='row card-text subscription-card-text mb-3'>
                  <label className='detail-label'>Full name</label>
                  <div className='detail-data'>
                    {modalData?.firstName} {modalData?.lastName}
                  </div>
                </div>
                <div className='row card-text subscription-card-text mb-3'>
                  <label className='detail-label'>Phone number</label>
                  <div className='detail-data'>{modalData?.phone}</div>
                </div>
                <div className='row card-text subscription-card-text mb-3'>
                  <label className='detail-label'>Email</label>
                  <div className='detail-data'>{modalData?.email}</div>
                </div>
                <div className='row card-text subscription-card-text mb-3'>
                  <label className='detail-label'>Subscription Code</label>
                  <div className='detail-data'>{modalData?.subscriptionCode}</div>
                </div>
              </div>
            </div>
            <div className='mt-4 fw-light'>Plan Information</div>
            <div className='card home-card subscription-card mt-2'>
              <div className='card-body'>
                <div className='row card-text subscription-card-text mb-3'>
                  <label className='detail-label'>Product</label>
                  <div className='detail-data'>{modalData?.product}</div>
                </div>
                <div className='row card-text subscription-card-text mb-3'>
                  <label className='detail-label'>Payment Plan</label>
                  <div className='detail-data'>{modalData?.paymentPlan}</div>
                </div>
                <div className='row card-text subscription-card-text mb-3'>
                  <label className='detail-label'>Number of Beneficiaries</label>
                  <div className='detail-data'>{modalData?.numberOfSubscribers}</div>
                </div>
                <div className='row card-text subscription-card-text mb-3'>
                  <label className='detail-label'>Amount</label>
                  <div className='detail-data'>{modalData?.amount}</div>
                </div>
              </div>
            </div>
            <div className='mt-4 fw-light'>Plan Status</div>
            <div className='card home-card subscription-card mt-2'>
              <div className='card-body'>
                <div className='row card-text subscription-card-text mb-3'>
                  <label className='detail-label'>Status</label>
                  <div className={getStatusColor(modalData!.status)}>{modalData?.status}</div>
                </div>
                <div className='row card-text subscription-card-text mb-3'>
                  <label className='detail-label'>Next Payment</label>
                  <div className='detail-data'>{formatDate(modalData?.nextPayment)}</div>
                </div>
                <div className='row card-text subscription-card-text mb-3'>
                  <label>
                    <label className='detail-label'>Claim Eligibility: </label>
                  </label>
                  <div className='detail-data'>
                    {claimEligibity ? (
                      <div>
                        <label className='ps-2'>
                          {claimEligibity?.eligible ? (
                            <span className='text-success'>Eligble</span>
                          ) : (
                            <span className='text-danger'>Ineligible</span>
                          )}
                        </label>
                        <br />
                        <label className='detail-label'>
                          Reason:
                          <span
                            className={
                              claimEligibity?.eligible ? 'ps-2 text-success' : 'ps-2 text-danger'
                            }
                          >
                            {claimEligibity?.reason}
                          </span>
                        </label>
                      </div>
                    ) : (
                      <span className='text-info ps-2'>Checking claims eligibility..</span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

export default SubscriptionDetail;
