import { useState, useContext, useEffect, useCallback } from 'react';
import { addToast } from '../../../utils/toastNotifications';
import ButtonLoader from '../../common/ButtonLoader';
import { endpoints } from '../../../utils/URL';
import axios from 'axios';
import { AppContext } from '../../../context/AppContext';
import { INewEsusuCustomer, intialNewCustomer } from '../../../interfaces/EsusuInterface';

const EsusuRegistration = () => {
  const { appState } = useContext(AppContext);
  const [localState, setLocalState] = useState<INewEsusuCustomer>(intialNewCustomer);
  const [isLoading, setLoading] = useState(false);
  const [amount, setAmount] = useState('0');
  let baseAmount = 500;

  const handleChange = (input: string) => (event: any) => {
    setLocalState({
      ...localState!,
      [input]: event.target.value,
    });
  };

  const clearForm = () => {
    setLocalState(intialNewCustomer);
  };

  const submit = async (event: any) => {
    event.preventDefault();

    setLoading(true);
    const url = endpoints.Esusu.Customers.mainUrl;

    localState.agentCode = appState.agentCode;
    const payload = localState;

    try {
      const response = await axios.post(url, payload);
      if (response.status === 200) {
        addToast('Registration Completed Successfully', 'success');
        clearForm();
      }
    } catch (error) {
      addToast('Error Registering Customer. Please try again later.', 'error');
    } finally {
      setLoading(false);
    }
  };

  const calculateAmount = useCallback(() => {
    let totalAmount = baseAmount * localState!.numberOfSubscribers * localState!.durationPaidFor;
    setAmount(`₦${totalAmount}`);
  }, [localState, baseAmount]);

  useEffect(() => {
    calculateAmount();
  }, [calculateAmount]);

  return (
    <div className='row justify-content-center'>
      <div className='col-md-5 col-sm-12'>
        <div className='mt-5'>
          <h3 className='fw-medium text-center'>Esusu Registration</h3>
          <div className='card home-card subscription-card mt-4'>
            <div className='card-body'>
              <form id='esusu-registration-form'>
                <fieldset disabled={isLoading}>
                  <div className='form-floating form-group mb-5'>
                    <input
                      type='text'
                      className='form-control'
                      value={localState!.firstName}
                      onChange={handleChange('firstName')}
                      required
                    />
                    <label className='form-label'>
                      First Name<span className='text-danger'>*</span>
                    </label>
                  </div>
                  <div className='form-floating form-group mb-5'>
                    <input
                      type='text'
                      className='form-control'
                      value={localState!.lastName}
                      onChange={handleChange('lastName')}
                      required
                    />
                    <label className='form-label'>
                      Last Name<span className='text-danger'>*</span>
                    </label>
                  </div>
                  <div className='form-floating form-group mb-5'>
                    <input
                      value={localState!.phoneNumber}
                      maxLength={13}
                      type='tel'
                      pattern='[0]{1}[7-9]{1}[0-2]{1}[0-9]{8}|[2]{1}[3]{1}[4]{1}[0-9]{10}'
                      className='form-control'
                      onChange={handleChange('phoneNumber')}
                      required
                    />
                    <label className='form-label'>
                      Phone Number<span className='text-danger'>*</span>
                    </label>
                  </div>

                  <div className='form-floating form-group mb-5'>
                    <input
                      type='text'
                      className='form-control'
                      value={localState!.address}
                      onChange={handleChange('address')}
                      required
                    />
                    <label className='form-label'>
                      Address<span className='text-danger'>*</span>
                    </label>
                  </div>

                  <div className='form-floating form-group mb-5'>
                    <input
                      value={localState!.numberOfSubscribers}
                      onChange={handleChange('numberOfSubscribers')}
                      className='form-control'
                      type='number'
                      min={1}
                      step={1}
                      max={5}
                    />
                    <label className='form-label'>
                      Number of Subscribers<span className='text-danger'>*</span>
                    </label>
                  </div>

                  <div className='form-floating form-group mb-5'>
                    <input
                      onChange={handleChange('durationType')}
                      type='text'
                      className='form-control'
                      value={localState.durationType}
                      disabled
                    />
                    <label>Duration Type</label>
                  </div>

                  <div className='form-floating form-group mb-5'>
                    <input
                      value={localState.durationPaidFor}
                      onChange={handleChange('durationPaidFor')}
                      className='form-control'
                      type='number'
                      min={0}
                      step={1}
                      max={4}
                    />
                    <label className='form-label'>
                      Number of Weeks Paid For<span className='text-danger'>*</span>
                    </label>
                  </div>

                  <div className='form-floating form-group mb-5'>
                    <input value={amount} className='form-control' type='text' disabled />
                    <label className='form-label'>
                      Total amount paid<span className='text-danger'>*</span>
                    </label>
                  </div>

                  <div className='mt-4 d-grid'>
                    <button onClick={submit} type='submit' className='btn btn-primary'>
                      Submit
                      {isLoading ? <ButtonLoader /> : ''}
                    </button>
                  </div>
                </fieldset>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EsusuRegistration;
