// import React, { useState } from 'react';
// import { Dropdown } from 'react-bootstrap';

const HeaderLogo = () => {
  // const [isLightTheme, setLightTheme] = useState(true);

  // Theme related methods

  // const getSavedTheme = useCallback(() => {
  //   //get from localstorage, if null or undefined, set as light
  //   const theme = localStorage.getItem('theme')?.toString();
  //   if (theme !== null && typeof theme !== 'undefined') {
  //     setTheme(theme);
  //   }
  // }, []);

  // const setTheme = (theme: string | undefined) => {
  //   //get from localstorage, if null or undefined, set as light
  //   if (theme === 'light') {
  //     document.body.classList.remove('dark-theme');
  //     setLightTheme(true);
  //   } else {
  //     document.body.classList.add('dark-theme');
  //     setLightTheme(false);
  //   }
  //   // }
  // };

  // const toggleTheme = (event: any) => {
  //   setLightTheme(event.target.checked);
  //   const theme = event.target.checked ? 'light' : 'dark';
  //   localStorage.setItem('theme', theme);
  //   setTheme(theme);
  // };

  return (
    <nav
      className='navbar navbar-light sticky-top flex-md-nowrap p-0 navbar-custom'
      style={{ backgroundColor: '#fff' }}
    >
      <div className='' style={{ margin: '10px 0px 15px 70px' }}>
        <img
          src={require('../../assets/images/wellahealth.svg').default}
          alt='wellahealth'
          width='100'
        />
      </div>
      <button
        // className='navbar-toggler position-absolute d-md-none collapsed'
        className='navbar-toggler position-absolute collapsed plain-link'
        type='button'
        data-toggle='collapse'
        data-target='#sidebarMenu'
        aria-controls='sidebarMenu'
        aria-expanded='false'
        aria-label='Toggle navigation'
      >
        <span
          className='navbar-toggler-icon'
          data-toggle='collapse'
          data-target='#sidebarMenu'
        ></span>
      </button>

      {/* <div style={{ marginRight: '10px' }}>
        <Dropdown style={{ display: 'inline', marginRight: '10px' }}>
          <Dropdown.Toggle variant='' id='dropdown-basic-1'>
            <i className='bi bi-gear' style={{ fontSize: '1.2rem' }}></i>
          </Dropdown.Toggle>

          <Dropdown.Menu className='drop-down-menu-custom-1'>
            <Dropdown.Item as='div' title='Click to mark as read'>
              <div className='form-check form-switch'>
                <input
                  type='checkbox'
                  className='form-check-input'
                  id='theme-toggle'
                  checked={isLightTheme}
                  onChange={toggleTheme}
                />
                <label className='custom-control-label' htmlFor='theme-toggle'>
                  {isLightTheme ? 'Light Mode' : 'Dark Mode'}
                </label>
              </div>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div> */}
    </nav>
  );
};

export default HeaderLogo;
