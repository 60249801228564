import { IBeneficiaryList } from './BeneficiaryInterface';
// import { GenericDetailsModalProps } from './GenericInterface';

export interface INextOfKinData {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  relationship: string;
}

export interface ISubscriptionResponse {
  subscriptionCode: string;
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  status: string;
  amount: number;
  paymentPlan: string;
  numberOfSubscribers: number;
  subscriptionAccount: string;
  healthSavingsAccount: number;
  nextPayment: string;
  product: string;
}

export interface ISubscriptionData {
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  location: string;
  stateOfResidence: string;
  lga: string;
  area: string;
  streetName: string;
  isBeneficiary: boolean;
  acquisitionChannel: string;
  amount: number;
  paymentPlan: string;
  paymentChannel: string;
  isPaymentVerified: boolean;
  dateOfBirth: string;
  gender: string;
  beneficiaryList: Array<IBeneficiaryList>;
  product: string;
  nextOfKinDTO: INextOfKinData | undefined;
  agentCode: string;
  numberOfSubscribers: number;
}

export interface INextOfKinModalProps {
  open: boolean;
  close: () => void;
  handleChange: (input: string) => (event: React.ChangeEvent<HTMLInputElement>) => void;
  modalData: INextOfKinData;
  addToPlan: () => void;
}

export interface ISubscriptionInfoModal {
  open: boolean;
  modalData: ISubscriptionResponse;
  close: () => void;
  handleModalChange?: Function;
}

export interface IClaimEligibility {
  eligible: boolean;
  reason: string;
}

//Initialization

export const initialNextOfKinData: INextOfKinData = {
  firstName: '',
  lastName: '',
  phoneNumber: '',
  email: '',
  relationship: 'Relative',
};

export const initialSubscriptionPostData: ISubscriptionData = {
  firstName: '',
  lastName: '',
  phone: '',
  email: '',
  location: '',
  stateOfResidence: '',
  lga: '',
  area: '',
  streetName: '',
  isBeneficiary: true,
  acquisitionChannel: 'AgentNetwork',
  amount: 600,
  paymentPlan: 'Quarterly',
  paymentChannel: 'Agent',
  dateOfBirth: '',
  gender: 'Others',
  beneficiaryList: [],
  product: 'Cashplus',
  nextOfKinDTO: initialNextOfKinData,
  agentCode: '',
  isPaymentVerified: false,
  numberOfSubscribers: 1,
};

export const initialSubscriptionResponse: ISubscriptionResponse = {
  subscriptionCode: '',
  firstName: '',
  lastName: '',
  phone: '',
  email: '',
  status: 'Unpaid',
  amount: 0,
  paymentPlan: '',
  numberOfSubscribers: 0,
  subscriptionAccount: '',
  healthSavingsAccount: 0,
  nextPayment: '',
  product: 'Malaria',
};

export interface ISubscriptionDetailsProp {
  tabKey: string;
  subscription: ISubscriptionData | undefined;
  nextOfKinData?: INextOfKinData;
  changeTab?: Function;
  handleChange: Function;
  amount: number;
  submitForm?: Function;
}
