import axios from 'axios';
import { useState } from 'react';
import { endpoints } from '../../../utils/URL';
import ButtonLoader from '../../common/ButtonLoader';

const VoucherCodes = () => {
  const [btnLoading, setBtnLoading] = useState(false);

  const [voucherData, setVoucherData] = useState({
    voucherCode: '',
    message: '',
  });

  const searchForCode = async (event: any) => {
    event.preventDefault();

    const url = `${endpoints.Subscriptions.mainUrl}/vouchers/${voucherData.voucherCode}`;
    setBtnLoading(true);
    let message = '';
    try {
      const response = await axios.get(url);
      message = response.data;
    } catch (error: any) {
      if (error.response && error.response.data) {
        message = error.response.data.detail.replace('END', '');
      }
    } finally {
      setBtnLoading(false);
    }
    setVoucherData({
      ...voucherData,
      message: message,
    });
  };

  const handleChange = (inputName: string) => (event: any) => {
    setVoucherData({
      ...voucherData,
      [inputName]: event.target.value,
    });
  };

  return (
    <>
      <div className='container'>
        <form className='row justify-content-center mt-5'>
          <div className='col-md-4 col-sm-6 mt-3'>
            <div className='form-group'>
              <label className='form-label'>
                Voucher Code <span className='text-danger'>*</span>
              </label>
              <input
                required
                className='form-control'
                type='tel'
                pattern='[0]{1}[7-9]{1}[0-2]{1}[0-9]{8}|[2]{1}[3]{1}[4]{1}[0-9]{10}'
                value={voucherData.voucherCode}
                onChange={handleChange('voucherCode')}
              />
            </div>
            <br />
            <div className='d-grid'>
              <button className='btn btn-primary ' onClick={searchForCode}>
                Search Voucher Code
                {btnLoading ? <ButtonLoader /> : ''}
              </button>
            </div>
          </div>
        </form>
        <div className='text-center mt-4'>{voucherData.message}</div>
      </div>
    </>
  );
};

export default VoucherCodes;
