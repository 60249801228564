import axios from 'axios';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  defaultWellaPayReward,
  IWellaPayReward,
  IWellaPayUserDTO,
} from '../../../interfaces/WellaPayUser';
import { addToast } from '../../../utils/toastNotifications';
import { endpoints } from '../../../utils/URL';
import ButtonLoader from '../../common/ButtonLoader';
import NewRewardModal from './NewRewardModal';
import RedeemRewardModal from './RedeemRewardModal';

const ViewWellaPayUsers = () => {
  //
  const [profileData, setProfileData] = useState<IWellaPayUserDTO | undefined>();
  const [rewardData, setRewardData] = useState<IWellaPayReward | undefined>();
  const [btnLoading, setBtnLoading] = useState(false);

  const [newRewardOpen, setNewRewardOpen] = useState(false);
  const [redeemRewardOpen, setRedeemRewardOpen] = useState(false);

  const toggleNewRewardModal = () => {
    setNewRewardOpen(!newRewardOpen);
  };

  const toggleRedeemRewardModal = () => {
    setRedeemRewardOpen(!redeemRewardOpen);
  };

  const searchForAccount = async (event: any) => {
    event.preventDefault();

    const phoneNumberElement = document.getElementById('phone-number') as any;
    const phoneNumber = phoneNumberElement.value;

    const url = `${endpoints.Accounts.mainUrl}/${phoneNumber}`;
    setBtnLoading(true);
    try {
      const response = await axios.get(url);
      setProfileData(response.data);
      await getRewardDetails(phoneNumber);
    } catch (error: any) {
      if (error.response && (error.response.status === 404 || error.response.status === 403)) {
        addToast('Account not found', 'error');
      }
    } finally {
      setBtnLoading(false);
    }
  };

  const getRewardDetails = async (phoneNumber: string) => {
    const url = `${endpoints.Rewards.mainUrl}/phoneNumber/${phoneNumber}`;
    try {
      const response = await axios.get(url);
      setRewardData(response.data);
    } catch (error: any) {
      if (error.response && error.response.status === 404) {
        setRewardData(defaultWellaPayReward);
      }
    }
  };

  const newRewardModal = (
    <NewRewardModal
      open={newRewardOpen}
      close={toggleNewRewardModal}
      modalData={profileData!}
      callback={getRewardDetails}
    />
  );

  const redeemRewardModal = (
    <RedeemRewardModal
      open={redeemRewardOpen}
      close={toggleRedeemRewardModal}
      modalData={rewardData!}
      callback={getRewardDetails}
    />
  );

  return (
    <>
      {newRewardModal}
      {redeemRewardModal}
      <div className='container'>
        <form className='row justify-content-center mt-5'>
          <div className='col-md-4 col-sm-6 mt-3'>
            <div className='form-group'>
              <label className='form-label'>
                Phone Number <span className='text-danger'>*</span>
              </label>
              <input
                required
                className='form-control'
                type='tel'
                pattern='[0]{1}[7-9]{1}[0-2]{1}[0-9]{8}|[2]{1}[3]{1}[4]{1}[0-9]{10}'
                id='phone-number'
              />
            </div>
            <br />
            <div className='d-grid'>
              <button className='btn btn-primary' onClick={searchForAccount}>
                Search User
                {btnLoading ? <ButtonLoader /> : ''}
              </button>
            </div>
          </div>
          <div className='col-sm-6 text-sm-end mt-5'>
            <button className='btn btn-primary'>
              <Link to='/wellapay/users/add' style={{ textDecoration: 'none', color: 'white' }}>
                Add User
              </Link>
            </button>
          </div>
        </form>
        {profileData ? (
          <div className='row justify-content-center'>
            <div className='mt-3 pb-4 pt-4 table-responsive col-sm-6'>
              <table className='card table pb-4 pt-4  pl-4 pr-4 table-borderless'>
                <tbody>
                  <tr>
                    <th>Account Number</th>
                    <td>{profileData!.accountNumber}</td>
                  </tr>
                  <tr>
                    <th>First Name</th>
                    <td>{profileData!.firstName}</td>
                  </tr>
                  <tr>
                    <th>Last Name</th>
                    <td>{profileData!.lastName}</td>
                  </tr>

                  <tr>
                    <th>Phone Number</th>
                    <td>{profileData!.phoneNumber}</td>
                  </tr>
                  <tr>
                    <th>Email</th>
                    <td>{profileData!.email}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className='mt-3 pb-4 pt-4 table-responsive col-sm-6'>
              {rewardData ? (
                <table className='card  pb-4 pt-4 pl-4 pr-4 table table-borderless'>
                  <tbody>
                    <tr>
                      <th>Cashback Amount</th>
                      <td>{rewardData!.totalCashback}</td>
                    </tr>
                    <tr>
                      <th>Loyalty Points</th>
                      <td>{rewardData!.totalLoyaltyPoints}</td>
                    </tr>
                    <tr>
                      <th>Account Number</th>
                      <td>{rewardData!.accountNumber}</td>
                    </tr>
                    <tr>
                      <td>
                        <button className='btn btn-success btn-sm' onClick={toggleNewRewardModal}>
                          Give Cashback
                        </button>
                      </td>
                      <td>
                        <button
                          className='btn btn-warning btn-sm'
                          onClick={toggleRedeemRewardModal}
                        >
                          Redeem
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              ) : (
                ''
              )}
            </div>
          </div>
        ) : (
          <div></div>
        )}
      </div>
    </>
  );
};

export default ViewWellaPayUsers;
