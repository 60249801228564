import axios from 'axios';
import { useCallback, useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../context/AppContext';
import {
  IWalletAccountSummary,
  IWalletTransactionHistory,
} from '../../../interfaces/WalletInterface';
import { addToast } from '../../../utils/toastNotifications';
import { endpoints } from '../../../utils/URL';
import { formatDate, formatDisplayDate } from '../../../utils/dateTimeUtils';
import TableLoader from '../../common/TableLoader';

const WalletDashboard = ({ history }: any) => {
  const { dispatch } = useContext(AppContext);

  const [walletFound, setWalletFound] = useState(false);

  const [isLoading, setLoading] = useState(true);
  const [showLoadButton, setShowLoadButton] = useState(false);
  const [showAmount, setShowAmount] = useState(true);

  let pageNumber = 1;

  const [transactionHistory, setTransactionHistory] = useState<IWalletTransactionHistory[]>([]);
  const [walletSummary, setWalletSummary] = useState<IWalletAccountSummary>({
    walletBalance: 0.0,
    totalCredits: 0.0,
    totalDebits: 0.0,
    bankAccountName: '',
    bankAccountNumber: '',
    bankName: '',
  });

  const getTransactionHistory = useCallback(async (pageNumber: number) => {
    //call API
    const url = `${endpoints.UserWallets.transactions}?pageNumber=${pageNumber}&pageSize=${10}`;

    try {
      const response = await axios.get(url);

      if (response.data.length > 0) {
        setShowLoadButton(true);
        const data = response.data as IWalletTransactionHistory[];
        console.log(data);
        //setTransactionHistory(data);
        setTransactionHistory((old) => [...old, ...data]);
      } else {
        setShowLoadButton(false);
      }
    } catch (error) {
      addToast('An error occured', 'error');
    } finally {
      setLoading(false);
    }
  }, []);

  const getWalletSummary = useCallback(async () => {
    setLoading(true);
    //call API
    const url = `${endpoints.UserWallets.mainUrl}`;

    try {
      const response = await axios.get(url);

      setWalletSummary(response.data);

      //save to context
      dispatch({
        type: 'SET_WALLET',
        payload: response.data,
      });

      setTransactionHistory([]);
      getTransactionHistory(1);

      setWalletFound(true);
    } catch (error: any) {
      if (error.response) {
        if (error.response.status === 404) {
          setWalletFound(false);
        } else {
          addToast('An error occured', 'error');
        }
      }
    } finally {
      setLoading(false);
    }
  }, [dispatch, getTransactionHistory]);

  const loadMoreData = (event: any) => {
    event.preventDefault();
    pageNumber = pageNumber + 1;
    getTransactionHistory(pageNumber);
  };

  const toggleShowAmount = () => {
    setShowAmount(!showAmount);
  };

  const toggleAccountAction = () => {
    if (walletSummary.bankAccountNumber === '') {
      // means account not set
      addToast('No wallet account');
    } else {
      //show withdrawal
      history.push('/wallet/withdrawal');
    }
  };

  useEffect(() => {
    getWalletSummary();

    // if (walletFound) {
    //   getTransactionHistory(1);
    // }
  }, [getWalletSummary]);

  const noWallet = <div className='text-center'>No wallet found</div>;

  const formatTransactionType = (transactionType: string) => {
    if (transactionType === 'Credit') {
      return 'col-3 primary-color';
    }
    return 'col-3 text-muted';
  };

  const walletDisplay = (
    <div className='container'>
      <div className='row justify-content-center'>
        <div className='col-sm-8 col-md-6'>
          <div className='row mb-4'>
            <div
              className='home-card card mt-3 col-12'
              style={{ background: 'linear-gradient(109.04deg, #5BA532 3.23%, #1489C8 132.43%)' }}
            >
              <div className='card-body form-group'>
                <div className='row'>
                  <div className='col-10' style={{ margin: 'auto', color: '#fff' }}>
                    <div>
                      <div className='mt-2 home-card-subtitle fs-6'>Your Wallet Balance</div>
                      <div className='home-card-title fs-1 fw-medium mt-2'>
                        ₦{showAmount ? walletSummary.walletBalance.toLocaleString() : '*****'}
                      </div>
                    </div>
                  </div>
                  <div className='col-2 align-items-bottom' onClick={toggleShowAmount}>
                    {showAmount ? (
                      <span
                        className='bi bi-eye-slash'
                        style={{ fontSize: '1.5rem', color: '#fff' }}
                      ></span>
                    ) : (
                      <span
                        className='bi bi-eye'
                        style={{ fontSize: '1.5rem', color: '#fff' }}
                      ></span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='row mb-4'>
            <div className='col-6 padding-left-reset d-grid'>
              <button className='btn btn-withdrawal btn-height' onClick={toggleAccountAction}>
                Withdrawal
                <span className='bi bi-arrow-up-right primary-color ms-2'></span>
              </button>
            </div>
            <div className='col-6 padding-right-reset d-grid'>
              <button className='btn btn-deposit btn-height'>
                Deposit
                <span className='bi bi-arrow-down-left ms-2'></span>
              </button>
            </div>
          </div>

          {transactionHistory.length === 0 ? (
            <div className='text-center mt-5'>No transaction history</div>
          ) : (
            <div>
              <h6 style={{ marginBottom: '10px', fontWeight: 'bold' }}>Transaction History</h6>

              {transactionHistory.map((groupData, key) => (
                <div key={key} className='row' style={{ marginTop: '20px', marginBottom: '20px' }}>
                  <div className='col-12'>
                    <div>{formatDate(groupData.transactionDate)}</div>
                    {groupData.transactions.map((data, key2) => (
                      <div key={key2} className='row text-left transaction'>
                        <div className='row mt-3'>
                          <div className='col-2 align-items-middle'>
                            {data.transactionType === 'Credit' ? (
                              <img
                                src={require('../../../assets/icons/arrow-down-right.svg').default}
                                alt=''
                              />
                            ) : (
                              <img
                                src={require('../../../assets/icons/arrow-up-left.svg').default}
                                alt=''
                              />
                            )}
                          </div>
                          <div className='col-7'>
                            <div className={formatTransactionType(data.transactionType)}>
                              <span className=' fs-5'>{data.transactionType}</span>
                            </div>
                            <div className='fs-8'>{formatDisplayDate(data.transactionDate)}</div>
                          </div>
                          <div className='col-3 fs-5'>
                            <span className={formatTransactionType(data.transactionType)}>
                              ₦{data.amount.toLocaleString()}
                            </span>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
        <div className='text-center'>
          {showLoadButton ? (
            <button className='btn btn-sm' onClick={loadMoreData}>
              Load more
            </button>
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  );

  return (
    <div>
      <h5 className='text-center' style={{ marginTop: '20px', fontWeight: 500, color: '#000' }}>
        Float Wallet
      </h5>
      {isLoading ? (
        <div className='mt-5'>
          <TableLoader />
        </div>
      ) : walletFound ? (
        walletDisplay
      ) : (
        noWallet
      )}
    </div>
  );
};

export default WalletDashboard;
