import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { IEsusuPaymentModal } from '../../../interfaces/EsusuInterface';
import { isFormValidated } from '../../../utils/formUtils';
import { addToast } from '../../../utils/toastNotifications';
import { endpoints } from '../../../utils/URL';
import ButtonLoader from '../../common/ButtonLoader';

const EsusuPaymentModal = ({ modalData, open, close }: IEsusuPaymentModal) => {
  const [modalLoading, setModalLoading] = useState(false);
  const [durationPaidFor, setDurationPaidFor] = useState(0);
  const [amount, setAmount] = useState('0');

  let baseAmount = 500;

  const savePayment = async (event: any) => {
    if (!isFormValidated('payment-modal')) {
      return;
    }

    event.preventDefault();

    setModalLoading(true);

    try {
      const payload = {
        customerId: modalData.customerId,
        durationType: 'Weekly',
        durationPaidFor: durationPaidFor,
      };
      const response = await axios.post(endpoints.Esusu.Payments.mainUrl, payload);
      if (response.status === 200) {
        addToast('Payment added successfully', 'success');
        close();
      }
    } catch (error) {
      addToast('Error adding payment', 'error');
    } finally {
      setModalLoading(false);
    }
  };

  const calculateAmount = useCallback(() => {
    const numberOfSubscribers = modalData ? modalData.numberOfSubscribers : 2;
    let totalAmount = baseAmount * numberOfSubscribers * durationPaidFor;
    setAmount(`₦${totalAmount}`);
  }, [durationPaidFor, baseAmount, modalData]);

  useEffect(() => {
    calculateAmount();
  }, [calculateAmount]);

  return (
    <Modal centered show={open} onHide={close}>
      <Modal.Header closeButton>
        <Modal.Title>
          Add Payment for {modalData?.firstName} {modalData?.lastName}
        </Modal.Title>
      </Modal.Header>
      <form id='payment-modal'>
        <fieldset disabled={modalLoading}>
          <Modal.Body>
            <div className='form-floating form-group mb-5'>
              <input
                value={durationPaidFor}
                onChange={(e) => setDurationPaidFor(parseInt(e.target.value))}
                className='form-control'
                type='number'
                disabled={modalLoading}
                min={1}
                step={1}
                max={4}
              />
              <label className='form-label'>
                Number of Weeks Paid For<span className='text-danger'>*</span>
              </label>
            </div>
            <div className='form-floating form-group mb-5'>
              <input
                value={modalData?.numberOfSubscribers}
                className='form-control'
                type='number'
                disabled
              />
              <label className='form-label'>
                Number of Subscribers<span className='text-danger'>*</span>
              </label>
            </div>
            <div className='form-floating form-group mb-5'>
              <input value={amount} className='form-control' type='text' disabled />
              <label className='form-label'>
                Amount to pay<span className='text-danger'>*</span>
              </label>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button type='submit' className='btn btn-primary' onClick={savePayment}>
              Save Payment
              {modalLoading ? <ButtonLoader /> : ''}
            </button>
          </Modal.Footer>
        </fieldset>
      </form>
    </Modal>
  );
};

export default EsusuPaymentModal;
