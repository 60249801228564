export const STATES_LGA = [
  {
    state: '',
    id: 0,
    locals: [],
  },

  {
    state: 'Abia',
    id: 1,
    locals: [
      {
        name: 'Aba North',
        id: 2005,
      },
      {
        name: 'Aba South',
        id: 2006,
      },
      {
        name: 'Arochukwu',
        id: 2007,
      },
      {
        name: 'Bende',
        id: 2008,
      },
      {
        name: 'Ikwuano',
        id: 2009,
      },
      {
        name: 'Isiala Ngwa North',
        id: 2010,
      },
      {
        name: 'Isiala Ngwa South',
        id: 2011,
      },
      {
        name: 'Isuikwuato',
        id: 2012,
      },
      {
        name: 'Obi Ngwa',
        id: 2013,
      },
      {
        name: 'Ohafia',
        id: 2014,
      },
      {
        name: 'Osisioma',
        id: 2015,
      },
      {
        name: 'Ugwunagbo',
        id: 2016,
      },
      {
        name: 'Ukwa East',
        id: 2017,
      },
      {
        name: 'Ukwa West',
        id: 2018,
      },
      {
        name: 'Umuahia North',
        id: 2019,
      },
      {
        name: 'Umuahia South',
        id: 2020,
      },
      {
        name: 'Umu Nneochi',
        id: 2021,
      },
    ],
  },
  {
    state: 'Adamawa',
    id: 2,
    locals: [
      {
        name: 'Fufure',
        id: 2022,
      },
      {
        name: 'Fufure',
        id: 2023,
      },
      {
        name: 'Ganye',
        id: 2024,
      },
      {
        name: 'Gayuk',
        id: 2025,
      },
      {
        name: 'Gombi',
        id: 2026,
      },
      {
        name: 'Grie',
        id: 2027,
      },
      {
        name: 'Hong',
        id: 2028,
      },
      {
        name: 'Jada',
        id: 2029,
      },
      {
        name: 'Lamurde',
        id: 2030,
      },
      {
        name: 'Madagali',
        id: 2031,
      },
      {
        name: 'Maiha',
        id: 2032,
      },
      {
        name: 'Mayo Belwa',
        id: 2033,
      },
      {
        name: 'Michika',
        id: 2034,
      },
      {
        name: 'Mubi North',
        id: 2035,
      },
      {
        name: 'Mubi South',
        id: 2036,
      },
      {
        name: 'Numan',
        id: 2037,
      },
      {
        name: 'Shelleng',
        id: 2038,
      },
      {
        name: 'Song',
        id: 2039,
      },
      {
        name: 'Toungo',
        id: 2040,
      },
      {
        name: 'Yola North',
        id: 2041,
      },
      {
        name: 'Yola South',
        id: 2042,
      },
    ],
  },
  {
    state: 'Akwa Ibom',
    id: 3,
    locals: [
      {
        name: 'Abak',
        id: 2043,
      },
      {
        name: 'Eastern Obolo',
        id: 2044,
      },
      {
        name: 'Eket',
        id: 2045,
      },
      {
        name: 'Esit Eket',
        id: 2046,
      },
      {
        name: 'Essien Udim',
        id: 2047,
      },
      {
        name: 'Etim Ekpo',
        id: 2048,
      },
      {
        name: 'Etinan',
        id: 2049,
      },
      {
        name: 'Ibeno',
        id: 2050,
      },
      {
        name: 'Ibesikpo Asutan',
        id: 2051,
      },
      {
        name: 'Ibiono-Ibom',
        id: 2052,
      },
      {
        name: 'Ika',
        id: 2053,
      },
      {
        name: 'Ikono',
        id: 2054,
      },
      {
        name: 'Ikot Abasi',
        id: 2055,
      },
      {
        name: 'Ikot Ekpene',
        id: 2056,
      },
      {
        name: 'Ini',
        id: 2057,
      },
      {
        name: 'Itu',
        id: 2058,
      },
      {
        name: 'Mbo',
        id: 2059,
      },
      {
        name: 'Mkpat-Enin',
        id: 2060,
      },
      {
        name: 'Nsit-Atai',
        id: 2061,
      },
      {
        name: 'Nsit-Ibom',
        id: 2062,
      },
      {
        name: 'Nsit-Ubium',
        id: 2063,
      },
      {
        name: 'Obot Akara',
        id: 2064,
      },
      {
        name: 'Okobo',
        id: 2065,
      },
      {
        name: 'Onna',
        id: 2066,
      },
      {
        name: 'Oron',
        id: 2067,
      },
      {
        name: 'Oruk Anam',
        id: 2068,
      },
      {
        name: 'Udung-Uko',
        id: 2069,
      },
      {
        name: 'Ukanafun',
        id: 2070,
      },
      {
        name: 'Uruan',
        id: 2071,
      },
      {
        name: 'Urue-Offong/Oruko',
        id: 2072,
      },
      {
        name: 'Uyo',
        id: 2073,
      },
    ],
  },
  {
    state: 'Anambra',
    id: 4,
    locals: [
      {
        name: 'Aguata',
        id: 2074,
      },
      {
        name: 'Anambra East',
        id: 2075,
      },
      {
        name: 'Anambra West',
        id: 2076,
      },
      {
        name: 'Anaocha',
        id: 2077,
      },
      {
        name: 'Awka North',
        id: 2078,
      },
      {
        name: 'Awka South',
        id: 2079,
      },
      {
        name: 'Ayamelum',
        id: 2080,
      },
      {
        name: 'Dunukofia',
        id: 2081,
      },
      {
        name: 'Ekwusigo',
        id: 2082,
      },
      {
        name: 'Idemili North',
        id: 2083,
      },
      {
        name: 'Idemili South',
        id: 2084,
      },
      {
        name: 'Ihiala',
        id: 2085,
      },
      {
        name: 'Njikoka',
        id: 2086,
      },
      {
        name: 'Nnewi North',
        id: 2087,
      },
      {
        name: 'Nnewi South',
        id: 2088,
      },
      {
        name: 'Ogbaru',
        id: 2089,
      },
      {
        name: 'Onitsha North',
        id: 2090,
      },
      {
        name: 'Onitsha South',
        id: 2091,
      },
      {
        name: 'Orumba North',
        id: 2092,
      },
      {
        name: 'Orumba South',
        id: 2093,
      },
      {
        name: 'Oyi',
        id: 2094,
      },
    ],
  },
  {
    state: 'Bauchi',
    id: 5,
    locals: [
      {
        name: 'Alkaleri',
        id: 2095,
      },
      {
        name: 'Bauchi',
        id: 2096,
      },
      {
        name: 'Bogoro',
        id: 2097,
      },
      {
        name: 'Damban',
        id: 2098,
      },
      {
        name: 'Darazo',
        id: 2099,
      },
      {
        name: 'Dass',
        id: 2100,
      },
      {
        name: 'Gamawa',
        id: 2101,
      },
      {
        name: 'Ganjuwa',
        id: 2102,
      },
      {
        name: 'Giade',
        id: 2103,
      },
      {
        name: 'Itas/Gadau',
        id: 2104,
      },
      {
        name: "Jama'are",
        id: 2105,
      },
      {
        name: 'Katagum',
        id: 2106,
      },
      {
        name: 'Kirfi',
        id: 2107,
      },
      {
        name: 'Misau',
        id: 2108,
      },
      {
        name: 'Ningi',
        id: 2109,
      },
      {
        name: 'Shira',
        id: 2110,
      },
      {
        name: 'Tafawa Balewa',
        id: 2111,
      },
      {
        name: 'Toro',
        id: 2112,
      },
      {
        name: 'Warji',
        id: 2113,
      },
      {
        name: 'Zaki',
        id: 2114,
      },
    ],
  },
  {
    state: 'Bayelsa',
    id: 6,
    locals: [
      {
        name: 'Brass',
        id: 2115,
      },
      {
        name: 'Ekeremor',
        id: 2116,
      },
      {
        name: 'Kolokuma/Opokuma',
        id: 2117,
      },
      {
        name: 'Nembe',
        id: 2118,
      },
      {
        name: 'Ogbia',
        id: 2119,
      },
      {
        name: 'Sagbama',
        id: 2120,
      },
      {
        name: 'Southern Ijaw',
        id: 2121,
      },
      {
        name: 'Yenagoa',
        id: 2122,
      },
    ],
  },
  {
    state: 'Benue',
    id: 7,
    locals: [
      {
        name: 'Agatu',
        id: 2123,
      },
      {
        name: 'Apa',
        id: 2124,
      },
      {
        name: 'Ado',
        id: 2125,
      },
      {
        name: 'Buruku',
        id: 2126,
      },
      {
        name: 'Gboko',
        id: 2127,
      },
      {
        name: 'Guma',
        id: 2128,
      },
      {
        name: 'Gwer East',
        id: 2129,
      },
      {
        name: 'Gwer West',
        id: 2130,
      },
      {
        name: 'Katsina-Ala',
        id: 2131,
      },
      {
        name: 'Konshisha',
        id: 2132,
      },
      {
        name: 'Kwande',
        id: 2133,
      },
      {
        name: 'Logo',
        id: 2134,
      },
      {
        name: 'Makurdi',
        id: 2135,
      },
      {
        name: 'Obi',
        id: 2136,
      },
      {
        name: 'Ogbadibo',
        id: 2137,
      },
      {
        name: 'Ohimini',
        id: 2138,
      },
      {
        name: 'Oju',
        id: 2139,
      },
      {
        name: 'Okpokwu',
        id: 2140,
      },
      {
        name: 'Oturkpo',
        id: 2141,
      },
      {
        name: 'Tarka',
        id: 2142,
      },
      {
        name: 'Ukum',
        id: 2143,
      },
      {
        name: 'Ushongo',
        id: 2144,
      },
      {
        name: 'Vandeikya',
        id: 2145,
      },
    ],
  },
  {
    state: 'Borno',
    id: 8,
    locals: [
      {
        name: 'Abadam',
        id: 2146,
      },
      {
        name: 'Askira/Uba',
        id: 2147,
      },
      {
        name: 'Bama',
        id: 2148,
      },
      {
        name: 'Bayo',
        id: 2149,
      },
      {
        name: 'Biu',
        id: 2150,
      },
      {
        name: 'Chibok',
        id: 2151,
      },
      {
        name: 'Damboa',
        id: 2152,
      },
      {
        name: 'Dikwa',
        id: 2153,
      },
      {
        name: 'Gubio',
        id: 2154,
      },
      {
        name: 'Guzamala',
        id: 2155,
      },
      {
        name: 'Gwoza',
        id: 2156,
      },
      {
        name: 'Hawul',
        id: 2157,
      },
      {
        name: 'Jere',
        id: 2158,
      },
      {
        name: 'Kaga',
        id: 2159,
      },
      {
        name: 'Kala/Balge',
        id: 2160,
      },
      {
        name: 'Konduga',
        id: 2161,
      },
      {
        name: 'Kukawa',
        id: 2162,
      },
      {
        name: 'Kwaya Kusar',
        id: 2163,
      },
      {
        name: 'Mafa',
        id: 2164,
      },
      {
        name: 'Magumeri',
        id: 2165,
      },
      {
        name: 'Maiduguri',
        id: 2166,
      },
      {
        name: 'Marte',
        id: 2167,
      },
      {
        name: 'Mobbar',
        id: 2168,
      },
      {
        name: 'Monguno',
        id: 2169,
      },
      {
        name: 'Ngala',
        id: 2170,
      },
      {
        name: 'Nganzai',
        id: 2171,
      },
      {
        name: 'Shani',
        id: 2172,
      },
    ],
  },
  {
    state: 'Cross River',
    id: 9,
    locals: [
      {
        name: 'Abi',
        id: 2173,
      },
      {
        name: 'Akamkpa',
        id: 2174,
      },
      {
        name: 'Akpabuyo',
        id: 2175,
      },
      {
        name: 'Bakassi',
        id: 2176,
      },
      {
        name: 'Bekwarra',
        id: 2177,
      },
      {
        name: 'Biase',
        id: 2178,
      },
      {
        name: 'Boki',
        id: 2179,
      },
      {
        name: 'Calabar Municipal',
        id: 2180,
      },
      {
        name: 'Calabar South',
        id: 2181,
      },
      {
        name: 'Etung',
        id: 2182,
      },
      {
        name: 'Ikom',
        id: 2183,
      },
      {
        name: 'Obanliku',
        id: 2184,
      },
      {
        name: 'Obubra',
        id: 2185,
      },
      {
        name: 'Obudu',
        id: 2186,
      },
      {
        name: 'Odukpani',
        id: 2187,
      },
      {
        name: 'Ogoja',
        id: 2188,
      },
      {
        name: 'Yakuur',
        id: 2189,
      },
      {
        name: 'Yala',
        id: 2190,
      },
    ],
  },
  {
    state: 'Delta',
    id: 10,
    locals: [
      {
        name: 'Aniocha North',
        id: 2191,
      },
      {
        name: 'Aniocha South',
        id: 2192,
      },
      {
        name: 'Bomadi',
        id: 2193,
      },
      {
        name: 'Burutu',
        id: 2194,
      },
      {
        name: 'Ethiope East',
        id: 2195,
      },
      {
        name: 'Ethiope West',
        id: 2196,
      },
      {
        name: 'Ika North East',
        id: 2197,
      },
      {
        name: 'Ika South',
        id: 2198,
      },
      {
        name: 'Isoko North',
        id: 2199,
      },
      {
        name: 'Isoko South',
        id: 2200,
      },
      {
        name: 'Ndokwa East',
        id: 2201,
      },
      {
        name: 'Ndokwa West',
        id: 2202,
      },
      {
        name: 'Okpe',
        id: 2203,
      },
      {
        name: 'Oshimili North',
        id: 2204,
      },
      {
        name: 'Oshimili South',
        id: 2205,
      },
      {
        name: 'Patani',
        id: 2206,
      },
      {
        name: 'Sapele',
        id: 2207,
      },
      {
        name: 'Udu',
        id: 2208,
      },
      {
        name: 'Ughelli North',
        id: 2209,
      },
      {
        name: 'Ughelli South',
        id: 2210,
      },
      {
        name: 'Ukwuani',
        id: 2211,
      },
      {
        name: 'Uvwie',
        id: 2212,
      },
      {
        name: 'Warri North',
        id: 2213,
      },
      {
        name: 'Warri South',
        id: 2214,
      },
      {
        name: 'Warri South West',
        id: 2215,
      },
    ],
  },
  {
    state: 'Ebonyi',
    id: 11,
    locals: [
      {
        name: 'Afikpo North',
        id: 2216,
      },
      {
        name: 'Abakaliki',
        id: 2217,
      },
      {
        name: 'Afikpo South',
        id: 2218,
      },
      {
        name: 'Ebonyi',
        id: 2219,
      },
      {
        name: 'Ezza North',
        id: 2220,
      },
      {
        name: 'Ezza South',
        id: 2221,
      },
      {
        name: 'Ikwo',
        id: 2222,
      },
      {
        name: 'Ishielu',
        id: 2223,
      },
      {
        name: 'Ivo',
        id: 2224,
      },
      {
        name: 'Izzi',
        id: 2225,
      },
      {
        name: 'Ohaozara',
        id: 2226,
      },
      {
        name: 'Ohaukwu',
        id: 2227,
      },
      {
        name: 'Onicha',
        id: 2228,
      },
    ],
  },
  {
    state: 'Edo',
    id: 12,
    locals: [
      {
        name: 'Akoko Edo',
        id: 2229,
      },
      {
        name: 'Egor',
        id: 2230,
      },
      {
        name: 'Esan Central',
        id: 2231,
      },
      {
        name: 'Esan North-East',
        id: 2232,
      },
      {
        name: 'Esan South-East',
        id: 2233,
      },
      {
        name: 'Esan West',
        id: 2234,
      },
      {
        name: 'Etsako Central',
        id: 2235,
      },
      {
        name: 'Etsako East',
        id: 2236,
      },
      {
        name: 'Etsako West',
        id: 2237,
      },
      {
        name: 'Igueben',
        id: 2238,
      },
      {
        name: 'Ikpoba Okha',
        id: 2239,
      },
      {
        name: 'Orhionmwon',
        id: 2240,
      },
      {
        name: 'Oredo',
        id: 2241,
      },
      {
        name: 'Ovia North-East',
        id: 2242,
      },
      {
        name: 'Ovia South-West',
        id: 2243,
      },
      {
        name: 'Owan East',
        id: 2244,
      },
      {
        name: 'Owan West',
        id: 2245,
      },
      {
        name: 'Uhunmwonde',
        id: 2246,
      },
    ],
  },
  {
    state: 'Ekiti',
    id: 13,
    locals: [
      {
        name: 'Ado Ekiti',
        id: 2247,
      },
      {
        name: 'Efon',
        id: 2248,
      },
      {
        name: 'Ekiti East',
        id: 2249,
      },
      {
        name: 'Ekiti South-West',
        id: 2250,
      },
      {
        name: 'Ekiti West',
        id: 2251,
      },
      {
        name: 'Emure',
        id: 2252,
      },
      {
        name: 'Gbonyin',
        id: 2253,
      },
      {
        name: 'Ido Osi',
        id: 2254,
      },
      {
        name: 'Ijero',
        id: 2255,
      },
      {
        name: 'Ikere',
        id: 2256,
      },
      {
        name: 'Ikole',
        id: 2257,
      },
      {
        name: 'Ilejemeje',
        id: 2258,
      },
      {
        name: 'Irepodun/Ifelodun',
        id: 2259,
      },
      {
        name: 'Ise/Orun',
        id: 2260,
      },
      {
        name: 'Moba',
        id: 2261,
      },
      {
        name: 'Oye',
        id: 2262,
      },
    ],
  },
  {
    state: 'Enugu',
    id: 14,
    locals: [
      {
        name: 'Aninri',
        id: 2263,
      },
      {
        name: 'Awgu',
        id: 2264,
      },
      {
        name: 'Enugu East',
        id: 2265,
      },
      {
        name: 'Enugu North',
        id: 2266,
      },
      {
        name: 'Enugu South',
        id: 2267,
      },
      {
        name: 'Ezeagu',
        id: 2268,
      },
      {
        name: 'Igbo Etiti',
        id: 2269,
      },
      {
        name: 'Igbo Eze North',
        id: 2270,
      },
      {
        name: 'Igbo Eze South',
        id: 2271,
      },
      {
        name: 'Isi Uzo',
        id: 2272,
      },
      {
        name: 'Nkanu East',
        id: 2273,
      },
      {
        name: 'Nkanu West',
        id: 2274,
      },
      {
        name: 'Nsukka',
        id: 2275,
      },
      {
        name: 'Oji River',
        id: 2276,
      },
      {
        name: 'Udenu',
        id: 2277,
      },
      {
        name: 'Udi',
        id: 2278,
      },
      {
        name: 'Uzo Uwani',
        id: 2279,
      },
    ],
  },
  {
    state: 'F.C.T',
    id: 15,
    locals: [
      {
        name: 'AMAC',
        id: 2280,
      },
      {
        name: 'Bwari',
        id: 2281,
      },
      {
        name: 'Gwagwalada',
        id: 2282,
      },
      {
        name: 'Kuje',
        id: 2283,
      },
      {
        name: 'Kwali',
        id: 2284,
      },
      {
        name: 'Abaji',
        id: 2285,
      },
    ],
  },
  {
    state: 'Gombe',
    id: 16,
    locals: [
      {
        name: 'Akko',
        id: 2286,
      },
      {
        name: 'Balanga',
        id: 2287,
      },
      {
        name: 'Billiri',
        id: 2288,
      },
      {
        name: 'Dukku',
        id: 2289,
      },
      {
        name: 'Funakaye',
        id: 2290,
      },
      {
        name: 'Gombe',
        id: 2291,
      },
      {
        name: 'Kaltungo',
        id: 2292,
      },
      {
        name: 'Kwami',
        id: 2293,
      },
      {
        name: 'Nafada',
        id: 2294,
      },
      {
        name: 'Shongom',
        id: 2295,
      },
      {
        name: 'Yamaltu/Deba',
        id: 2296,
      },
    ],
  },
  {
    state: 'Imo',
    id: 17,
    locals: [
      {
        name: 'Aboh Mbaise',
        id: 2297,
      },
      {
        name: 'Ahiazu Mbaise',
        id: 2298,
      },
      {
        name: 'Ehime Mbano',
        id: 2299,
      },
      {
        name: 'Ezinihitte',
        id: 2300,
      },
      {
        name: 'Ideato North',
        id: 2301,
      },
      {
        name: 'Ideato South',
        id: 2302,
      },
      {
        name: 'Ihitte/Uboma',
        id: 2303,
      },
      {
        name: 'Ikeduru',
        id: 2304,
      },
      {
        name: 'Isiala Mbano',
        id: 2305,
      },
      {
        name: 'Isu',
        id: 2306,
      },
      {
        name: 'Mbaitoli',
        id: 2307,
      },
      {
        name: 'Ngor Okpala',
        id: 2308,
      },
      {
        name: 'Njaba',
        id: 2309,
      },
      {
        name: 'Nkwerre',
        id: 2310,
      },
      {
        name: 'Nwangele',
        id: 2311,
      },
      {
        name: 'Obowo',
        id: 2312,
      },
      {
        name: 'Oguta',
        id: 2313,
      },
      {
        name: 'Ohaji/Egbema',
        id: 2314,
      },
      {
        name: 'Okigwe',
        id: 2315,
      },
      {
        name: 'Orlu',
        id: 2316,
      },
      {
        name: 'Orsu',
        id: 2317,
      },
      {
        name: 'Oru East',
        id: 2318,
      },
      {
        name: 'Oru West',
        id: 2319,
      },
      {
        name: 'Owerri Municipal',
        id: 2320,
      },
      {
        name: 'Owerri North',
        id: 2321,
      },
      {
        name: 'Owerri West',
        id: 2322,
      },
      {
        name: 'Unuimo',
        id: 2323,
      },
    ],
  },
  {
    state: 'Jigawa',
    id: 18,
    locals: [
      {
        name: 'Auyo',
        id: 2324,
      },
      {
        name: 'Babura',
        id: 2325,
      },
      {
        name: 'Biriniwa',
        id: 2326,
      },
      {
        name: 'Birnin Kudu',
        id: 2327,
      },
      {
        name: 'Buji',
        id: 2328,
      },
      {
        name: 'Dutse',
        id: 2329,
      },
      {
        name: 'Gagarawa',
        id: 2330,
      },
      {
        name: 'Garki',
        id: 2331,
      },
      {
        name: 'Gumel',
        id: 2332,
      },
      {
        name: 'Guri',
        id: 2333,
      },
      {
        name: 'Gwaram',
        id: 2334,
      },
      {
        name: 'Gwiwa',
        id: 2335,
      },
      {
        name: 'Hadejia',
        id: 2336,
      },
      {
        name: 'Jahun',
        id: 2337,
      },
      {
        name: 'Kafin Hausa',
        id: 2338,
      },
      {
        name: 'Kazaure',
        id: 2339,
      },
      {
        name: 'Kiri Kasama',
        id: 2340,
      },
      {
        name: 'Kiyawa',
        id: 2341,
      },
      {
        name: 'Kaugama',
        id: 2342,
      },
      {
        name: 'Maigatari',
        id: 2343,
      },
      {
        name: 'Malam Madori',
        id: 2344,
      },
      {
        name: 'Miga',
        id: 2345,
      },
      {
        name: 'Ringim',
        id: 2346,
      },
      {
        name: 'Roni',
        id: 2347,
      },
      {
        name: 'Sule Tankarkar',
        id: 2348,
      },
      {
        name: 'Taura',
        id: 2349,
      },
      {
        name: 'Yankwashi',
        id: 2350,
      },
    ],
  },
  {
    state: 'Kaduna',
    id: 19,
    locals: [
      {
        name: 'Birnin Gwari',
        id: 2351,
      },
      {
        name: 'Chikun',
        id: 2352,
      },
      {
        name: 'Giwa',
        id: 2353,
      },
      {
        name: 'Igabi',
        id: 2354,
      },
      {
        name: 'Ikara',
        id: 2355,
      },
      {
        name: 'Jaba',
        id: 2356,
      },
      {
        name: "Jema'a",
        id: 2357,
      },
      {
        name: 'Kachia',
        id: 2358,
      },
      {
        name: 'Kaduna North',
        id: 2359,
      },
      {
        name: 'Kaduna South',
        id: 2360,
      },
      {
        name: 'Kagarko',
        id: 2361,
      },
      {
        name: 'Kajuru',
        id: 2362,
      },
      {
        name: 'Kaura',
        id: 2363,
      },
      {
        name: 'Kauru',
        id: 2364,
      },
      {
        name: 'Kubau',
        id: 2365,
      },
      {
        name: 'Kudan',
        id: 2366,
      },
      {
        name: 'Lere',
        id: 2367,
      },
      {
        name: 'Makarfi',
        id: 2368,
      },
      {
        name: 'Sabon Gari',
        id: 2369,
      },
      {
        name: 'Sanga',
        id: 2370,
      },
      {
        name: 'Soba',
        id: 2371,
      },
      {
        name: 'Zangon Kataf',
        id: 2372,
      },
      {
        name: 'Zaria',
        id: 2373,
      },
    ],
  },
  {
    state: 'Kano',
    id: 20,
    locals: [
      {
        name: 'Ajingi',
        id: 2374,
      },
      {
        name: 'Albasu',
        id: 2375,
      },
      {
        name: 'Bagwai',
        id: 2376,
      },
      {
        name: 'Bebeji',
        id: 2377,
      },
      {
        name: 'Bichi',
        id: 2378,
      },
      {
        name: 'Bunkure',
        id: 2379,
      },
      {
        name: 'Dala',
        id: 2380,
      },
      {
        name: 'Dambatta',
        id: 2381,
      },
      {
        name: 'Dawakin Kudu',
        id: 2382,
      },
      {
        name: 'Dawakin Tofa',
        id: 2383,
      },
      {
        name: 'Doguwa',
        id: 2384,
      },
      {
        name: 'Fagge',
        id: 2385,
      },
      {
        name: 'Gabasawa',
        id: 2386,
      },
      {
        name: 'Garko',
        id: 2387,
      },
      {
        name: 'Garun Mallam',
        id: 2388,
      },
      {
        name: 'Gaya',
        id: 2389,
      },
      {
        name: 'Gezawa',
        id: 2390,
      },
      {
        name: 'Gwale',
        id: 2391,
      },
      {
        name: 'Gwarzo',
        id: 2392,
      },
      {
        name: 'Kabo',
        id: 2393,
      },
      {
        name: 'Kano Municipal',
        id: 2394,
      },
      {
        name: 'Karaye',
        id: 2395,
      },
      {
        name: 'Kibiya',
        id: 2396,
      },
      {
        name: 'Kiru',
        id: 2397,
      },
      {
        name: 'Kumbotso',
        id: 2398,
      },
      {
        name: 'Kunchi',
        id: 2399,
      },
      {
        name: 'Kura',
        id: 2400,
      },
      {
        name: 'Madobi',
        id: 2401,
      },
      {
        name: 'Makoda',
        id: 2402,
      },
      {
        name: 'Minjibir',
        id: 2403,
      },
      {
        name: 'Nasarawa',
        id: 2404,
      },
      {
        name: 'Rano',
        id: 2405,
      },
      {
        name: 'Rimin Gado',
        id: 2406,
      },
      {
        name: 'Rogo',
        id: 2407,
      },
      {
        name: 'Shanono',
        id: 2408,
      },
      {
        name: 'Sumaila',
        id: 2409,
      },
      {
        name: 'Takai',
        id: 2410,
      },
      {
        name: 'Tarauni',
        id: 2411,
      },
      {
        name: 'Tofa',
        id: 2412,
      },
      {
        name: 'Tsanyawa',
        id: 2413,
      },
      {
        name: 'Tudun Wada',
        id: 2414,
      },
      {
        name: 'Ungogo',
        id: 2415,
      },
      {
        name: 'Warawa',
        id: 2416,
      },
      {
        name: 'Wudil',
        id: 2417,
      },
    ],
  },
  {
    state: 'Katsina',
    id: 21,
    locals: [
      {
        name: 'Bakori',
        id: 2418,
      },
      {
        name: 'Batagarawa',
        id: 2419,
      },
      {
        name: 'Batsari',
        id: 2420,
      },
      {
        name: 'Baure',
        id: 2421,
      },
      {
        name: 'Bindawa',
        id: 2422,
      },
      {
        name: 'Charanchi',
        id: 2423,
      },
      {
        name: 'Dandume',
        id: 2424,
      },
      {
        name: 'Danja',
        id: 2425,
      },
      {
        name: 'Dan Musa',
        id: 2426,
      },
      {
        name: 'Daura',
        id: 2427,
      },
      {
        name: 'Dutsi',
        id: 2428,
      },
      {
        name: 'Dutsin Ma',
        id: 2429,
      },
      {
        name: 'Faskari',
        id: 2430,
      },
      {
        name: 'Funtua',
        id: 2431,
      },
      {
        name: 'Ingawa',
        id: 2432,
      },
      {
        name: 'Jibia',
        id: 2433,
      },
      {
        name: 'Kafur',
        id: 2434,
      },
      {
        name: 'Kaita',
        id: 2435,
      },
      {
        name: 'Kankara',
        id: 2436,
      },
      {
        name: 'Kankia',
        id: 2437,
      },
      {
        name: 'Katsina',
        id: 2438,
      },
      {
        name: 'Kurfi',
        id: 2439,
      },
      {
        name: 'Kusada',
        id: 2440,
      },
      {
        name: "Mai'Adua",
        id: 2441,
      },
      {
        name: 'Malumfashi',
        id: 2442,
      },
      {
        name: 'Mani',
        id: 2443,
      },
      {
        name: 'Mashi',
        id: 2444,
      },
      {
        name: 'Matazu',
        id: 2445,
      },
      {
        name: 'Musawa',
        id: 2446,
      },
      {
        name: 'Rimi',
        id: 2447,
      },
      {
        name: 'Sabuwa',
        id: 2448,
      },
      {
        name: 'Safana',
        id: 2449,
      },
      {
        name: 'Sandamu',
        id: 2450,
      },
      {
        name: 'Zango',
        id: 2451,
      },
    ],
  },
  {
    state: 'Kebbi',
    id: 22,
    locals: [
      {
        name: 'Aleiro',
        id: 2452,
      },
      {
        name: 'Arewa Dandi',
        id: 2453,
      },
      {
        name: 'Argungu',
        id: 2454,
      },
      {
        name: 'Augie',
        id: 2455,
      },
      {
        name: 'Bagudo',
        id: 2456,
      },
      {
        name: 'Birnin Kebbi',
        id: 2457,
      },
      {
        name: 'Bunza',
        id: 2458,
      },
      {
        name: 'Dandi',
        id: 2459,
      },
      {
        name: 'Fakai',
        id: 2460,
      },
      {
        name: 'Gwandu',
        id: 2461,
      },
      {
        name: 'Jega',
        id: 2462,
      },
      {
        name: 'Kalgo',
        id: 2463,
      },
      {
        name: 'Koko/Besse',
        id: 2464,
      },
      {
        name: 'Maiyama',
        id: 2465,
      },
      {
        name: 'Ngaski',
        id: 2466,
      },
      {
        name: 'Sakaba',
        id: 2467,
      },
      {
        name: 'Shanga',
        id: 2468,
      },
      {
        name: 'Suru',
        id: 2469,
      },
      {
        name: 'Wasagu/Danko',
        id: 2470,
      },
      {
        name: 'Yauri',
        id: 2471,
      },
      {
        name: 'Zuru',
        id: 2472,
      },
    ],
  },
  {
    state: 'Kogi',
    id: 23,
    locals: [
      {
        name: 'Adavi',
        id: 2473,
      },
      {
        name: 'Ajaokuta',
        id: 2474,
      },
      {
        name: 'Ankpa',
        id: 2475,
      },
      {
        name: 'Bassa',
        id: 2476,
      },
      {
        name: 'Dekina',
        id: 2477,
      },
      {
        name: 'Ibaji',
        id: 2478,
      },
      {
        name: 'Idah',
        id: 2479,
      },
      {
        name: 'Igalamela Odolu',
        id: 2480,
      },
      {
        name: 'Ijumu',
        id: 2481,
      },
      {
        name: 'Kabba/Bunu',
        id: 2482,
      },
      {
        name: 'Kogi',
        id: 2483,
      },
      {
        name: 'Lokoja',
        id: 2484,
      },
      {
        name: 'Mopa Muro',
        id: 2485,
      },
      {
        name: 'Ofu',
        id: 2486,
      },
      {
        name: 'Ogori/Magongo',
        id: 2487,
      },
      {
        name: 'Okehi',
        id: 2488,
      },
      {
        name: 'Okene',
        id: 2489,
      },
      {
        name: 'Olamaboro',
        id: 2490,
      },
      {
        name: 'Omala',
        id: 2491,
      },
      {
        name: 'Yagba East',
        id: 2492,
      },
      {
        name: 'Yagba West',
        id: 2493,
      },
    ],
  },
  {
    state: 'Kwara',
    id: 24,
    locals: [
      {
        name: 'Asa',
        id: 2494,
      },
      {
        name: 'Baruten',
        id: 2495,
      },
      {
        name: 'Edu',
        id: 2496,
      },
      {
        name: 'Ekiti',
        id: 2497,
      },
      {
        name: 'Ifelodun',
        id: 2498,
      },
      {
        name: 'Ilorin East',
        id: 2499,
      },
      {
        name: 'Ilorin South',
        id: 2500,
      },
      {
        name: 'Ilorin West',
        id: 2501,
      },
      {
        name: 'Irepodun',
        id: 2502,
      },
      {
        name: 'Isin',
        id: 2503,
      },
      {
        name: 'Kaiama',
        id: 2504,
      },
      {
        name: 'Moro',
        id: 2505,
      },
      {
        name: 'Offa',
        id: 2506,
      },
      {
        name: 'Oke Ero',
        id: 2507,
      },
      {
        name: 'Oyun',
        id: 2508,
      },
      {
        name: 'Pategi',
        id: 2509,
      },
    ],
  },
  {
    state: 'Lagos',
    id: 25,
    locals: [
      {
        name: 'Agege',
        id: 2510,
      },
      {
        name: 'Ajeromi-Ifelodun',
        id: 2511,
      },
      {
        name: 'Alimosho',
        id: 2512,
      },
      {
        name: 'Amuwo-Odofin',
        id: 2513,
      },
      {
        name: 'Apapa',
        id: 2514,
      },
      {
        name: 'Badagry',
        id: 2515,
      },
      {
        name: 'Epe',
        id: 2516,
      },
      {
        name: 'Eti Osa',
        id: 2517,
      },
      {
        name: 'Ibeju-Lekki',
        id: 2518,
      },
      {
        name: 'Ifako-Ijaiye',
        id: 2519,
      },
      {
        name: 'Ikeja',
        id: 2520,
      },
      {
        name: 'Ikorodu',
        id: 2521,
      },
      {
        name: 'Kosofe',
        id: 2522,
      },
      {
        name: 'Lagos Island',
        id: 2523,
      },
      {
        name: 'Lagos Mainland',
        id: 2524,
      },
      {
        name: 'Mushin',
        id: 2525,
      },
      {
        name: 'Ojo',
        id: 2526,
      },
      {
        name: 'Oshodi-Isolo',
        id: 2527,
      },
      {
        name: 'Shomolu',
        id: 2528,
      },
      {
        name: 'Surulere',
        id: 2529,
      },
    ],
  },
  {
    state: 'Nasarawa',
    id: 26,
    locals: [
      {
        name: 'Awanga',
        id: 2530,
      },
      {
        name: 'Awe',
        id: 2531,
      },
      {
        name: 'Doma',
        id: 2532,
      },
      {
        name: 'Karu',
        id: 2533,
      },
      {
        name: 'Keana',
        id: 2534,
      },
      {
        name: 'Keffi',
        id: 2535,
      },
      {
        name: 'Kokona',
        id: 2536,
      },
      {
        name: 'Lafia',
        id: 2537,
      },
      {
        name: 'Nasarawa',
        id: 2538,
      },
      {
        name: 'Nasarawa Egon',
        id: 2539,
      },
      {
        name: 'Obi',
        id: 2540,
      },
      {
        name: 'Toto',
        id: 2541,
      },
      {
        name: 'Wamba',
        id: 2542,
      },
    ],
  },
  {
    state: 'Niger',
    id: 27,
    locals: [
      {
        name: 'Agaie',
        id: 2543,
      },
      {
        name: 'Agwara',
        id: 2544,
      },
      {
        name: 'Bida',
        id: 2545,
      },
      {
        name: 'Borgu',
        id: 2546,
      },
      {
        name: 'Bosso',
        id: 2547,
      },
      {
        name: 'Chanchaga',
        id: 2548,
      },
      {
        name: 'Edati',
        id: 2549,
      },
      {
        name: 'Gbako',
        id: 2550,
      },
      {
        name: 'Gurara',
        id: 2551,
      },
      {
        name: 'Katcha',
        id: 2552,
      },
      {
        name: 'Kontagora',
        id: 2553,
      },
      {
        name: 'Lapai',
        id: 2554,
      },
      {
        name: 'Lavun',
        id: 2555,
      },
      {
        name: 'Magama',
        id: 2556,
      },
      {
        name: 'Mariga',
        id: 2557,
      },
      {
        name: 'Mashegu',
        id: 2558,
      },
      {
        name: 'Mokwa',
        id: 2559,
      },
      {
        name: 'Moya',
        id: 2560,
      },
      {
        name: 'Paikoro',
        id: 2561,
      },
      {
        name: 'Rafi',
        id: 2562,
      },
      {
        name: 'Rijau',
        id: 2563,
      },
      {
        name: 'Shiroro',
        id: 2564,
      },
      {
        name: 'Suleja',
        id: 2565,
      },
      {
        name: 'Tafa',
        id: 2566,
      },
      {
        name: 'Wushishi',
        id: 2567,
      },
    ],
  },
  {
    state: 'Ogun',
    id: 28,
    locals: [
      {
        name: 'Abeokuta North',
        id: 2568,
      },
      {
        name: 'Abeokuta South',
        id: 2569,
      },
      {
        name: 'Ado-Odo/Ota',
        id: 2570,
      },
      {
        name: 'Egbado North',
        id: 2571,
      },
      {
        name: 'Egbado South',
        id: 2572,
      },
      {
        name: 'Ewekoro',
        id: 2573,
      },
      {
        name: 'Ifo',
        id: 2574,
      },
      {
        name: 'Ijebu East',
        id: 2575,
      },
      {
        name: 'Ijebu North',
        id: 2576,
      },
      {
        name: 'Ijebu North East',
        id: 2577,
      },
      {
        name: 'Ijebu Ode',
        id: 2578,
      },
      {
        name: 'Ikenne',
        id: 2579,
      },
      {
        name: 'Imeko Afon',
        id: 2580,
      },
      {
        name: 'Ipokia',
        id: 2581,
      },
      {
        name: 'Obafemi Owode',
        id: 2582,
      },
      {
        name: 'Odeda',
        id: 2583,
      },
      {
        name: 'Odogbolu',
        id: 2584,
      },
      {
        name: 'Ogun Waterside',
        id: 2585,
      },
      {
        name: 'Remo North',
        id: 2586,
      },
      {
        name: 'Shagamu',
        id: 2587,
      },
    ],
  },
  {
    state: 'Ondo',
    id: 29,
    locals: [
      {
        name: 'Akoko North-East',
        id: 2588,
      },
      {
        name: 'Akoko North-West',
        id: 2589,
      },
      {
        name: 'Akoko South-West',
        id: 2590,
      },
      {
        name: 'Akoko South-East',
        id: 2591,
      },
      {
        name: 'Akure North',
        id: 2592,
      },
      {
        name: 'Akure South',
        id: 2593,
      },
      {
        name: 'Ese Odo',
        id: 2594,
      },
      {
        name: 'Idanre',
        id: 2595,
      },
      {
        name: 'Ifedore',
        id: 2596,
      },
      {
        name: 'Ilaje',
        id: 2597,
      },
      {
        name: 'Ile Oluji/Okeigbo',
        id: 2598,
      },
      {
        name: 'Irele',
        id: 2599,
      },
      {
        name: 'Odigbo',
        id: 2600,
      },
      {
        name: 'Okitipupa',
        id: 2601,
      },
      {
        name: 'Ondo East',
        id: 2602,
      },
      {
        name: 'Ondo West',
        id: 2603,
      },
      {
        name: 'Ose',
        id: 2604,
      },
      {
        name: 'Owo',
        id: 2605,
      },
    ],
  },
  {
    state: 'Osun',
    id: 30,
    locals: [
      {
        name: 'Atakunmosa East',
        id: 2606,
      },
      {
        name: 'Atakunmosa West',
        id: 2607,
      },
      {
        name: 'Aiyedaade',
        id: 2608,
      },
      {
        name: 'Aiyedire',
        id: 2609,
      },
      {
        name: 'Boluwaduro',
        id: 2610,
      },
      {
        name: 'Boripe',
        id: 2611,
      },
      {
        name: 'Ede North',
        id: 2612,
      },
      {
        name: 'Ede South',
        id: 2613,
      },
      {
        name: 'Ife Central',
        id: 2614,
      },
      {
        name: 'Ife East',
        id: 2615,
      },
      {
        name: 'Ife North',
        id: 2616,
      },
      {
        name: 'Ife South',
        id: 2617,
      },
      {
        name: 'Egbedore',
        id: 2618,
      },
      {
        name: 'Ejigbo',
        id: 2619,
      },
      {
        name: 'Ifedayo',
        id: 2620,
      },
      {
        name: 'Ifelodun',
        id: 2621,
      },
      {
        name: 'Ila',
        id: 2622,
      },
      {
        name: 'Ilesa East',
        id: 2623,
      },
      {
        name: 'Ilesa West',
        id: 2624,
      },
      {
        name: 'Irepodun',
        id: 2625,
      },
      {
        name: 'Irewole',
        id: 2626,
      },
      {
        name: 'Isokan',
        id: 2627,
      },
      {
        name: 'Iwo',
        id: 2628,
      },
      {
        name: 'Obokun',
        id: 2629,
      },
      {
        name: 'Odo Otin',
        id: 2630,
      },
      {
        name: 'Ola Oluwa',
        id: 2631,
      },
      {
        name: 'Olorunda',
        id: 2632,
      },
      {
        name: 'Oriade',
        id: 2633,
      },
      {
        name: 'Orolu',
        id: 2634,
      },
      {
        name: 'Osogbo',
        id: 2635,
      },
    ],
  },
  {
    state: 'Oyo',
    id: 31,
    locals: [
      {
        name: 'Afijio',
        id: 2636,
      },
      {
        name: 'Akinyele',
        id: 2637,
      },
      {
        name: 'Atiba',
        id: 2638,
      },
      {
        name: 'Atisbo',
        id: 2639,
      },
      {
        name: 'Egbeda',
        id: 2640,
      },
      {
        name: 'Ibadan North',
        id: 2641,
      },
      {
        name: 'Ibadan North-East',
        id: 2642,
      },
      {
        name: 'Ibadan North-West',
        id: 2643,
      },
      {
        name: 'Ibadan South-East',
        id: 2644,
      },
      {
        name: 'Ibadan South-West',
        id: 2645,
      },
      {
        name: 'Ibarapa Central',
        id: 2646,
      },
      {
        name: 'Ibarapa East',
        id: 2647,
      },
      {
        name: 'Ibarapa North',
        id: 2648,
      },
      {
        name: 'Ido',
        id: 2649,
      },
      {
        name: 'Irepo',
        id: 2650,
      },
      {
        name: 'Iseyin',
        id: 2651,
      },
      {
        name: 'Itesiwaju',
        id: 2652,
      },
      {
        name: 'Iwajowa',
        id: 2653,
      },
      {
        name: 'Kajola',
        id: 2654,
      },
      {
        name: 'Lagelu',
        id: 2655,
      },
      {
        name: 'Ogbomosho North',
        id: 2656,
      },
      {
        name: 'Ogbomosho South',
        id: 2657,
      },
      {
        name: 'Ogo Oluwa',
        id: 2658,
      },
      {
        name: 'Olorunsogo',
        id: 2659,
      },
      {
        name: 'Oluyole',
        id: 2660,
      },
      {
        name: 'Ona Ara',
        id: 2661,
      },
      {
        name: 'Orelope',
        id: 2662,
      },
      {
        name: 'Ori Ire',
        id: 2663,
      },
      {
        name: 'Oyo',
        id: 2664,
      },
      {
        name: 'Oyo East',
        id: 2665,
      },
      {
        name: 'Saki East',
        id: 2666,
      },
      {
        name: 'Saki West',
        id: 2667,
      },
      {
        name: 'Surulere',
        id: 2668,
      },
    ],
  },
  {
    state: 'Plateau',
    id: 32,
    locals: [
      {
        name: 'Bokkos',
        id: 2669,
      },
      {
        name: 'Barkin Ladi',
        id: 2670,
      },
      {
        name: 'Bassa',
        id: 2671,
      },
      {
        name: 'Jos East',
        id: 2672,
      },
      {
        name: 'Jos North',
        id: 2673,
      },
      {
        name: 'Jos South',
        id: 2674,
      },
      {
        name: 'Kanam',
        id: 2675,
      },
      {
        name: 'Kanke',
        id: 2676,
      },
      {
        name: 'Langtang South',
        id: 2677,
      },
      {
        name: 'Langtang North',
        id: 2678,
      },
      {
        name: 'Mangu',
        id: 2679,
      },
      {
        name: 'Mikang',
        id: 2680,
      },
      {
        name: 'Pankshin',
        id: 2681,
      },
      {
        name: "Qua'an Pan",
        id: 2682,
      },
      {
        name: 'Riyom',
        id: 2683,
      },
      {
        name: 'Shendam',
        id: 2684,
      },
      {
        name: 'Wase',
        id: 2685,
      },
    ],
  },
  {
    state: 'Rivers',
    id: 33,
    locals: [
      {
        name: 'Abua/Odual',
        id: 2686,
      },
      {
        name: 'Ahoada East',
        id: 2687,
      },
      {
        name: 'Ahoada West',
        id: 2688,
      },
      {
        name: 'Akuku-Toru',
        id: 2689,
      },
      {
        name: 'Andoni',
        id: 2690,
      },
      {
        name: 'Asari-Toru',
        id: 2691,
      },
      {
        name: 'Bonny',
        id: 2692,
      },
      {
        name: 'Degema',
        id: 2693,
      },
      {
        name: 'Eleme',
        id: 2694,
      },
      {
        name: 'Emuoha',
        id: 2695,
      },
      {
        name: 'Etche',
        id: 2696,
      },
      {
        name: 'Gokana',
        id: 2697,
      },
      {
        name: 'Ikwerre',
        id: 2698,
      },
      {
        name: 'Khana',
        id: 2699,
      },
      {
        name: 'Obio/Akpor',
        id: 2700,
      },
      {
        name: 'Ogba/Egbema/Ndoni',
        id: 2701,
      },
      {
        name: 'Ogu/Bolo',
        id: 2702,
      },
      {
        name: 'Okrika',
        id: 2703,
      },
      {
        name: 'Omuma',
        id: 2704,
      },
      {
        name: 'Opobo/Nkoro',
        id: 2705,
      },
      {
        name: 'Oyigbo',
        id: 2706,
      },
      {
        name: 'Port Harcourt',
        id: 2707,
      },
      {
        name: 'Tai',
        id: 2708,
      },
    ],
  },
  {
    state: 'Sokoto',
    id: 34,
    locals: [
      {
        name: 'Binji',
        id: 2709,
      },
      {
        name: 'Bodinga',
        id: 2710,
      },
      {
        name: 'Dange Shuni',
        id: 2711,
      },
      {
        name: 'Gada',
        id: 2712,
      },
      {
        name: 'Goronyo',
        id: 2713,
      },
      {
        name: 'Gudu',
        id: 2714,
      },
      {
        name: 'Gwadabawa',
        id: 2715,
      },
      {
        name: 'Illela',
        id: 2716,
      },
      {
        name: 'Isa',
        id: 2717,
      },
      {
        name: 'Kebbe',
        id: 2718,
      },
      {
        name: 'Kware',
        id: 2719,
      },
      {
        name: 'Rabah',
        id: 2720,
      },
      {
        name: 'Sabon Birni',
        id: 2721,
      },
      {
        name: 'Shagari',
        id: 2722,
      },
      {
        name: 'Silame',
        id: 2723,
      },
      {
        name: 'Sokoto North',
        id: 2724,
      },
      {
        name: 'Sokoto South',
        id: 2725,
      },
      {
        name: 'Tambuwal',
        id: 2726,
      },
      {
        name: 'Tangaza',
        id: 2727,
      },
      {
        name: 'Tureta',
        id: 2728,
      },
      {
        name: 'Wamako',
        id: 2729,
      },
      {
        name: 'Wurno',
        id: 2730,
      },
      {
        name: 'Yabo',
        id: 2731,
      },
    ],
  },
  {
    state: 'Taraba',
    id: 35,
    locals: [
      {
        name: 'Ardo Kola',
        id: 2732,
      },
      {
        name: 'Bali',
        id: 2733,
      },
      {
        name: 'Donga',
        id: 2734,
      },
      {
        name: 'Gashaka',
        id: 2735,
      },
      {
        name: 'Gassol',
        id: 2736,
      },
      {
        name: 'Ibi',
        id: 2737,
      },
      {
        name: 'Jalingo',
        id: 2738,
      },
      {
        name: 'Karim Lamido',
        id: 2739,
      },
      {
        name: 'Kumi',
        id: 2740,
      },
      {
        name: 'Lau',
        id: 2741,
      },
      {
        name: 'Sardauna',
        id: 2742,
      },
      {
        name: 'Takum',
        id: 2743,
      },
      {
        name: 'Ussa',
        id: 2744,
      },
      {
        name: 'Wukari',
        id: 2745,
      },
      {
        name: 'Yorro',
        id: 2746,
      },
      {
        name: 'Zing',
        id: 2747,
      },
    ],
  },
  {
    state: 'Yobe',
    id: 36,
    locals: [
      {
        name: 'Bade',
        id: 2748,
      },
      {
        name: 'Bursari',
        id: 2749,
      },
      {
        name: 'Damaturu',
        id: 2750,
      },
      {
        name: 'Fika',
        id: 2751,
      },
      {
        name: 'Fune',
        id: 2752,
      },
      {
        name: 'Geidam',
        id: 2753,
      },
      {
        name: 'Gujba',
        id: 2754,
      },
      {
        name: 'Gulani',
        id: 2755,
      },
      {
        name: 'Jakusko',
        id: 2756,
      },
      {
        name: 'Karasuwa',
        id: 2757,
      },
      {
        name: 'Machina',
        id: 2758,
      },
      {
        name: 'Nangere',
        id: 2759,
      },
      {
        name: 'Nguru',
        id: 2760,
      },
      {
        name: 'Potiskum',
        id: 2761,
      },
      {
        name: 'Tarmuwa',
        id: 2762,
      },
      {
        name: 'Yunusari',
        id: 2763,
      },
      {
        name: 'Yusufari',
        id: 2764,
      },
    ],
  },
  {
    state: 'Zamfara',
    id: 37,
    locals: [
      {
        name: 'Anka',
        id: 2765,
      },
      {
        name: 'Bakura',
        id: 2766,
      },
      {
        name: 'Birnin Magaji/Kiyaw',
        id: 2767,
      },
      {
        name: 'Bukkuyum',
        id: 2768,
      },
      {
        name: 'Bungudu',
        id: 2769,
      },
      {
        name: 'Gummi',
        id: 2770,
      },
      {
        name: 'Gusau',
        id: 2771,
      },
      {
        name: 'Kaura Namoda',
        id: 2772,
      },
      {
        name: 'Maradun',
        id: 2773,
      },
      {
        name: 'Maru',
        id: 2774,
      },
      {
        name: 'Shinkafi',
        id: 2775,
      },
      {
        name: 'Talata Mafara',
        id: 2776,
      },
      {
        name: 'Chafe',
        id: 2777,
      },
      {
        name: 'Zurmi',
        id: 2778,
      },
    ],
  },
];
