import { useEffect } from 'react';
import {
  PAYMENT_PLAN_MAPPING,
  PHARM_PAYMENT_PLAN_MAPPING,
  // BOOL_MAPPING,
  PRODUCT_TYPE,
  SubscriptionDetailsTab,
} from '../../../utils/mappings';

import { ISubscriptionDetailsProp } from '../../../interfaces/SubscriptionInterface';
import { isFormValidated } from '../../../utils/formUtils';

const PlanDetailScreen = ({
  subscription,
  tabKey,
  handleChange,
  amount,
  changeTab,
  submitForm,
}: ISubscriptionDetailsProp) => {
  const moveToNextScreen = () => {
    if (changeTab) {
      changeTab(SubscriptionDetailsTab.nextOfKin);
    }
  };

  const save = (event: any) => {
    if (!isFormValidated('plan-details')) {
      console.log('Not validated');
      return;
    }

    event.preventDefault();
    if (submitForm) {
      submitForm(event);
    }
  };

  const goBack = () => {
    if (changeTab) {
      changeTab(SubscriptionDetailsTab.info);
    }
  };

  useEffect(() => {
    if (!subscription || tabKey !== SubscriptionDetailsTab.plan) {
      return;
    }
  }, [subscription, tabKey]);

  return (
    <>
      <div className='container mt-5'>
        <div className='bi bi-chevron-left pointer' onClick={goBack}>
          Back
        </div>
        <form id='plan-details' className='row justify-content-center mt-4'>
          <h4 style={{ textAlign: 'center', color: '#000000' }}>Plan Information</h4>

          <div className='col-md-4 col-sm-8 mt-3'>
            <div className='form-floating form-group'>
              <select
                onChange={handleChange('numberOfSubscribers')}
                value={subscription!.numberOfSubscribers}
                className='form-control'
              >
                <option value='1'>1</option>
                <option value='2'>2</option>
                <option value='3'>3</option>
                <option value='4'>4</option>
                <option value='5'>5</option>
              </select>
              <label className='form-label'>
                Number of Beneficiaries <span className='text-danger'>*</span>
              </label>
            </div>
            <div className='form-floating form-group mt-4'>
              <select
                value={subscription!.product}
                required
                onChange={handleChange('product')}
                className='form-control custom-select'
              >
                {PRODUCT_TYPE.map((option) => (
                  <option key={option.key} value={option.value}>
                    {option.text}
                  </option>
                ))}
              </select>
              <label className='form-label'>
                Product <span className='text-danger'>*</span>
              </label>
            </div>
            <div className='form-floating form-group mt-4'>
              <select
                required
                value={subscription!.paymentPlan}
                onChange={handleChange('paymentPlan')}
                className='form-control custom-select'
              >
                {subscription?.product === 'Pharma' &&
                  PHARM_PAYMENT_PLAN_MAPPING.map((option) => (
                    <option key={option.key} value={option.value}>
                      {option.text}
                    </option>
                  ))}

                {subscription?.product !== 'Pharma' &&
                  PAYMENT_PLAN_MAPPING.map((option) => (
                    <option key={option.key} value={option.value}>
                      {option.text}
                    </option>
                  ))}
              </select>
              <label className='form-label'>
                Payment Plan <span className='text-danger'>*</span>
              </label>
            </div>
            <div className='form-floating form-group mt-4'>
              <input
                value={amount}
                disabled
                className='form-control '
                type='number'
                min={0}
                step={0.01}
              />
              <label className='form-label'>
                Amount (₦)<span className='text-danger'>*</span>
              </label>
            </div>
            <div className='d-grid mt-5'>
              {subscription?.product === 'Malaria' || subscription?.product === 'Pharma' ? (
                <button className='btn btn-primary btn-height' type='submit' onClick={save}>
                  Submit
                </button>
              ) : (
                <button
                  className='btn btn-primary btn-height'
                  type='button'
                  onClick={moveToNextScreen}
                >
                  Next
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default PlanDetailScreen;
